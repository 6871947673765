import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { HTTPURL } from "../../../constants/Matcher";
import profile from './../../../image/profile.png'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './../../../styles/DriverDetails.css'
import { toast } from "react-toastify";

const DriverDetails = () => {

    const navigate = useNavigate()

    const params = useParams();
    const [driver, setDriverId] = useState();
    const [orders, setOrders] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const [paginate, setPaginate] = useState({
        start: 0,
        limit: 25
    })
    const [totalRecords, setTotalRecords] = useState(false);
    const [isBtn, setIsBtn] = useState(false)

    useEffect(() => {
        setDriverId(params.id)
        getDriverDetails(paginate?.start, paginate?.limit);
    }, [])

    console.log("${params.id}", params.id);

    const getDriverDetails = (start, limit) => {
        setIsBtn(true);
        axios.get(HTTPURL + `/api/get_driver_detail/${params.id}/all/${start}/${limit}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setIsBtn(false);

            if (response?.data?.status) {
                let data = response?.data?.data;


                let rows = data?.order;
                if (start > 0) {
                    rows = orders.concat(rows)
                }


                setOrders(rows)
                setUserInfo(data?.userinfo)
                console.log("data?.user", data?.userinfo);
                setTotalRecords(response?.data?.data?.totalRecords)
            } else {
                toast.error("No driver is Found")
                navigate("/drivers-list")
                return;
            }
        }).catch((error) => {

        })
    }
    const handleImageError = (e) => {
        e.target.src = profile; // Replace with the path to your default image
    }
    const viewMore = (start, limit) => {
        let obj = { start: start, limit: limit }
        setPaginate(obj)
        getDriverDetails(start, limit)
    }
    return (
        <>
            <section class="bg-light driver-details details-cloned" id="driver-details">

                <div className="driver-details-inner">
                    {!userInfo ?
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <div class="container">
                            <div className="font-42 text-black text-center pb-2">Driver Details</div>
                            <div class="row">
                                <div class="col-lg-12 mb-4 mb-sm-5">
                                    <div class="card card-style1 border-0">
                                        <div class="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
                                            <div class="row align-items-center">
                                                <div class="col-lg-3 mb-4 mb-lg-0 customer-dp">
                                                    <img
                                                        src={HTTPURL + "/uploads/" + userInfo?.profile_image}
                                                        width="60"
                                                        alt="Image"
                                                        onError={handleImageError}
                                                        className="driver-dp"
                                                    />
                                                </div>
                                                <div class="col-lg-9 px-xl-10 details-f">
                                                    <div class="bg-secondary d-lg-inline-block py-1-9 px-1-9 px-sm-6 mb-1-9 rounded customer-basic-details">
                                                        <h3 class="h2 text-white mb-0 text-capitalize">{userInfo?.name}</h3>
                                                        <span class="text-primary">(Driver)</span>
                                                    </div>
                                                    <ul class="list-unstyled mb-1-9">
                                                        <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Email:</span> {userInfo?.email}</li>
                                                        <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Address:</span> {userInfo?.address}</li>
                                                        <li class="display-28"><span class="display-26 text-secondary me-2 font-weight-600">Phone:</span> {userInfo?.mobile}</li>
                                                    </ul>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 mb-4 mb-sm-5">



                                    <Tabs
                                        defaultActiveKey="home"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="home" title="Orders list">
                                            <div className="table-responsive">
                                                {/* <span class="section-title text-primary mb-3 mb-sm-4">Orders list</span> */}
                                                <table className="table table-borderless">
                                                    <thead>
                                                        <tr>
                                                            <th className="pl-0  pb-2 border-bottom">Sr.No.</th>
                                                            <th className="border-bottom pb-2">Order id</th>
                                                            <th className="border-bottom pb-2">Profile picture</th>
                                                            <th className="border-bottom pb-2">Email</th>
                                                            <th className="border-bottom pb-2">Name</th>
                                                            <th className="border-bottom pb-2">No. of loads/Amount</th>
                                                            <th className="border-bottom pb-2">Order type</th>
                                                            <th className="border-bottom pb-2">Order status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orders && orders.map((order, index) => (
                                                            <tr key={index}>
                                                                <td className="pl-0">{index + 1}</td>
                                                                <td><p className="mb-0"><span className="font-weight-bold mr-2">#{order?.order_id}</span></p></td>
                                                                <td>
                                                                    <div className="td-img">
                                                                        <img
                                                                            src={HTTPURL + "/uploads/" + order?.customer_pic}
                                                                            width="60"
                                                                            alt="Image"
                                                                            onError={handleImageError}
                                                                        />
                                                                    </div>

                                                                </td>
                                                                <td className="text-muted">{order?.customer_email}</td>
                                                                <td className="text-muted">{order?.customer_name}</td>
                                                                <td className="text-muted">
                                                                    {(order?.order_type == 1 || order?.order_type == 2) ? order?.total_loads : order?.total_amount}
                                                                </td>
                                                                <td className="text-muted">{(order?.order_type == 1 || order?.order_type == 2) ? "Laundary" : "Dry cleaning"}</td>
                                                                <td className="text-muted">
                                                                    <div class="swc-badge-inner">
                                                                        {order?.order_status == 0 ? "New order" : null}
                                                                        {order?.order_status == 1 ? "Wash" : null}
                                                                        {order?.order_status == 2 ? "Dry" : null}
                                                                        {order?.order_status == 3 ? "Fold" : null}
                                                                        {order?.order_status == 4 ? "Pack" : null}
                                                                        {order?.order_status == 5 ? "Completed" : null}
                                                                        {order?.order_status == 6 ? "Way to drop" : null}
                                                                        {order?.order_status == 7 ? "No status" : null}
                                                                        {order?.order_status == 8 ? "Picked Up" : null}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {totalRecords ?
                                                            <div className="modal-footer">
                                                                <button type='button' className="btn btn-lg btn-success" onClick={() => viewMore(paginate?.start + paginate?.limit, paginate?.limit)} disabled={isBtn}>View more
                                                                    {isBtn ? <div className="spinner-border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div> : ''}
                                                                </button>
                                                            </div> : null
                                                        }

                                                        {!orders.length ? <tr align="center">
                                                            <td colSpan={8}>
                                                                <div className="text-muted" role="status">
                                                                    <span className="no-data">No orders found.</span>
                                                                </div>
                                                            </td>
                                                        </tr> : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Report" title="Report" disabled>
                                            Tab content for Report
                                        </Tab>
                                        <Tab eventKey="Attendance" title="Attendance" disabled>
                                            Tab content for Attendance
                                        </Tab>
                                        <Tab eventKey="Payment" title="Payment" disabled>
                                            Tab content for Payment
                                        </Tab>
                                    </Tabs>

                                </div>

                            </div>
                        </div>
                    }
                </div>

            </section>

        </>
    )

}


export default DriverDetails