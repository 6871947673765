import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { HTTPURL } from "../../../constants/Matcher";
import profile from './../../../image/profile.png'
import html2pdf from 'html2pdf.js';
import './../../../styles/OrderDetails.css';
import { async } from "q";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { AssignDriver, AssignFolder, GetAllOrders, UpdateStatus, AddLoad, updateInstruction } from "../../../apis/Orders";
import Moment from 'moment';
import dateFormat from 'date-and-time';
import Webcam from "react-webcam";
import { Modal, ModalHeader } from "react-bootstrap";



const OrderDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const stutuses = [{ ids: [], title: "New" }, { ids: [0], title: "Picked" }, { ids: [0, 8], title: "Washed" }, { ids: [0, 1, 8], title: "Dried" }, { ids: [0, 1, 2, 8], title: "Folded" }, { ids: [0, 1, 2, 3, 8], title: "Packed" }, { ids: [0, 1, 2, 3, 4, 8], title: "Done" }]

    const order_statuses = ["New", "Washed", "Dried", "Folded", "Packed", "-", "Complete", "-", "Picked"];
    const [orderDetails, setOrderDetails] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(0);

    const [isLoader, setIsLoader] = useState(false);
    const [driverList, setDriverList] = useState([])
    const [folderList, setFolderList] = useState([])
    const [deliveryDays, setDeliveryDays] = useState([])

    const [id, setId] = useState(0);
    const [isStatusImage, setIsStatusImage] = useState([false]);
    const [extraLoad, setExtraLoad] = useState([0]);
    const [weight, setWeight] = useState([0]);
    const [orderTimings, setOrderTimings] = useState(null)
    const [orderImages, setOrderImages] = useState(null)

    const [statusCam, setStatusCam] = useState(false);
    const [statusImages, setStatusImages] = useState([]);
    const [chargeCam, setChargeCam] = useState(false);
    const [chargeImages, setChargeImages] = useState([]);

    const [orderAssign, setOrderAssign] = useState({
        booking_id: 0,
        driver_id: 0,
        folder_id: 0,
        folder_instructions: '',
        driver_instructionss: '',
        bin: '',
        price: '',
        min_order: '',
        estimated_loads: 0,
        total_loads: 0,
        paid_amount: 0,
        booking_status: 0,
        qrcodes: '',
        date: '',
        delivery_day: '',
        days_gap: 0
    })

    useEffect(() => {
        getOrderDetails();
        getDriverList();
        getFolderList();
        getDeliveryDays();
        getOrderTimings();
        getOrderImages();
    }, [])



    const getOrderTimings = async () => {
        let booking_id = params?.order_id
        axios.post(HTTPURL + `/api/get-order-timings`, {
            booking_id: booking_id
        }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            let data = response?.data;
            if (data?.status && data?.data.length > 0) {
                setOrderTimings(data?.data[0])
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    const getOrderImages = async () => {
        let booking_id = params?.order_id
        axios.post(HTTPURL + `/api/get-order-images`, {
            booking_id: booking_id
        }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            let data = response?.data;
            if (data?.status && data?.data.length > 0) {
                setOrderImages(data?.data[0])
            }
        }).catch((error) => {
            console.log(error);
        })
    }



    const getDeliveryDays = () => {

        axios.get(HTTPURL + `/api/get-active-delivery-days`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response?.data?.status) {
                setDeliveryDays(response?.data?.data)
            }
        }).catch((error) => {

        })

    }


    const getFolderList = () => {

        axios.get(HTTPURL + `/api/folders-list`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response?.data?.status) {
                setFolderList(response?.data?.data)
            }
        }).catch((error) => {

        })

    }

    const getDriverList = () => {
        axios.get(HTTPURL + `/api/get-active-drivers/all/0/100`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response?.data?.status) {
                setDriverList(response?.data?.data?.rows)
            }
        }).catch((error) => {

        })
    }



    const getOrderDetails = () => {
        axios.get(HTTPURL + `/api/get-order-detail/${params.order_id}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {

            if (response?.data?.status) {
                let data = response?.data?.data;

                setOrderDetails(data)


                setOrderAssign({
                    ...orderAssign,
                    booking_id: data?.id,
                    driver_id: data.driver_id,
                    folder_id: data.folder_id,
                    folder_instructions: data.folder_instructions ? data.folder_instructions : '',
                    driver_instructions: data.driver_instructions ? data.driver_instructions : '',
                    bin: data.bin ? data.bin : '',
                    booking_status: data?.order_status,
                    booking_unit: data?.unit,
                    paid_amount: data?.paid_amount,
                    old_paid_amount: data?.paid_amount,
                    price: data?.price,
                    min_order: data?.min_order,
                    credit: data?.credit,
                    unit: data?.unit,
                    user_id: data?.user_id,
                    qrcodes: data?.qrcodes,
                    estimated_loads: data?.estimated_loads,
                    total_loads: data?.total_loads,
                    old_driver_id: data.driver_id,
                    date: data?.date + "T00:00:00",
                    delivery_day: data?.delivery_day,
                    days_gap: data?.delivery_gap,
                })


                setId(data?.user_id)
                setWeight([0]);
                setExtraLoad([0]);
                setChargeImages([])


            }
        }).catch((error) => {
            toast.error(error)

        })
    }


    const addNewElement = (e) => {
        var tmp = Object.values({ ...extraLoad });
        if (tmp.length < 20) {
            tmp.push(0);
            setExtraLoad(tmp);
        } else {
            toast.error("Max 20 Wt. scale reading allowed.")
        }

    }






    const detailsPage = (id) => {
        navigate("/customer-details/" + id)
    }



    const addNewWeight = (e) => {
        var tmp = Object.values({ ...weight });
        if (tmp.length < 20) {
            tmp.push(0);
            setWeight(tmp);


        } else {
            toast.error("Max 20 Wt. scale reading allowed.")
        }

    }


    const updateStatus = (status) => {


        if (status != 6 || orderDetails?.paid_amount > 0) {
            const form = new FormData();
            form.append('booking_id', orderDetails?.id);
            form.append('status', status);
            
             for (let i = 0; i < statusImages.length; i++) {

                const file = DataURIToBlob(statusImages[i])
                form.append('status_images', file, 'image.jpg');

            }


            axios.post(HTTPURL + "/api/update-booking-status/", form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': localStorage.getItem('token')
                }
            }).then((response) => {
                if (response?.data?.status) {
                    toast.success(response?.data?.message)
                    getOrderDetails();
                    getOrderTimings();
        getOrderImages();
        setStatusImages([]);

                } else {
                    toast.error(response?.data?.message)
                }
            })
        } else {

            toast.error("Please charge first before marking order as complete.")
            //  setSelectedOrderStatus(6);

        }

    }



    const chargeBooking = async (status) => {


        setIsLoader(true);
        var consent = true;

        const form = new FormData();
        form.append('booking_id', orderAssign?.booking_id);
        const tmp_weight = extraLoad.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)
        if (tmp_weight <= 0) {
            toast.error("Please Enter Weight/Bags")
                    setIsLoader(false);

        }
        else {
            form.append('extra_loads', tmp_weight);
            let total_amount = orderAssign?.min_order * orderAssign?.price;
            if (tmp_weight >= orderAssign?.min_order) {
                total_amount = tmp_weight * orderAssign?.price;
            }

            for (let i = 0; i < chargeImages.length; i++) {

                const file = DataURIToBlob(chargeImages[i])
                form.append('charge_images', file, 'charge.jpg');

            }

            if (orderAssign.credit < total_amount) {
                const balance = total_amount - orderAssign.credit;
                const str = "Customer credit ( USD " + orderAssign.credit + ") is low than his bill( USD" + total_amount + ") for " + tmp_weight + "lbs" + " An amount of USD" + balance + " will be deducted from customer credit card. Are you sure, you want to proceed.";
                if (!window.confirm(str)) {
                    consent = false;
                    setIsLoader(false)

                }

            }

            if (consent) {
                var result = await AddLoad(form, '/api/add-load');
                setIsLoader(false);
                if (result?.status) {
                    toast.success(result?.message)
                    getOrderDetails();
                    setChargeCam(false); setChargeImages([]);
                } else {
                    toast.error(result?.message)
                }
            }
        }

    }





    const handleCompletedStatus = async () => {

        setIsLoader(true)

        var consent = true;
        const form = new FormData();
        form.append('booking_id', orderDetails?.id);
        form.append('status', 6);


        if (orderDetails?.unit == 2 && orderDetails.paid_amount <= 0) {

            const tmp_weight = weight.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)
            form.append('weight', tmp_weight);



            let total_amount = orderDetails?.min_order * orderDetails?.price;
            if (tmp_weight >= orderDetails?.min_order) {
                total_amount = tmp_weight * orderDetails?.price;
            }

            if (orderDetails.credit < total_amount) {
                const balance = total_amount - orderDetails.credit;
                const str = "Customer credit ( USD " + orderDetails.credit + ") is low than his bill( USD" + total_amount + ") for " + tmp_weight + "lbs" + " An amount of USD" + balance + " will be deducted from customer credit card. Are you sure, you want to proceed.";
                if (!window.confirm(str)) {
                    consent = false;

                    setIsLoader(false);

                }

            }
        }

        if (consent) {

            axios.post(HTTPURL + "/api/update-booking-status/", form, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                }
            }).then((response) => {
                if (response?.data?.status) {
                    toast.success(response?.data?.message)
                    getOrderDetails();
                    setIsLoader(false);
                } else {
                    toast.error(response?.data?.message)
                }
            })


        }


    }






    const handleUpdateOrder = async () => {



        if (orderAssign.old_paid_amount > 0 && (orderAssign?.paid_amount <= 0 || orderAssign?.total_loads <= 0)) {

            toast.error("This Order is already charged, Please enter correct charged amount & Actual Weight/Bags");

        }
        else if (orderAssign.old_paid_amount <= 0 && (orderAssign?.paid_amount > 0)) {

            toast.error("This Order is not yet charged, Charge amount must be zero. Please charge first.");

        }
        else if (orderAssign?.price <= 0 || orderAssign?.estimated_loads <= 0 || orderAssign?.min_order <= 0) {

            toast.error("Price, Min Weight and Estimated Weigth are required");

        }
        else {


            setIsLoader(true)
            const form = new FormData();
            form.append('booking_id', orderAssign?.booking_id);
            form.append('price', isNaN(orderAssign?.price) ? 0 : orderAssign?.price);
            form.append('min_order', isNaN(orderAssign?.min_order) ? 0 : orderAssign?.min_order);
            form.append('estimated_loads', isNaN(orderAssign?.estimated_loads) ? 0 : orderAssign?.estimated_loads);
            form.append('total_loads', isNaN(orderAssign?.total_loads) ? 0 : orderAssign?.total_loads);
            form.append('bin', orderAssign?.bin);
            form.append('folder_id', orderAssign?.folder_id);
            form.append('driver_id', orderAssign?.driver_id);
            form.append('old_driver_id', orderAssign?.old_driver_id);
            form.append('paid_amount', isNaN(orderAssign?.paid_amount) ? 0 : orderAssign?.paid_amount);
            form.append('folder_instructions', orderAssign?.folder_instructions);
            form.append('driver_instructions', orderAssign?.driver_instructions);

            form.append('date', Moment(orderAssign?.date).format('YYYY-MM-DD'));
            form.append('delivery_day', orderAssign?.delivery_day);
            form.append('days_gap', orderAssign?.days_gap);


            axios.post(HTTPURL + "/api/update-order/", form, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                }
            }).then((response) => {
                if (response?.data?.status) {
                    toast.success(response?.data?.message);
                    setIsLoader(false);
                    getOrderDetails();

                } else {
                    toast.error(response?.data?.message)
                }
            })


        }


    }


    const videoConstraints = {
        width: 300,
        height: 200,
    };
    const webcamChargeRef = useRef(null);
    const webcamStatusRef = useRef(null);

    const captureCharge = async () => {

        const imageSrc = webcamChargeRef.current.getScreenshot();
        setChargeImages(chargeImages => [...chargeImages, imageSrc]);

    }



    const captureStatus = async () => {

        const imageSrc = webcamStatusRef.current.getScreenshot();
        setStatusImages(statusImages => [...statusImages, imageSrc]);

    }


    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    return (
        <div className="container-fluid px-0" id="order_detail_cont">
            <div className="right-nav py-3 px-2">
                <h3>Order Details #{orderDetails?.id}</h3>


                <div className="row mt-3">

                    <div className="col-xl-6 mb-3">
                        <div className="card  rounded-4">
                            <h5 className="card-header pt-3 pb-4 fw-bold">Order Details & Charge</h5>
                            <div className="card-body form-group" >


                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Order ID:</label>{orderDetails?.id}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Category:</label>{orderDetails?.category_title}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Pickup Address:</label>{orderDetails?.pickup_address}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Delivery Address:</label>{orderDetails?.delivery_address}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Price:</label>{orderDetails?.price}{orderDetails?.unit == 1 ? "/bag" : "/lb"}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Min Wt./Bags:</label>{orderDetails?.min_order}{orderDetails?.unit == 1 ? "bags" : "lbs"}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Estimated Wt./Bags:</label>{orderDetails?.estimated_loads}{orderDetails?.unit == 1 ? "bags" : "lbs"}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Actual Wt./Bags:</label>{orderDetails?.total_loads == 0 ? "Not Weighed Yet" : orderDetails?.total_loads}{orderDetails?.unit == 1 ? "bags" : "lbs"}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Order Status:</label>{order_statuses[parseInt(orderDetails?.order_status)]}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Bin #:</label>{orderDetails?.bin}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Payment Status:</label>{orderDetails?.paid_amount == 0 ? "Not Paid" : "Paid"}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Amount Paid:</label>USD {orderDetails?.paid_amount}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Driver Instructions:</label>{orderDetails?.driver_instructions}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Folder Instructions:</label>{orderDetails?.folder_instructions}</div>
                                </div>


                                {orderDetails?.paid_amount === 0 &&
                                    <div className="col-lg-12 col-md-6 mt-4">
                                        <label><h4>{orderAssign?.booking_unit == 2 ? "Weighing Scale Readings (Total Wt.)" : "Total Bags"}</h4></label>
                                        <div className='form-group mt-2'>
                                            {extraLoad?.map((extra, index) => (
                                                <input type="number" name="extra_load[]" onChange={(e) => setExtraLoad(Object.values({ ...extraLoad, [index]: e.target.value }))} className="form-control extra-load" />

                                            ))}

                                            <>
                                                <butoon className="btn btn-lg btn-success" onClick={addNewElement} ><i className="fa fa-plus"></i></butoon>
                                                <button type='button' className="btn btn-lg btn-success mx-1 " onClick={chargeBooking} disabled={isLoader}>Charge {isLoader ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div> : ''}</button>
                                                <button type='button' className="btn btn-lg btn-success mx-1 " onClick={(e) => {
                                                    const tmp_weight = extraLoad.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
                                                    if (tmp_weight <= 0) {
                                                        toast.error("Please Enter Weight/Bags")
                                                    }
                                                    else {
                                                        setChargeCam(true); setChargeImages([]);
                                                    }


                                                }} >Charge With Images</button>

                                                <label>Total: {extraLoad.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)}</label>

                                            </>

                                        </div>


                                    </div>
                                }


                            </div>




                        </div>
                    </div>

                    <div className="col-xl-6 mb-3">
                        <div className="card  rounded-4">
                            <h5 className="card-header ">
                                <label className="lh-lg fw-bold">Update Order</label>
                                <button type='button' className="btn btn-lg btn-success float-end " onClick={handleUpdateOrder} disabled={isLoader}>Save Order
                                    {isLoader ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : ''}
                                </button>
                            </h5>
                            <div className="card-body form-group" >


                                <>

                                    <div className="row mt-3">
                                        <div className="col-md-6 mb-2  mt-3">
                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Price:</label>
                                                </div>
                                                <div className="col-5">
                                                    <input className="form-control" type="text" name="price" id="price" value={orderAssign?.price} onChange={(e) => { setOrderAssign({ ...orderAssign, price: e.target.value }) }} />
                                                </div>
                                                <div className="col-2">
                                                    {orderAssign?.unit == 1 ? "$/bag" : "$/lb"}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2  mt-3">
                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Min Wt./B:</label>
                                                </div>
                                                <div className="col-5">

                                                    <input className="form-control" type="text" name="min_order" id="min_order" value={orderAssign?.min_order} onChange={(e) => { setOrderAssign({ ...orderAssign, min_order: e.target.value }) }} />


                                                </div>
                                                <div className="col-2">
                                                    {orderAssign?.unit == 1 ? "bags" : "lbs"}
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6  mb-2  mt-3">

                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Estimated</label>
                                                </div>
                                                <div className="col-5">


                                                    <input className="form-control" type="text" name="estimated_load" id="estimated_load" value={orderAssign?.estimated_loads} onChange={(e) => { setOrderAssign({ ...orderAssign, estimated_loads: e.target.value }) }} />

                                                </div>
                                                <div className="col-2">
                                                    {orderAssign?.unit == 1 ? "bags" : "lbs"}
                                                </div>


                                            </div>
                                        </div>


                                        <div className="col-md-6 mb-2  mt-3">

                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Actual</label>
                                                </div>
                                                <div className="col-5">
                                                    <input className="form-control" type="text" name="total_loads" id="total_loads" value={orderAssign?.total_loads} onChange={(e) => { setOrderAssign({ ...orderAssign, total_loads: e.target.value }) }} />
                                                </div>

                                                <div className="col-2">
                                                    {orderAssign?.unit == 1 ? "bags" : "lbs"}
                                                </div>


                                            </div>


                                        </div>
                                    </div>


                                    <div className="row ">
                                        <div className="col-md-6 mb-2  mt-3">
                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Bin #:</label>
                                                </div>
                                                <div className="col-5">

                                                    <input className="form-control " type="text" name="bin" id="bin" value={orderAssign?.bin} onChange={(e) => { setOrderAssign({ ...orderAssign, bin: e.target.value }) }} />


                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2  mt-3">


                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Paid:</label>
                                                </div>
                                                <div className="col-5">

                                                    <input className="form-control" type="text" name="paid_amount" id="paid_amount" value={orderAssign?.paid_amount} onChange={(e) => { setOrderAssign({ ...orderAssign, paid_amount: e.target.value }) }} />

                                                </div>
                                                <div className="col-2">$</div>


                                            </div>


                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-md-6 mb-2  mt-3">
                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Pickup Date:</label>
                                                </div>
                                                <div className="col-5">
                                                    {orderAssign?.date &&
                                                        <DatePicker className="form-control" minDate={new Date()} selected={new Date(orderAssign?.date)} onChange={(e) => { setOrderAssign({ ...orderAssign, date: e }) }} />
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2  mt-3">
                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Delivery:</label>
                                                </div>
                                                <div className="col-5">
                                                    <select className="form-control text-capitalize" aria-label="Default select example" id="delivery_day"
                                                        onChange={(e) => {
                                                            console.log(e.target);
                                                            const selectedIndex = e.target.options.selectedIndex;
                                                            setOrderAssign({ ...orderAssign, days_gap: e.target.value, delivery_day: e.target.options[selectedIndex].getAttribute('data-key') });

                                                        }
                                                        }
                                                    >
                                                        <option value="0" disabled selected>Delivery</option>
                                                        {deliveryDays.map((d) => (
                                                            <option key={d?.delivery_day} data-key={d?.delivery_day} value={d?.days_gap} selected={d?.delivery_day == orderAssign.delivery_day} >{d?.delivery_day} </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <div className="col-md-6 mb-2  mt-3">
                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Driver:</label>
                                                </div>
                                                <div className="col-5">

                                                    <select className="form-control text-capitalize" aria-label="Default select example"
                                                        onChange={(e) =>
                                                            setOrderAssign({ ...orderAssign, driver_id: e.target.value })
                                                        }
                                                        id="category"
                                                    >
                                                        <option value="0" disabled selected>Select Driver</option>
                                                        {driverList.map((driver) => (
                                                            <option key={driver?.id} value={driver?.id} selected={driver?.id == orderAssign.driver_id}  >{driver?.name} ({driver?.email})</option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2  mt-3">


                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <label className="">Folder:</label>
                                                </div>
                                                <div className="col-5">

                                                    <select
                                                        className="form-control text-capitalize" aria-label="Default select example"
                                                        onChange={(e) =>
                                                            setOrderAssign({ ...orderAssign, folder_id: e.target.value })
                                                        }
                                                        id="category"
                                                    >
                                                        <option value="0" disabled selected>Select Folder</option>
                                                        {folderList.map((folder) => (
                                                            <option key={folder?.id} value={folder?.id} selected={folder?.id == orderAssign.folder_id} >{folder?.name} ({folder?.email})</option>
                                                        ))}
                                                    </select>
                                                </div>



                                            </div>


                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <label>Folder Instructions</label>
                                            <div className='form-group mt-2'>
                                                <textarea className="form-control" id="folder_instructions" rows="2"
                                                    onChange={(e) =>
                                                        setOrderAssign({ ...orderAssign, folder_instructions: e.target.value })
                                                    }
                                                    placeholder="Folder Instructions"
                                                    value={orderAssign.folder_instructions}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <label>Driver Instructions</label>
                                            <div className='form-group mt-2'>
                                                <textarea className="form-control" id="driver_instructions" rows="2"
                                                    onChange={(e) =>
                                                        setOrderAssign({ ...orderAssign, driver_instructions: e.target.value })
                                                    }
                                                    placeholder="Driver Instructions"
                                                    value={orderAssign.driver_instructions}
                                                />
                                            </div>
                                        </div>
                                    </div>






                                </>




                            </div>




                        </div>
                    </div>


                </div>


                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <div className="card  rounded-4 ">
                            <h5 className="card-header pt-3 pb-4 fw-bold">Update Order Status</h5>
                            <div className="track m-1">

                                <ul className="track_item " >
                                    {orderDetails && stutuses.map((stutus) => (
                                        <li className={`${(stutus.ids.includes(orderDetails?.order_status)) ? "" : "active"}`} >
                                            <div className={`check_icon ${(stutus.ids.includes(orderDetails?.order_status)) ? "uncheck" : ""}`} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                                                    <path d="M1 3.85714L3.5 6.35714L8.5 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="pick_title pt-3 pb-0 m-0">{stutus.title}</div>
                                        </li>
                                    ))}
                                </ul>


                            </div>



                            <div className='row'>

                                <div className="col-lg-12 mt-4 text-center">

                                    <div className=' mt-2'>


                                        {orderDetails?.order_status == 0 &&
                                            <button onClick={(e) => updateStatus(8)} className="btn btn-lg btn-info me-1 mb-1" >Order Pickup</button>
                                        }
                                        {[0, 8].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(1)} className="btn btn-lg btn-info me-1 mb-1" >Washed</button>
                                        }
                                        {[0, 8, 1].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(2)} className="btn btn-lg btn-info me-1 mb-1" >Dried</button>
                                        }
                                        {[0, 8, 1, 2].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(3)} className="btn btn-lg btn-info me-1 mb-1" >Folded</button>
                                        }
                                        {[0, 8, 1, 2, 3].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(4)} className="btn btn-lg btn-info me-1 mb-1" >Packed</button>
                                        }
                                        {[0, 8, 1, 2, 3, 4].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(6)} className="btn btn-lg btn-info me-1 mb-1" >Completed</button>
                                        }

{orderDetails?.order_status!=6 &&
                                        <a className="btn btn-lg btn-warning me-1 mb-1 py-1" onClick={(e) => { setStatusCam(true);setStatusImages([]); }} >
                                            <svg className="inline" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" >
                                                <path d="M18 24C20.4853 24 22.5 21.9853 22.5 19.5C22.5 17.0147 20.4853 15 18 15C15.5147 15 13.5 17.0147 13.5 19.5C13.5 21.9853 15.5147 24 18 24Z"
                                                    stroke="#333333" stroke-width="1.5" />
                                                <path d="M14.667 31.5001H21.333C26.0145 31.5001 28.356 31.5001 30.0375 30.3976C30.763 29.922 31.3879 29.3084 31.8765 28.5916C33 26.9416 33 24.6421 33 20.0461C33 15.4486 33 13.1506 31.8765 11.5006C31.3879 10.7837 30.7631 10.1701 30.0375 9.69457C28.9575 8.98507 27.6045 8.73157 25.533 8.64157C24.5445 8.64157 23.694 7.90657 23.5005 6.95407C23.3526 6.25637 22.9684 5.63112 22.4128 5.184C21.8571 4.73687 21.1642 4.49528 20.451 4.50007H15.549C14.067 4.50007 12.7905 5.52757 12.4995 6.95407C12.306 7.90657 11.4555 8.64157 10.467 8.64157C8.397 8.73157 7.044 8.98657 5.9625 9.69457C5.23743 10.1702 4.61306 10.7838 4.125 11.5006C3 13.1506 3 15.4486 3 20.0461C3 24.6421 3 26.9401 4.1235 28.5916C4.6095 29.3056 5.2335 29.9191 5.9625 30.3976C7.644 31.5001 9.9855 31.5001 14.667 31.5001Z"
                                                    stroke="#333333" stroke-width="1.5" />
                                                <path d="M28.5 15H27" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>

                                        </a>
                                        }
                                    </div>



                                </div>

                            </div>

                            {orderTimings &&
                                <div className="row px-3 my-3">
                                    {orderTimings.pickup_time &&

                                        <div class="col-sm-6 "><label className="fw-bold" >Pickup Time:</label> {dateFormat.format(new Date(orderTimings.pickup_time), "ddd, MMM DD, YY hh:mm A")}</div>

                                    }
                                    {orderTimings.wash_time &&

                                        <div class="col-sm-6 "><label className="fw-bold" >Wash Time:</label> {dateFormat.format(new Date(orderTimings.wash_time), "ddd, MMM DD, YY hh:mm A")}</div>

                                    }
                                    {orderTimings.dry_time &&

                                        <div class="col-sm-6 "><label className="fw-bold" >Dry Time:</label> {dateFormat.format(new Date(orderTimings.dry_time), "ddd, MMM DD, YY hh:mm A")}</div>

                                    }
                                    {orderTimings.fold_time &&

                                        <div class="col-sm-6 "><label className="fw-bold" >Fold Time:</label> {dateFormat.format(new Date(orderTimings.fold_time), "ddd, MMM DD, YY hh:mm A")}</div>

                                    }

                                    {orderTimings.pack_time &&

                                        <div class="col-sm-6 "><label className="fw-bold" >Pack Time:</label> {dateFormat.format(new Date(orderTimings.pack_time), "ddd, MMM DD, YY hh:mm A")}</div>

                                    }

                                    {orderTimings.drop_time &&

                                        <div class="col-sm-6 "><label className="fw-bold" >Delivery Time:</label> {dateFormat.format(new Date(orderTimings.drop_time), "ddd, MMM DD, YY hh:mm A")}</div>

                                    }
                                </div>
                            }



                        </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <div className="card  rounded-4">
                            <h5 className="card-header pt-3 pb-4 fw-bold">Customer Information</h5>
                            <div className="card-body form-group" >
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">User ID:</label>{orderDetails?.user_id}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Name:</label>{orderDetails?.name}</div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Email:</label><a target="_blank" href={"mailto:" + orderDetails?.email}>{orderDetails?.email}</a></div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Phone:</label><a target="_blank" href="tel:{orderDetails?.mobile}">{orderDetails?.mobile}</a></div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Pickup Address:</label>{orderDetails?.pickup_address}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Delivery Address:</label>{orderDetails?.delivery_address}</div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Credit:</label>USD{orderDetails?.credit}</div>
                                    <div className="col-sm-6 font-black-medium"><label className="d-inline me-2">Square ID:</label>{orderDetails?.customer_id ? "Yes, Saved" : "Not Saved Yet"}</div>
                                </div>



                                <div className="row">
                                    <div className="col-sm-12 font-black-medium"><label className="d-inline me-2">How I found Weclea:</label>{orderDetails?.comment}</div>
                                </div>

                            </div>




                        </div>
                    </div>


                </div>

                <div className="row">
                    <div className="col-lg-12 mb-3">

                        <div className="card  rounded-4">
                            <div className="card-header pt-3 pb-4 fw-bold">Order Images</div>
                            <div className="card-body">

                                {orderImages && orderImages.pickup_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Pickup Images</h5>

                                            {orderImages.pickup_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}


                                {orderImages && orderImages.wash_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Wash Images</h5>

                                            {orderImages.wash_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}


                                {orderImages && orderImages.dry_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Dry Images</h5>

                                            {orderImages.dry_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}

                                {orderImages && orderImages.fold_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Fold Images</h5>

                                            {orderImages.fold_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}

                                {orderImages && orderImages.pack_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5 className="my-2">Pack Images</h5>

                                            {orderImages.pack_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}

                                {orderImages && orderImages.drop_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Delivery Images</h5>

                                            {orderImages.drop_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}

                                {orderImages && orderImages.charge_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Charge Images</h5>

                                            {orderImages.charge_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}



                            </div>
                        </div>
                    </div></div>


            </div>



            <Modal animation={false}
                show={statusCam}
                onHide={(e) => setStatusCam(false)}
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-inner-content text-center d-inline pt-4">
                    <div className="row mt-3" >
                        <div className="col-sm-12 " >

                            <Webcam
                                audio={false}
                                height={200}
                                ref={webcamStatusRef}
                                screenshotFormat="image/jpeg"
                                width={300}
                                videoConstraints={videoConstraints}

                            />
                        </div>
                    </div>
                    
                    
                                     

                    {statusImages && statusImages.length > 0 &&
                        <div className="row mt-3" >

                            {statusImages.map((el, i) => (
                                <div className="col-sm-6 col-md-4 col-lg-3 item position-relative" >

                                    <img className="img-fluid mb-3" src={el} />
                                    <button onClick={(e) => {
                                        let arr = [...statusImages];
                                        arr.splice(i, 1)
                                        setStatusImages(arr);
                                    }} type="button" className="btn btn-link text-white position-absolute top-0 end-0 px-3 py-0" ><i className="fa fa-remove" /></button>

                                </div>

                            ))}

                        </div>
                    }
                     <div className="row mb-3 " >
                       <div className="col-sm-12 text-center" >
                     <button type='button' onClick={captureStatus} className="btn btn-lg btn-success me-1" >Capture</button>
                        <button type='button' className="btn btn-lg btn-danger " onClick={(e) => setStatusCam(false)}>Close</button>
 </div> </div>
                    <div className="modal-footer text-center d-inline pt-4 ">
                       {orderDetails?.order_status == 0 &&
                                            <button onClick={(e) => updateStatus(8)} className="btn btn-lg btn-info me-1 mb-1" >Order Pickup</button>
                                        }
                                        {[0, 8].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(1)} className="btn btn-lg btn-info me-1 mb-1" >Washed</button>
                                        }
                                        {[0, 8, 1].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(2)} className="btn btn-lg btn-info me-1 mb-1" >Dried</button>
                                        }
                                        {[0, 8, 1, 2].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(3)} className="btn btn-lg btn-info me-1 mb-1" >Folded</button>
                                        }
                                        {[0, 8, 1, 2, 3].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(4)} className="btn btn-lg btn-info me-1 mb-1" >Packed</button>
                                        }
                                        {[0, 8, 1, 2, 3, 4].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(6)} className="btn btn-lg btn-info me-1 mb-1" >Completed</button>
                                        }

                       
                    </div>
                </div>
            </Modal>


            <Modal animation={false}
                show={chargeCam}
                onHide={(e) => setChargeCam(false)}
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-inner-content text-center pt-4">
                    <div className="row mt-3" >
                        <div className="col-12 " >

                            <label className="fw-bold">Total: {extraLoad.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)}</label>

                        </div>
                    </div>
 <div className="row mt-3" >
                        <div className="col-sm-12 " >

                    <Webcam
                        audio={false}
                        height={200}
                        ref={webcamChargeRef}
                        screenshotFormat="image/jpeg"
                        width={300}
                        videoConstraints={videoConstraints}

                    />
                    
                     </div>
                    </div>
                    {chargeImages && chargeImages.length > 0 &&
                        <div className="row mt-3" >

                            {chargeImages.map((el, i) => (
                                <div className="col-sm-6 col-md-4 col-lg-3 item position-relative" >

                                    <img className="img-fluid mb-3" src={el} />
                                    <button onClick={(e) => {
                                        let arr = [...chargeImages];
                                        arr.splice(i, 1)
                                        setChargeImages(arr);
                                    }} type="button" className="btn btn-link text-white position-absolute top-0 end-0 px-3 py-0" ><i className="fa fa-remove" /></button>

                                </div>

                            ))}

                        </div>
                    }


                    <div className="modal-footer text-center  d-inline ">
                        <button type='button' onClick={captureCharge} className="btn btn-lg btn-info mt-4" >Capture Photo</button>
                        <button type='button' className="btn btn-lg btn-success mt-4 " onClick={chargeBooking} disabled={isLoader}>Charge {isLoader ? <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div> : ''}</button>
                        <button type='button' className="btn btn-lg btn-danger mt-4" onClick={(e) => { setChargeCam(false); setChargeImages([]); }}>Close</button>
                    </div>
                </div>
            </Modal>



        </div>





    )
}

export default OrderDetails