import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';

const DriversTable = ({ columns, data, onNewDriver }) => {
    const [searchData, setSeacrhData] = useState('');
    const [filterData, setFilterData] = useState([]);

    const onSearch = (value) => {
        setSeacrhData(value);
        const searchRes = data.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        setFilterData(searchRes);
    };
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: "#a82928",
                color: "white",
            },
        },
        expanderCell: {
            style: {
                backgroundColor: "#a82928",
                color: "white"
            },
        },
        expanderButton: {
            style: {
                backgroundColor: '#eee',
                borderRadius: '2px',
                transition: '0.25s',
                height: '100%',
                width: '100%',
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:hover:not(:disabled)': {
                    cursor: 'pointer',
                    backgroundColor: "#a82928",
                    color: "#fff"
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: "#a82928",
                    color: "#fff"
                },
                svg: {
                    margin: 'auto',
                },
            },

        },
        expanderRow: {
            style: {
                color: '#000',
                backgroundColor: "#eee",
                padding: "10px 70px",
            },
        },
    };


    const conditionalRowStyles = [
        {
            when: row => row.color == 1,
            style: {
                backgroundColor: '#488102',
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#c2d5aa',
                    color: '#333',
                },
            },
        },
    ];

    const ExpandedComponent = ({ data }) => {
        return (
            <>
                <div className="row">
                    <div class="col-6">
                        <p><b>Name: </b>{data.name}</p>
                        <p><b>Email: </b>{data.email}</p>
                        <p><b>Mobile: </b><a href="callto:{{data.mobile}}">{data.mobile}</a> </p>
                       
                    </div>
                    <div class="col-6">
                         <p><b>Address: </b>USD {data.address}</p>
                        <p><b>County: </b>{data.operational_counties}</p>

                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div className='searc-bar'>
                <button className="btn btn-lg btn-danger mx-1 float-end" onClick={(e) => onNewDriver()}>Add Driver</button>
                <input type="search" className="form-control me-2 float-end" placeholder='Search here...' onChange={(e) => onSearch(e.target.value)} />

            </div>

            <DataTable
                // fixedHeader
                columns={columns}
                data={searchData ? filterData : data}
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                paginationRowsPerPageOptions={[50, 100, 250]}
                paginationPerPage={100}
                highlightOnHover
                customStyles={customStyles}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                conditionalRowStyles={conditionalRowStyles}

            />
        </>
    )
}

export default DriversTable;
