import axios from "axios";
import React, { useEffect, useState } from "react";
import { HTTPURL } from "../../constants/Matcher";

import { Link } from "react-router-dom";
import DataLoader from "../../Utils/DataLoader";
import OrderDataTable from "./OrderDataTable";
import { Modal, ModalHeader } from "react-bootstrap";
import { useRef } from "react";
import { toast } from "react-toastify";
import dateFormat from 'date-and-time';
import QRCode from "react-qr-code";
import { Scanner } from '@yudiel/react-qr-scanner';


export const columns = [
    {
        name: " ID",
        selector: row => row.order_link,
        sortable: true,
        width: "80px",
    },
    {
        name: "Customer",
        selector: row => row.name,
        sortable: true,
    },
    {
        name: "Price",
        width: "80px",
        selector: row => row.price,
        sortable: true,
    },
    {
        name: "Category",
        width: "100px",
        selector: row => row.category_title,
        sortable: true,
    },
    {
        name: "PICKUP",
        width: "250px",
        selector: row => row.pickup,
        sortable: true,
    },
    {
        name: "DELIVERY",
        selector: row => row.delivery,
        sortable: true,
        width: "250px",
    },
    {
        name: "STATUS",
        selector: row => row.status,
        width: "90px",
    },
    {
        name: "Es. Bags/Wt.",
        selector: row => row.estimated_loads,
        sortable: true,

    },
    {
        name: "Bags/Wt.",
        selector: row => row.total_loads,
        sortable: true,

    },
    {
        name: "Actions",
        selector: row => row.action,
        sortable: true,
        width: "140px",

    },
];
const LaundryOrdersList = () => {
    const [data, setData] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [printClicked, setPrintClicked] = useState(0);
    const [scanStart, setScanStart] = useState(0);
    const [scannedCodes, setScannedCodes] = useState([]);
    const [booking, setBooking] = useState({});
    const [noOfBags, setNoOfBags] = useState(0);
    const [qrcodes, setQrcodes] = useState([]);
    const [estimatedLoads, setEstimatedLoads] = useState(0);
    const [price, setPrice] = useState(0);
    const [minOrder, setMinOrder] = useState(0);
    const [totalLoads, setTotalLoads] = useState(0);
    const [bin, setBin] = useState('');

    const [isLoader, setIsLoader] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [isPrint, setIsPrint] = useState(false);

    const [driverList, setDriverList] = useState([])
    const [folderList, setFolderList] = useState([])
    const [actionType, setActionType] = useState(1)
    const [orderAssign, setOrderAssign] = useState({
        booking_id: 0,
        driver_id: 0,
        folder_id: 0,
        driver_instructionss: '',
        bin: '',
        booking_status: 0,
        qrcodes: ''
    })
    const [id, setId] = useState(0);
    const [image, setImage] = useState([null, null, null, null, null]);
    const [imageFile, setImageFile] = useState([null, null, null, null, null]);
    const [isStatusImage, setIsStatusImage] = useState([false]);
    const [extraLoad, setExtraLoad] = useState([0]);
    const [weight, setWeight] = useState([0]);

    async function get_data() {
        try {
            var todaysDate = new Date();

            const response = await fetch(HTTPURL + `/api/driver-order-list`, {
                method: "GET",
                headers: { "Content-Type": "application/json", 'Authorization': localStorage.getItem('token') },
            });
            const result = await response.json();
            setOrderDetails(result?.data);
            const arrayData = result?.data?.map((elem, index) => {
                const {
                    id, user_id, folder_instructions, driver_instructions, bin, order_id, price, unit, date, delivery_day, estimated_loads, total_loads, order_type,
                    driver, order_images, order_status, credit, folder, name, email, mobile, order_type_id, paid_amount, category_title,
                    delivery_address, pickup_address, qrcodes
                } = elem;
                var pickup_date = new Date(date);
                return {
                    order_no: id,
                    order_link: <a href={`/driver-order-details/${id}`} >{id}</a>,
                    user_id: user_id,
                    name: name.replace(/ .*/, ''),
                    email: email,
                    mobile: mobile,
                    category_title: category_title,
                    pickup: dateFormat.format(new Date(date), 'ddd, MMM DD, YY'),
                    pickupdate: date,
                    delivery: delivery_day,
                    driver_instructions: driver_instructions,
                    bin: bin ? bin : '',
                    delivery_address: delivery_address,
                    pickup_address: pickup_address,
                    qrcodes: qrcodes,
                    paid_amount: paid_amount,
                    color: (pickup_date.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) ? 1 : 2,
                    status: order_status == 0 ? "New" : order_status == 1 ? "Washed" : order_status == 2 ? "Dry" : order_status == 3 ? "Folded" : order_status == 4 ? "Packed" : order_status == 8 ? "Picked" : '',
                    action: <> <button className="btn btn-sm btn-danger" onClick={(e) => openActionModal(elem)}>Status</button>
                        <button className="btn btn-sm btn-info mx-1" onClick={(e) => openPrintModal(elem)}>QR</button>
                    </>,
                };
            });

            //  console.log(arrayData);
            setData(arrayData, setShowResults(true));
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        get_data();
    }, []);


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const dataUrl = readerEvent.target.result;
                setImage(dataUrl);
                setImageFile(e.target.files[0]);
            };
            reader.readAsDataURL(file);
        }
    };
    const closeModal = () => {
        //   setIsModalOpen(false);
        setIsAction(false);
    };


    const openPrintModal = (order) => {
        setBooking({
            ...booking,
            booking_id: order?.id,
            customer_id: order?.user_id,
            qrcodes: order?.qrcodes,

        });
        let qrs = [];
        console.log(order?.qrcodes);
        order?.qrcodes?.split(",").forEach(function (e) {

            const tmp = e.split("-");
            const left = <div class="col-6"><h4><b>weclea.com</b></h4>Cust ID: {order.user_id}<br />Bag No: {tmp[1]}<br />Code: {e}</div>;
            const right = <div class="col-6"><QRCode size={86} value={e} /></div>;

            const ele = <div class='row mt-1'>{left} {right}</div>;
            const sticker = <div>{ele}<h4 class="my-0">Premium Laundry Care by WeClea</h4></div>

            qrs.push(sticker);


        })
        console.log(qrs);

        setQrcodes(qrs)
        setPrintClicked(0);
        setIsPrint(true);
        setScannedCodes([]);
        get_data();


    }
    const closePrintModal = () => {

        setIsPrint(false);

    }

    useEffect(() => {

        if (qrcodes && qrcodes.length > 0 && printClicked === 1) {
            var mywindow = window.open('', '', '');
            if (mywindow) {
                mywindow.document.write('<html><head><style>.col-6{width:50%;font-size:10pt;font-family: Arial, Helvetica, sans-serif;}.row{display:flex;width:2in;margin-left:0.1in;padding-top:0.05in;margin-bottom:0;padding-bottom:0}h4{margin-top:0.02in;margin-bottom:0;font-size:8pt;margin-left:0.1in;}.row h4{margin-top:0;margin-bottom:0.06in;font-size:12pt;margin-left:0;}@page{ size: auto;margin: 0mm;}</style>');
                mywindow.document.write('</head><body >');
                mywindow.document.write(document.getElementById("qrs").innerHTML);
                mywindow.document.write('</body></html>');

                //    mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10*/

                mywindow.print();
            }
            setPrintClicked(0);
        }

    }, [qrcodes, printClicked]);


    const handlePrintOnlyQr = () => {

        if (qrcodes && qrcodes.length > 0) {

            var mywindow = window.open('', '', '');
            if (mywindow) {
                mywindow.document.write('<html><head><style>.col-6{width:50%;font-size:10pt;font-family: Arial, Helvetica, sans-serif;}.row{display:flex;width:2in;margin-left:0.1in;padding-top:0.05in;margin-bottom:0;padding-bottom:0}h4{margin-top:0.02in;margin-bottom:0;font-size:8pt;margin-left:0.1in;}.row h4{margin-top:0;margin-bottom:0.06in;font-size:12pt;margin-left:0;}@page{ size: auto;margin: 0mm;}</style>');
                mywindow.document.write('</head><body >');
                mywindow.document.write(document.getElementById("qrs").innerHTML);
                mywindow.document.write('</body></html>');
                mywindow.focus();
                mywindow.print();

            }
            mywindow.close();
            setPrintClicked(0);
        }

    }


    const handlePrintQr = () => {

        setIsLoader(true);
        const form = new FormData();
        form.append('booking_id', booking.booking_id);
        form.append('no_of_bags', noOfBags);

        axios.post(HTTPURL + `/api/driver-add-qrcode`, form, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setIsLoader(false);

            if (response?.data?.status) {

                const qr_data = response?.data?.data?.reverse();
                let qrs = [];
                qr_data.forEach(function (e) {

                    const left = <div class="col-6"><h4><b>weclea.com</b></h4>Cust ID: {booking.customer_id}<br />Bag No: {e.bag_no}<br />Code: {e.qrcode}</div>;
                    const right = <div class="col-6"><QRCode size={86} value={e.qrcode} /></div>;

                    const ele = <div class='row mt-1'>{left} {right}</div>;
                    const sticker = <div>{ele}<h4 class="my-0">Premium Laundry Care by WeClea</h4></div>

                    qrs.push(sticker);


                })
                setQrcodes(qrs) //simple value      
                setPrintClicked(1);
                get_data();


            } else {
                toast.error("Error in QR Printing. Reason: " + response?.data?.messages)

            }
        }).catch((error) => {
            setIsLoader(false);
            toast.error("Error in QR Printing. Reason: " + error)

        })



    }

    const handleScanQr = () => {

        setScanStart(1);

    }

    const handleAttachQr = () => {

        setIsLoader(true);
        const form = new FormData();
        form.append('booking_id', booking.booking_id);
        form.append('no_of_bags', scannedCodes.length);
        form.append('scanned_codes', scannedCodes);

        axios.post(HTTPURL + `/api/attach-qrcode`, form, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setIsLoader(false);

            if (response?.data?.status) {

                setScanStart(0);
                setScannedCodes([]);
                setQrcodes([])
                get_data();
                setIsPrint(false);
                toast.success("Scanned and QR is attached to this booking successfully.")

            } else {
                toast.error("Error in QR code attachment. Reason: " + response?.data?.message)

            }
        }).catch((error) => {
            setIsLoader(false);
            toast.error("Error in QR Printing. Reason: " + error)

        })






    }

    const handleDeattachQr = () => {


        setIsLoader(true);
        const form = new FormData();
        form.append('booking_id', booking.booking_id);

        axios.post(HTTPURL + `/api/driver-remove-qrcodes`, form, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setIsLoader(false);

            if (response?.data?.status) {

                setScannedCodes([]);
                setQrcodes([])
                get_data();
                toast.success("QR codes has been deattached to this booking successfully.")

            } else {
                toast.error("Error in QR code attachment. Reason: " + response?.data?.message)

            }
        }).catch((error) => {
            setIsLoader(false);
            toast.error("Error in QR Printing. Reason: " + error)

        })





    }

    const openActionModal = (order) => {
        setActionType(1)
        setIsAction(true);
        setIsLoader(false);

        setOrderAssign({
            ...orderAssign,
            booking_id: order?.id,
            driver_id: order.driver_id,
            folder_id: order.folder_id,
            folder_instructions: order?.folder_instructions,
            driver_instructions: order?.driver_instructions,
            bin: order?.bin,
            booking_status: order?.order_status,
            booking_unit: order?.unit,
            paid_amount: order?.paid_amount,
            price: order?.price,
            min_order: order?.min_order,
            credit: order?.credit,
            unit: order?.unit,
            user_id: order?.user_id,
            qrcodes: order?.qrcodes,

        })

        setId(order?.user_id)
        setWeight([0]);

        setEstimatedLoads(order.estimated_loads);
        setPrice(order.price);
        setMinOrder(order.min_order);
        setTotalLoads(order.total_loads);
        setBin(order.bin);
        if (order?.unit == 2) {
            setIsStatusImage([false, false, false, false, false]);
            setImageFile([null, null, null, null, null]);
            setImage([null, null, null, null, null]);

            setExtraLoad([0, 0, 0, 0, 0])
        } else {
            setIsStatusImage([false]);
            setImageFile([null]);
            setImage([null]);

            setExtraLoad([0])


        }

    }


    const changeAction = (value, e) => {
        setActionType(Number(value))
        // setOrderAssign({ ...orderAssign, driver_id: 0 })
        // setOrderAssign({ ...orderAssign, folder_id: 0 })
        let elements = document.getElementsByClassName("action-btn")
        for (let ele of elements) {
            ele.classList.remove('active-status')
        }
        e.target.classList.add('active-status');
        setIsStatusImage(false);

    }
    const handleSubmit = async () => {

        setIsLoader(true)
        const form = new FormData();
        form.append('booking_id', orderAssign?.booking_id);
        form.append('status', orderAssign?.booking_status);


        if (orderAssign.paid_amount <= 0 && orderAssign?.booking_status == 6) {

            toast.error("Laundry is not charged yet and cannot be marked as completed until paid.")
        }
        else {

            let response = await axios.post(HTTPURL + '/api/update-driver-booking-status', form,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': localStorage.getItem('token')
                    },
                }
            );

            var result = response?.data
            setIsLoader(false)

            if (result?.status) {
                toast.success(result?.message)
                closeModal();
                get_data();
            } else {
                toast.error(result?.message)
            }
        }


    }



    return (
        <div className="container-fluid" >
            <div className="row justify-content-center" >
                <div className=" col-12  mt-3" >
                    <div className="card" id="my-profile">
                        <div className="card-header">
                            <h3>Laundry Orders</h3>

                        </div>


                        <div className="card-body px-0 py-2">

                            {showResults ? (
                                <>
                                    <OrderDataTable columns={columns} data={data} />

                                </>
                            ) : (
                                <DataLoader />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal animation={false}
                show={isPrint}
                onHide={closePrintModal}
                backdrop="static"
                keyboard={false}
                id="print-qr-modal"
            >
                <div className="modal-inner-content">

                    <div className="modal-header mb-3">
                        <h4>Print QR Code</h4>
                    </div>

                    <div className="row">


                        <>
                            {qrcodes && qrcodes.length > 0 ?

                                <div id="qrs" className="col-12" style={{ maxWidth: 350 }}>
                                    {qrcodes}
                                </div>
                                :
                                <div className="col-12 mb-2">

                                    <label>No. of Bags: </label>
                                    <input type="text" name="no_of_bags[]" onChange={(e) => setNoOfBags(e.target.value)} className="form-control extra-load mx-2" />

                                </div>
                            }
                            {scanStart == 1 &&

                                <>
                                    {scannedCodes?.length > 0 &&
                                        <>
                                            <button type='button' className="btn btn-success" onClick={handleAttachQr} disabled={isLoader}>Attach Scanned QR to this Booking
                                                {isLoader ? <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> : ''}
                                            </button>
                                            {scannedCodes?.map((scannedqrcode, i) => {

                                                return (<div id="scannedqrs" className="col-12" style={{ maxWidth: 350 }}>
                                                    <div>
                                                        <div class='row mt-5'>
                                                            <div class="col-6"><h4><b>weclea.com</b></h4>Cust ID: {booking.customer_id}<br />Bag No: {scannedqrcode.split("-")[1]}<br />Code: {scannedqrcode}</div>
                                                            <div class="col-6"><QRCode size={86} value={scannedqrcode} /></div>
                                                        </div>
                                                        <h4 class="my-0">Premium Laundry Care by WeClea</h4>
                                                    </div>
                                                </div>)
                                            })}
                                        </>
                                    }
                                    <Scanner
                                        onResult={(text, result) => {
                                            console.log(text)
                                            if (scannedCodes?.length > 0 && scannedCodes?.indexOf(text) > -1) {
                                                toast.error("Already scanned, please proceed.")

                                            }
                                            else {

                                                setScannedCodes(scannedCodes => [...scannedCodes, text]);

                                            }

                                        }}
                                        onError={(error) => console.log(error?.message)}
                                    />



                                </>
                            }
                        </>
                    </div>

                    <div className="modal-footer">
                        {scanStart !== 1 &&
                            <>
                                {qrcodes && qrcodes.length > 0 ?
                                    <>
                                        <button type='button' className="btn btn-success" onClick={handleDeattachQr} disabled={isLoader}>Deattach/Remove QR
                                            {isLoader ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : ''}
                                        </button>
                                        <button type='button' className="btn btn-info" onClick={handlePrintOnlyQr} >Print QR
                                            {isLoader ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : ''}
                                        </button>
                                    </>

                                    :
                                    <>
                                        <button type='button' className="btn btn-success" onClick={handlePrintQr} disabled={isLoader}>Print & Attach QR
                                            {isLoader ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : ''}
                                        </button>

                                        <button type='button' className="btn btn-success" onClick={handleScanQr} disabled={isLoader}>Scan QR
                                            {isLoader ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : ''}
                                        </button>


                                    </>
                                }
                            </>
                        }
                        <button type='button' className="btn btn-warning" onClick={closePrintModal} >Close</button>
                    </div>
                </div>



            </Modal>



            <Modal animation={false}
                show={isAction}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                id="action-modal"
            >
                <div className="modal-inner-content">
                    <div className="modal-heading"><h2>Update Status</h2></div>

                    <div className="col-lg-12 mt-4">

                        <div className='form-group mt-2 '>
                            <label className="form-label " >Order Status:
                                <select className="form-control-inline" onChange={(e) => setOrderAssign({ ...orderAssign, booking_status: parseInt(e.target.value) })}>
                                    <option selected={orderAssign?.booking_status === 0} value="0">New</option>
                                    <option selected={orderAssign?.booking_status === 8} value="8">Order Pickup</option>
                                    <option selected={orderAssign?.booking_status === 6} value="6">Completed</option>
                                </select>
                            </label>

                        </div>

                    </div>

                    <div className="modal-footer">
                        <button type='button' className="btn btn-lg btn-success" onClick={handleSubmit} disabled={isLoader}>Submit
                            {isLoader ? <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''}
                        </button>
                        <button type='button' className="btn btn-lg btn-warning" onClick={closeModal}>Close</button>
                    </div>

                </div>
            </Modal>
        </div>

    );
};
export default LaundryOrdersList;
