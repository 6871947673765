import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { HTTPURL } from "../../../constants/Matcher";
import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { AssignDriver, AssignFolder, GetAllOrders, UpdateStatus, AddLoad, updateInstruction } from "../../../apis/Orders";
import OrderDataTable from "./OrderDataTable";
import { Modal, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import dateFormat from 'date-and-time';
import QRCode from "react-qr-code";
import { Scanner } from '@yudiel/react-qr-scanner';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Webcam from "react-webcam";


export const columns = [
    {
        name: " ID",
        selector: row => row.order_link,
        sortable: true,
        width: "80px",
    },
    {
        name: "Customer",
        selector: row => row.name,
        sortable: true,
    },
    {
        name: "Price",
        width: "80px",
        selector: row => row.price,
        sortable: true,
    },
    {
        name: "Category",
        width: "100px",
        selector: row => row.category_title,
        sortable: true,
    },
    {
        name: "PICKUP",
        width: "250px",
        selector: row => row.pickup,
        sortable: true,
    },
    {
        name: "DELIVERY",
        selector: row => row.delivery,
        sortable: true,
        width: "250px",
    },
    {
        name: "STATUS",
        selector: row => row.status,
        width: "90px",
    },
    {
        name: "Es. Bags/Wt.",
        selector: row => row.estimated_loads,
        sortable: true,
    },
    {
        name: "Bags/Wt.",
        selector: row => row.total_loads,
        sortable: true,
    },
    {
        name: "Paid",
        selector: row => row.paid_amount,
        sortable: true,
        width: "80px",
    },
    {
        name: "Credit",
        selector: row => row.credit,
        sortable: true,
        width: "90px",
    },
    {
        name: "Actions",
        selector: row => row.action,
        sortable: true,
        width: "140px",
    },
];
const Orders = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [printClicked, setPrintClicked] = useState(0);
    const [scanStart, setScanStart] = useState(0);
    const [scannedCodes, setScannedCodes] = useState([]);
    const [booking, setBooking] = useState({});
    const [noOfBags, setNoOfBags] = useState(0);
    const [qrcodes, setQrcodes] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [driverList, setDriverList] = useState([])
    const [folderList, setFolderList] = useState([])
    const [deliveryDays, setDeliveryDays] = useState([])
    const [actionType, setActionType] = useState(1)
    const [orderAssign, setOrderAssign] = useState({
        booking_id: 0,
        driver_id: 0,
        folder_id: 0,
        folder_instructions: '',
        driver_instructionss: '',
        bin: '',
        price: '',
        min_order: '',
        estimated_loads: 0,
        total_loads: 0,
        paid_amount: 0,
        booking_status: 0,
        qrcodes: '',
        date: '',
        delivery_day: '',
        days_gap: 0
    })
    const [id, setId] = useState(0);
    const [extraLoad, setExtraLoad] = useState([0]);
    const [weight, setWeight] = useState([0]);
    const [statusCam, setStatusCam] = useState(false);
    const [statusImages, setStatusImages] = useState([]);
    const [chargeCam, setChargeCam] = useState(false);
    const [chargeImages, setChargeImages] = useState([]);
    async function get_data() {
        try {
            var todaysDate = new Date();
            const result = await GetAllOrders();
            // console.log(result);
            const arrayData = result?.data?.map((elem, index) => {
                const {
                    id, user_id, folder_instructions, driver_instructions, bin, order_id, price, unit, date, delivery_day, estimated_loads, total_loads, order_type,
                    driver, order_images, order_status, credit, folder, name, email, mobile, order_type_id, paid_amount, category_title,
                    delivery_address, pickup_address, qrcodes
                } = elem;
                var pickup_date = new Date(date);
                return {
                    order_no: id,
                    order_link: <a href={`/order-details/${id}`} >{id}</a>,
                    user_id: user_id,
                    name: name,
                    email: email,
                    mobile: mobile,
                    category_title: category_title,
                    price: (unit === 1) ? price + '/bag' : + price + '/lb',
                    pickup: Moment(new Date(date + "T00:00:00")).format('ddd, MMM DD, YY'),
                    pickupdate: date,
                    delivery: delivery_day,
                    folder_instructions: folder_instructions,
                    driver_instructions: driver_instructions,
                    bin: bin,
                    total_loads: (unit === 1) ? total_loads + ' bags' : + total_loads + ' lbs',
                    estimated_loads: (unit === 1) ? estimated_loads + ' bags' : + estimated_loads + ' lbs',
                    order_type: order_type,
                    delivery_address: delivery_address,
                    pickup_address: pickup_address,
                    driver: driver,
                    folder: folder,
                    credit: credit,
                    qrcodes: qrcodes,
                    paid_amount: paid_amount,
                    color: (pickup_date.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) ? 1 : 2,
                    status: order_status == 0 ? "New" : order_status == 1 ? "Washed" : order_status == 2 ? "Dry" : order_status == 3 ? "Folded" : order_status == 4 ? "Packed" : order_status == 8 ? "Picked" : '',
                    action: <> <button className="btn btn-sm btn-danger" onClick={(e) => openActionModal(elem)}>Action</button>
                        <button className="btn btn-sm btn-info mx-1" onClick={(e) => openPrintModal(elem)}>QR</button>
                    </>,
                };
            });
            console.log(arrayData);
            setData(arrayData, setShowResults(true));
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        get_data();
        getDriverList();
        getFolderList();
        getDeliveryDays();
    }, []);
    const getDeliveryDays = () => {

        axios.get(HTTPURL + `/api/get-active-delivery-days`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response?.data?.status) {
                setDeliveryDays(response?.data?.data)
            }
        }).catch((error) => {

        })

    }


    const getFolderList = () => {

        axios.get(HTTPURL + `/api/folders-list`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response?.data?.status) {
                setFolderList(response?.data?.data)
            }
        }).catch((error) => {

        })

    }

    const getDriverList = () => {
        axios.get(HTTPURL + `/api/get-active-drivers/all/0/100`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response?.data?.status) {
                setDriverList(response?.data?.data?.rows)
            }
        }).catch((error) => {

        })
    }

  
    const closeModal = () => {
        //   setIsModalOpen(false);
        setIsAction(false);
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // initialSlide: 1||0
    };
    const openPrintModal = (order) => {
        setBooking({
            ...booking,
            booking_id: order?.id,
            customer_id: order?.user_id,
            qrcodes: order?.qrcodes,
        });
        let qrs = [];
        console.log(order?.qrcodes);
        order?.qrcodes?.split(",").forEach(function (e) {

            const tmp = e.split("-");
            const left = <div class="col-6"><h4><b>weclea.com</b></h4>Cust ID: {order?.user_id}<br />Bag No: {tmp[1]}<br />Code: {e}</div>;
            const right = <div class="col-6"><QRCode size={86} value={e} /></div>;
            const ele = <div class='row'>{left} {right}</div>;
            const sticker = <div>{ele}<h4 class="my-0">Premium Laundry Care by WeClea</h4></div>

            qrs.push(sticker);
        })
        console.log(qrs);
        setQrcodes(qrs)
        setPrintClicked(0);
        setIsPrint(true);
        setScannedCodes([]);
        get_data();
    }
    const closePrintModal = () => {

        setIsPrint(false);
    }

    useEffect(() => {

        if (qrcodes && qrcodes.length > 0 && printClicked === 1) {

            var mywindow = window.open('', '', '');
            if (mywindow) {
                mywindow.document.write('<html><head><style>.col-6{width:50%;font-size:10pt;font-family: Arial, Helvetica, sans-serif;}.row{display:flex;width:2in;margin-left:0.1in;padding-top:0.05in;margin-bottom:0;padding-bottom:0}h4{margin-top:0.02in;margin-bottom:0;font-size:8pt;margin-left:0.1in;}.row h4{margin-top:0;margin-bottom:0.06in;font-size:12pt;margin-left:0;}@page{ size: auto;margin: 0mm;}</style>');
                mywindow.document.write('</head><body >');
                mywindow.document.write(document.getElementById("qrs").innerHTML);
                mywindow.document.write('</body></html>');
                mywindow.focus();
                mywindow.print();
            }
            mywindow.close();
            setPrintClicked(0);
        }

    }, [qrcodes, printClicked]);
    const handlePrintOnlyQr = () => {

        if (qrcodes && qrcodes.length > 0) {

            var mywindow = window.open('', '', '');
            if (mywindow) {
                mywindow.document.write('<html><head><style>.col-6{width:50%;font-size:10pt;font-family: Arial, Helvetica, sans-serif;}.row{display:flex;width:2in;margin-left:0.1in;padding-top:0.05in;margin-bottom:0;padding-bottom:0}h4{margin-top:0.02in;margin-bottom:0;font-size:8pt;margin-left:0.1in;}.row h4{margin-top:0;margin-bottom:0.06in;font-size:12pt;margin-left:0;}@page{ size: auto;margin: 0mm;}</style>');
                mywindow.document.write('</head><body >');
                mywindow.document.write(document.getElementById("qrs").innerHTML);
                mywindow.document.write('</body></html>');
                mywindow.focus();
                mywindow.print();
            }
            mywindow.close();
            setPrintClicked(0);
        }

    }
    const handlePrintQr = () => {

        setIsLoader(true);
        const form = new FormData();
        form.append('booking_id', booking.booking_id);
        form.append('no_of_bags', noOfBags);
        axios.post(HTTPURL + `/api/add-qrcode`, form, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setIsLoader(false);
            if (response?.data?.status) {

                const qr_data = response?.data?.data?.reverse();
                let qrs = [];
                qr_data.forEach(function (e) {

                    const left = <div class="col-6"><h4><b>weclea.com</b></h4>Cust ID: {booking.customer_id}<br />Bag No: {e.bag_no}<br />Code: {e.qrcode}</div>;
                    const right = <div class="col-6"><QRCode size={86} value={e.qrcode} /></div>;
                    const ele = <div class='row mt-1'>{left} {right}</div>;
                    const sticker = <div>{ele}<h4 class="my-0">Premium Laundry Care by WeClea</h4></div>

                    qrs.push(sticker);
                })
                setQrcodes(qrs) //simple value      
                setPrintClicked(1);
                get_data();
            } else {
                toast.error("Error in QR Printing. Reason: " + response?.data?.messages)

            }
        }).catch((error) => {
            setIsLoader(false);
            toast.error("Error in QR Printing. Reason: " + error)

        })



    }

    const handleScanQr = () => {

        setScanStart(1);
    }

    const handleAttachQr = () => {

        setIsLoader(true);
        const form = new FormData();
        form.append('booking_id', booking.booking_id);
        form.append('no_of_bags', scannedCodes.length);
        form.append('scanned_codes', scannedCodes);
        axios.post(HTTPURL + `/api/attach-qrcode`, form, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setIsLoader(false);
            if (response?.data?.status) {

                setScanStart(0);
                setScannedCodes([]);
                setQrcodes([])
                get_data();
                setIsPrint(false);
                toast.success("Scanned and QR is attached to this booking successfully.")

            } else {
                toast.error("Error in QR code attachment. Reason: " + response?.data?.message)

            }
        }).catch((error) => {
            setIsLoader(false);
            toast.error("Error in QR Printing. Reason: " + error)

        })






    }

    const handleDeattachQr = () => {


        setIsLoader(true);
        const form = new FormData();
        form.append('booking_id', booking.booking_id);
        axios.post(HTTPURL + `/api/remove-qrcodes`, form, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setIsLoader(false);
            if (response?.data?.status) {

                setScannedCodes([]);
                setQrcodes([])
                get_data();
                toast.success("QR codes has been deattached to this booking successfully.")

            } else {
                toast.error("Error in QR code attachment. Reason: " + response?.data?.message)

            }
        }).catch((error) => {
            setIsLoader(false);
            toast.error("Error in QR Printing. Reason: " + error)

        })





    }

    const openActionModal = (order) => {
        setActionType(1)
        setIsAction(true);
        setIsLoader(false);
        setOrderAssign({
            ...orderAssign,
            booking_id: order?.id,
            driver_id: order.driver_id,
            folder_id: order.folder_id,
            folder_instructions: order?.folder_instructions,
            driver_instructions: order?.driver_instructions,
            bin: order?.bin,
            booking_status: order?.order_status,
            booking_unit: order?.unit,
            paid_amount: order?.paid_amount,
            old_paid_amount: order?.paid_amount,
            price: order?.price,
            min_order: order?.min_order,
            credit: order?.credit,
            unit: order?.unit,
            user_id: order?.user_id,
            qrcodes: order?.qrcodes,
            estimated_loads: order?.estimated_loads,
            total_loads: order?.total_loads,
            old_driver_id: order.driver_id,
            date: order?.date+ "T00:00:00",
            delivery_day: order?.delivery_day,
            days_gap: order?.delivery_gap,
        })

        setId(order?.user_id)
        setWeight([0]);
    
           setChargeImages([]) 
            setExtraLoad([0])


    }


    const changeAction = (value, e) => {
        setActionType(Number(value))
        let elements = document.getElementsByClassName("action-btn")
        for (let ele of elements) {
            ele.classList.remove('active-status')
        }
        e.target.classList.add('active-status');
    }
     function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
      }
    const handleSubmit = async () => {
        if (actionType == 3) {

            setIsLoader(true)

            var consent = true;
            const form = new FormData();
            form.append('booking_id', orderAssign?.booking_id);
            form.append('status', orderAssign?.booking_status);
            for (let i = 0; i < statusImages.length; i++) {
                
                const file = DataURIToBlob(statusImages[i])

                form.append('status_images', file, 'image.jpg');
            }
            if (orderAssign?.unit == 2 && orderAssign.paid_amount <= 0 && orderAssign?.booking_status == 6) {

                const tmp_weight = weight.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)
                form.append('weight', tmp_weight);
                let total_amount = orderAssign?.min_order * orderAssign?.price;
                if (tmp_weight >= orderAssign?.min_order) {
                    total_amount = tmp_weight * orderAssign?.price;
                }

                if (orderAssign.credit < total_amount) {
                    const balance = total_amount - orderAssign.credit;
                    const str = "Customer credit ( USD " + orderAssign.credit + ") is low than his bill( USD" + total_amount + ") for " + tmp_weight + "lbs" + " An amount of USD" + balance + " will be deducted from customer credit card. Are you sure, you want to proceed.";
                    if (!window.confirm(str)) {
                        consent = false;
                        setIsLoader(false)

                    }

                }
            }

            if (consent) {
                
                      let response_status = await axios.post(HTTPURL + '/api/update-booking-status' , form,
                                              {
                                                headers: {
                                                  "Content-Type": "multipart/form-data",
                                                  'Authorization': localStorage.getItem('token')
                                                },
                                              }
                                            );
          

                var result = response_status?.data ;
                setIsLoader(false)

                if (result?.status) {
                    toast.success(result?.message)
                    closeModal();
                    get_data();
                } else {
                    toast.error(result?.message)
                }
            }

        } else if (actionType == 8) {
            setIsLoader(true);
            var consent = true;
            const form = new FormData();
            form.append('booking_id', orderAssign?.booking_id);
            const tmp_weight = extraLoad.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)
           if (tmp_weight <= 0) {
            toast.error("Please Enter Weight/Bags");
             setIsLoader(false);
        }
else{
            form.append('extra_loads', tmp_weight);
            let total_amount = orderAssign?.min_order * orderAssign?.price;
            if (tmp_weight >= orderAssign?.min_order) {
                total_amount = tmp_weight * orderAssign?.price;
            }

             for (let i = 0; i < chargeImages.length; i++) {

                const file = DataURIToBlob(chargeImages[i])
                form.append('charge_images', file, 'charge.jpg');

            }

            if (orderAssign.credit < total_amount) {
                const balance = total_amount - orderAssign.credit;
                const str = "Customer credit ( USD " + orderAssign.credit + ") is low than his bill( USD" + total_amount + ") for " + tmp_weight + "lbs" + " An amount of USD" + balance + " will be deducted from customer credit card. Are you sure, you want to proceed.";
                if (!window.confirm(str)) {
                    consent = false;
                    setIsLoader(false)

                }

            }

            if (consent) {
                var result = await AddLoad(form, '/api/add-load');
                setIsLoader(false);
                if (result?.status) {
                    toast.success(result?.message)
                    closeModal();
                    get_data();
                } else {
                    toast.error(result?.message)
                }
            }
            }
        }
        else if (actionType == 1) {

            if (orderAssign.old_paid_amount > 0 && (orderAssign?.paid_amount <= 0 || orderAssign?.total_loads <= 0)) {

                toast.error("This Order is already charged, Please enter correct charged amount & Actual Weight/Bags");
            }
            else if (orderAssign.old_paid_amount <= 0 && (orderAssign?.paid_amount > 0)) {

                toast.error("This Order is not yet charged, Charge amount must be zero. Please charge first.");
            }
            else if (orderAssign?.price <= 0 || orderAssign?.estimated_loads <= 0 || orderAssign?.min_order <= 0) {

                toast.error("Price, Min Weight and Estimated Weigth are required");
            }
            else {

                setIsLoader(true)
                const form = new FormData();
                form.append('booking_id', orderAssign?.booking_id);
                form.append('price', isNaN(orderAssign?.price) ? 0 : orderAssign?.price);
                form.append('min_order', isNaN(orderAssign?.min_order) ? 0 : orderAssign?.min_order);
                form.append('estimated_loads', isNaN(orderAssign?.estimated_loads) ? 0 : orderAssign?.estimated_loads);
                form.append('total_loads', isNaN(orderAssign?.total_loads) ? 0 : orderAssign?.total_loads);
                form.append('bin', orderAssign?.bin);
                form.append('folder_id', orderAssign?.folder_id);
                form.append('driver_id', orderAssign?.driver_id);
                form.append('old_driver_id', orderAssign?.old_driver_id);
                form.append('paid_amount', isNaN(orderAssign?.paid_amount) ? 0 : orderAssign?.paid_amount);
                form.append('folder_instructions', orderAssign?.folder_instructions);
                form.append('driver_instructions', orderAssign?.driver_instructions);
                form.append('date', Moment(orderAssign?.date).format('YYYY-MM-DD'));
                form.append('delivery_day', orderAssign?.delivery_day);
                form.append('days_gap', orderAssign?.days_gap);
                axios.post(HTTPURL + "/api/update-order/", form, {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': localStorage.getItem('token')
                    }
                }).then((response) => {
                    if (response?.data?.status) {
                        toast.success(response?.data?.message);
                        setIsLoader(false);
                        get_data();
                        closeModal();
                    } else {
                        toast.error(response?.data?.message)
                    }
                })


            }



        }

    }




    const addNewElement = (e) => {
        var tmp = Object.values({ ...extraLoad });
            tmp.push(0);
            setExtraLoad(tmp);
       
    }



    const addNewWeight = (e) => {
        var tmp = Object.values({ ...weight });
        if (tmp.length < 20) {
            tmp.push(0);
            setWeight(tmp);
        } else {
            toast.error("Max 20 Wt. scale reading allowed.")
        }






    }


    const videoConstraints = {
        width: 300,
        height: 200,
    };
    const webcamRef = useRef(null);
    const capture = () => {

        const imageSrc = webcamRef.current.getScreenshot();
        setStatusImages(statusImages => [...statusImages, imageSrc]);
        
    }
    
    
    const webcamChargeRef = useRef(null);
   
    const captureCharge = async () => {

        const imageSrc = webcamChargeRef.current.getScreenshot();
        setChargeImages(chargeImages => [...chargeImages, imageSrc]);

    }





    return (
        <>
            <div className="right-nav py-4 px-2" id="dash-event">
                <h3>Laundry Orders</h3>
                {showResults ? (
                    <>
                        <OrderDataTable columns={columns} data={data} />

                    </>
                ) : (
                    <DataLoader />
                )}
            </div>

            <Modal animation={false}
                show={isPrint}
                onHide={closePrintModal}
                backdrop="static"
                keyboard={false}
                id="print-qr-modal"
            >
                <div className="modal-inner-content">

                    <div className="modal-header mb-3">
                        <h4>Print QR Code</h4>
                    </div>

                    <div className="row">
                        <>
                            {qrcodes && qrcodes.length > 0 ?

                                <div id="qrs" className="col-12" style={{ maxWidth: 350 }}>
                                    {qrcodes}
                                </div>
                                :
                                <div className="col-12 mb-2">

                                    <label>No. of Bags: </label>
                                    <input type="text" name="no_of_bags[]" onChange={(e) => setNoOfBags(e.target.value)} className="form-control extra-load mx-2" />

                                </div>
                            }
                            {scanStart == 1 &&
                                <>
                                    {scannedCodes?.length > 0 &&
                                        <>
                                            <button type='button' className="btn btn-success" onClick={handleAttachQr} disabled={isLoader}>Attach Scanned QR to this Booking
                                                {isLoader ? <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div> : ''}
                                            </button>
                                            {scannedCodes?.map((scannedqrcode, i) => {

                                                return (<div id="scannedqrs" className="col-12" style={{ maxWidth: '350px', fontSize: '12px' }}>
                                                    <div>
                                                        <div class='row'>
                                                            <div class="col-6"><h5><b>weclea.com</b></h5>Cust ID: {booking.customer_id}<br />Bag No: {scannedqrcode.split("-")[1]}<br />QR Code: {scannedqrcode}</div>
                                                            <div class="col-6"><QRCode size={86} value={scannedqrcode} /></div>
                                                        </div>
                                                        <h4 class="my-0">Premium Laundry Care by WeClea</h4>
                                                    </div>
                                                </div>)
                                            })}
                                        </>
                                    }
                                    <Scanner
                                        onResult={(text, result) => {
                                            console.log(text)
                                            if (scannedCodes?.length > 0 && scannedCodes?.indexOf(text) > - 1) {
                                                toast.error("Already scanned, please proceed.")

                                            }
                                            else {

                                                setScannedCodes(scannedCodes => [...scannedCodes, text]);
                                            }

                                        }}
                                        onError={(error) => console.log(error?.message)}
                                    />



                                </>
                            }
                        </>
                    </div>

                    <div className="modal-footer">
                        {scanStart !== 1 &&
                            <>
                                {qrcodes && qrcodes.length > 0 ?
                                    <>
                                        <button type='button' className="btn btn-success" onClick={handleDeattachQr} disabled={isLoader}>Deattach/Remove QR
                                            {isLoader ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : ''}
                                        </button>
                                        <button type='button' className="btn btn-info" onClick={handlePrintOnlyQr} >Print QR
                                            {isLoader ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : ''}
                                        </button>
                                    </>

                                    :
                                    <>
                                        <button type='button' className="btn btn-success" onClick={handlePrintQr} disabled={isLoader}>Print & Attach QR
                                            {isLoader ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : ''}
                                        </button>

                                        <button type='button' className="btn btn-success" onClick={handleScanQr} disabled={isLoader}>Scan QR
                                            {isLoader ? <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> : ''}
                                        </button>


                                    </>
                                }
                            </>
                        }
                        <button type='button' className="btn btn-warning" onClick={closePrintModal} >Close</button>
                    </div>
                </div>



            </Modal>



            <Modal animation={false}
                show={isAction}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                id="action-modal"
            >
                <div className="modal-inner-content">
                    <div className="modal-heading"><h2>Perform Action</h2></div>
                    <div class="swc-bot">
                        <div class="swc-dual-btn">
                            <button className="action-btn btn btn-lg btn-danger me-1 active-status" onClick={(e) => changeAction(1, e)}>Update</button>
                            <button className="action-btn btn btn-lg btn-danger me-1" onClick={(e) => { changeAction(3, e); setStatusImages([]); }}>Update Status</button>
                            <button className="action-btn btn btn-lg btn-danger me-1" onClick={(e) => changeAction(8, e)}>Charge </button>

                        </div>
                    </div>
                    {actionType == 1 &&
                        <>
                            <div className="row mt-3">
                                <div class="col-lg-6 mb-2  mt-3">
                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Price:</label>
                                        </div>
                                        <div class="col-auto">
                                            <input className="form-control" type="text" name="price" id="price" value={orderAssign?.price} onChange={(e) => { setOrderAssign({ ...orderAssign, price: e.target.value }) }} />
                                        </div>
                                        <div class="col-auto">
                                            {orderAssign?.unit == 1 ? "/bag" : "/lb"}
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-6 mb-2  mt-3">
                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Min Wt./Bags:</label>
                                        </div>
                                        <div class="col-auto">

                                            <input className="form-control" type="text" name="min_order" id="min_order" value={orderAssign?.min_order} onChange={(e) => { setOrderAssign({ ...orderAssign, min_order: e.target.value }) }} />


                                        </div>
                                        <div class="col-auto">
                                            {orderAssign?.unit == 1 ? "bags" : "lbs"}
                                        </div>



                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div class="col-md-6  mb-2  mt-3">

                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Estimated Wt.</label>
                                        </div>
                                        <div class="col-auto">


                                            <input className="form-control" type="text" name="estimated_load" id="estimated_load" value={orderAssign?.estimated_loads} onChange={(e) => { setOrderAssign({ ...orderAssign, estimated_loads: e.target.value }) }} />

                                        </div>
                                        <div class="col-auto">
                                            {orderAssign?.unit == 1 ? "bags" : "lbs"}
                                        </div>


                                    </div>
                                </div>


                                <div class="col-md-6 mb-2  mt-3">

                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Actual Wt.</label>
                                        </div>
                                        <div class="col-auto">
                                            <input className="form-control" type="text" name="total_loads" id="total_loads" value={orderAssign?.total_loads} onChange={(e) => { setOrderAssign({ ...orderAssign, total_loads: e.target.value }) }} />
                                        </div>

                                        <div class="col-auto">
                                            {orderAssign?.unit == 1 ? "bags" : "lbs"}
                                        </div>


                                    </div>


                                </div>
                            </div>


                            <div className="row ">
                                <div class="col-md-6 mb-2  mt-3">
                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Bin #:</label>
                                        </div>
                                        <div class="col-auto">

                                            <input className="form-control " type="text" name="bin" id="bin" value={orderAssign?.bin} onChange={(e) => { setOrderAssign({ ...orderAssign, bin: e.target.value }) }} />


                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-2  mt-3">


                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Amount Paid:</label>
                                        </div>
                                        <div class="col-auto">

                                            <input className="form-control" type="text" name="paid_amount" id="paid_amount" value={orderAssign?.paid_amount} onChange={(e) => { setOrderAssign({ ...orderAssign, paid_amount: e.target.value }) }} />

                                        </div>
                                        <div class="col-auto">USD</div>


                                    </div>


                                </div>
                            </div>

                            <div className="row ">
                                <div class="col-md-6 mb-2  mt-3">
                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Pickup Date:</label>
                                        </div>
                                        <div class="col-auto">

                                            <DatePicker className="form-control" selected={new Date(orderAssign?.date )} onChange={(e) => { setOrderAssign({ ...orderAssign, date: e }) }} />


                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-2  mt-3">
                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Delivery:</label>
                                        </div>
                                        <div class="col-auto">
                                            <select className="form-control text-capitalize" aria-label="Default select example" id="delivery_day"
                                                onChange={(e) => {
                                                    console.log(e.target);
                                                    const selectedIndex = e.target.options.selectedIndex;
                                                    setOrderAssign({ ...orderAssign, days_gap: e.target.value, delivery_day: e.target.options[selectedIndex].getAttribute('data-key') });
                                                }
                                                }
                                            >
                                                <option value="0" disabled selected>Delivery</option>
                                                {deliveryDays.map((d) => (
                                                    <option key={d?.delivery_day} data-key={d?.delivery_day} value={d?.days_gap} selected={d?.delivery_day == orderAssign.delivery_day} >{d?.delivery_day} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row ">
                                <div class="col-md-6 mb-2  mt-3">
                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Driver:</label>
                                        </div>
                                        <div class="col-auto">

                                            <select className="form-control text-capitalize" aria-label="Default select example"
                                                onChange={(e) =>
                                                    setOrderAssign({ ...orderAssign, driver_id: e.target.value })
                                                }
                                                id="category"
                                            >
                                                <option value="0" disabled selected>Select Driver</option>
                                                {driverList.map((driver) => (
                                                    <option key={driver?.id} value={driver?.id} selected={driver?.id == orderAssign.driver_id}  >{driver?.name} ({driver?.email})</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-2  mt-3">


                                    <div class="row align-items-center">
                                        <div class="col-xl-3 col-lg-4">
                                            <label className="">Folder:</label>
                                        </div>
                                        <div class="col-auto">

                                            <select
                                                className="form-control text-capitalize" aria-label="Default select example"
                                                onChange={(e) =>
                                                    setOrderAssign({ ...orderAssign, folder_id: e.target.value })
                                                }
                                                id="category"
                                            >
                                                <option value="0" disabled selected>Select Folder</option>
                                                {folderList.map((folder) => (
                                                    <option key={folder?.id} value={folder?.id} selected={folder?.id == orderAssign.folder_id} >{folder?.name} ({folder?.email})</option>
                                                ))}
                                            </select>
                                        </div>



                                    </div>


                                </div>
                            </div>


                            <div class="row">
                                <div className="col-lg-6 col-md-6 mt-4">
                                    <label>Folder Instructions</label>
                                    <div className='form-group mt-2'>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="2"
                                            onChange={(e) =>
                                                setOrderAssign({ ...orderAssign, folder_instructions: e.target.value })
                                            }
                                            placeholder="Folder Instructions"
                                            value={orderAssign.folder_instructions}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mt-4">
                                    <label>Driver Instructions</label>
                                    <div className='form-group mt-2'>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="2"
                                            onChange={(e) =>
                                                setOrderAssign({ ...orderAssign, driver_instructions: e.target.value })
                                            }
                                            placeholder="Driver Instructions"
                                            value={orderAssign.driver_instructions}
                                        />
                                    </div>
                                </div>
                            </div>






                        </>
                    }
                    {actionType == 3 &&
                        <div className="col-lg-12 mt-4">

                            <div className='form-group mt-2 '>
                                <label className="form-label " >Order Status: <select className="form-control-inline" onChange={(e) => {
                                    setOrderAssign({ ...orderAssign, booking_status: parseInt(e.target.value) })
                                    setStatusImages([]);
                                }}>
                                    <option selected={orderAssign?.booking_status === 0} value="0">New</option>
                                    <option selected={orderAssign?.booking_status === 8} value="8">Order Pickup</option>
                                    <option selected={orderAssign?.booking_status === 1} value="1">Wash</option>
                                        <option selected={orderAssign?.booking_status === 2} value="2">Dry</option>
                                        <option selected={orderAssign?.booking_status === 3} value="3">Fold</option>
                                        <option selected={orderAssign?.booking_status === 4} value="4">Pack</option>
                                        <option selected={orderAssign?.booking_status === 6} value="6">Completed</option>

                                </select>

    <a className="mx-2" onClick={(e) => { setStatusCam(true); }} >
                                        <svg className="inline" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" >
                                            <path d="M18 24C20.4853 24 22.5 21.9853 22.5 19.5C22.5 17.0147 20.4853 15 18 15C15.5147 15 13.5 17.0147 13.5 19.5C13.5 21.9853 15.5147 24 18 24Z"
                                                stroke="#333333" stroke-width="1.5" />
                                            <path d="M14.667 31.5001H21.333C26.0145 31.5001 28.356 31.5001 30.0375 30.3976C30.763 29.922 31.3879 29.3084 31.8765 28.5916C33 26.9416 33 24.6421 33 20.0461C33 15.4486 33 13.1506 31.8765 11.5006C31.3879 10.7837 30.7631 10.1701 30.0375 9.69457C28.9575 8.98507 27.6045 8.73157 25.533 8.64157C24.5445 8.64157 23.694 7.90657 23.5005 6.95407C23.3526 6.25637 22.9684 5.63112 22.4128 5.184C21.8571 4.73687 21.1642 4.49528 20.451 4.50007H15.549C14.067 4.50007 12.7905 5.52757 12.4995 6.95407C12.306 7.90657 11.4555 8.64157 10.467 8.64157C8.397 8.73157 7.044 8.98657 5.9625 9.69457C5.23743 10.1702 4.61306 10.7838 4.125 11.5006C3 13.1506 3 15.4486 3 20.0461C3 24.6421 3 26.9401 4.1235 28.5916C4.6095 29.3056 5.2335 29.9191 5.9625 30.3976C7.644 31.5001 9.9855 31.5001 14.667 31.5001Z"
                                                stroke="#333333" stroke-width="1.5" />
                                            <path d="M28.5 15H27" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
                                        </svg>

                                    </a>
                                </label>


                                {statusImages && statusImages.length > 0 &&
                                    <div className="row mt-3" >

                                        {statusImages.map((el, i) => (
                                            <div className="col-sm-6 col-md-4 col-lg-3 item position-relative" >

                                                <img className="img-fluid mb-3" src={el} />
                                                <button onClick={(e) => {
                                                    let arr = [...statusImages];
                                                    arr.splice(i, 1)
                                                    setStatusImages(arr);
                                                }} type="button" className="btn btn-link text-white position-absolute top-0 end-0 px-3 py-0" ><i className="fa fa-remove" /></button>

                                            </div>

                                        ))}

                                    </div>
                                }

                            </div>
                            {orderAssign.paid_amount <= 0 && orderAssign.booking_status == 6 && orderAssign.unit == 2 &&
                                <div className='form-group mt-3'>

                                    <label>{orderAssign?.booking_unit == 2 ? "Weighing Scale Readings (Total Wt.)" : "Total Bags"}</label>
                                    {weight?.map((extra, index) => (
                                        <input type="number" name="weight[]" onChange={(e) => setWeight(Object.values({ ...weight, [index]: e.target.value }))} className="form-control extra-load" />

                                    ))}
                                    {orderAssign?.booking_unit == 2 &&
                                        <>
                                            <butoon class="d-inline-block btn-lg btn-success" onClick={addNewWeight} ><i class="fa fa-plus"></i></butoon>

                                            <label className="mt-2">Total: {weight.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)}</label>
                                        </>
                                    }
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                </div>

                            }




                        </div>
                    }
                    {actionType == 8 &&
                        <div className="col-lg-12 col-md-6 mt-4">
                            <label>{orderAssign?.booking_unit == 2 ? "Weighing Scale Readings (Total Wt.)" : "Total Bags"}</label>
                            <div className='form-group mt-2'>
                                {extraLoad?.map((extra, index) => (
                                    <input type="number" name="extra_load[]" onChange={(e) => setExtraLoad(Object.values({ ...extraLoad, [index]: e.target.value }))} className="form-control extra-load" />

                                ))}
                                {orderAssign?.booking_unit == 2 &&
                                    <>
                                        <butoon class="d-inline-block btn-lg btn-success" onClick={addNewElement} ><i class="fa fa-plus"></i></butoon>
 <a href='#' className=" mx-1 " onClick={(e) => {
                                                   
                                                        setChargeCam(true); setChargeImages([]);
                                                  

                                                }} >
      <svg className="inline" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" >
                                            <path d="M18 24C20.4853 24 22.5 21.9853 22.5 19.5C22.5 17.0147 20.4853 15 18 15C15.5147 15 13.5 17.0147 13.5 19.5C13.5 21.9853 15.5147 24 18 24Z"
                                                stroke="#333333" stroke-width="1.5" />
                                            <path d="M14.667 31.5001H21.333C26.0145 31.5001 28.356 31.5001 30.0375 30.3976C30.763 29.922 31.3879 29.3084 31.8765 28.5916C33 26.9416 33 24.6421 33 20.0461C33 15.4486 33 13.1506 31.8765 11.5006C31.3879 10.7837 30.7631 10.1701 30.0375 9.69457C28.9575 8.98507 27.6045 8.73157 25.533 8.64157C24.5445 8.64157 23.694 7.90657 23.5005 6.95407C23.3526 6.25637 22.9684 5.63112 22.4128 5.184C21.8571 4.73687 21.1642 4.49528 20.451 4.50007H15.549C14.067 4.50007 12.7905 5.52757 12.4995 6.95407C12.306 7.90657 11.4555 8.64157 10.467 8.64157C8.397 8.73157 7.044 8.98657 5.9625 9.69457C5.23743 10.1702 4.61306 10.7838 4.125 11.5006C3 13.1506 3 15.4486 3 20.0461C3 24.6421 3 26.9401 4.1235 28.5916C4.6095 29.3056 5.2335 29.9191 5.9625 30.3976C7.644 31.5001 9.9855 31.5001 14.667 31.5001Z"
                                                stroke="#333333" stroke-width="1.5" />
                                            <path d="M28.5 15H27" stroke="#333333" stroke-width="1.5" stroke-linecap="round" />
                                        </svg>                                            
    </a>
                                        <label>Total: {extraLoad.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)}</label>
                                    </>
                                }
                                
                                
                                
                                  
                                
                                 {chargeImages && chargeImages.length > 0 &&
                        <div className="row mt-3" >

                            {chargeImages.map((el, i) => (
                                <div className="col-sm-6 col-md-4 col-lg-3 item position-relative" >

                                    <img className="img-fluid mb-3" src={el} />
                                    <button onClick={(e) => {
                                        let arr = [...chargeImages];
                                        arr.splice(i, 1)
                                        setChargeImages(arr);
                                    }} type="button" className="btn btn-link text-white position-absolute top-0 end-0 px-3 py-0" ><i className="fa fa-remove" /></button>

                                </div>

                            ))}

                        </div>
                    }

                                
                            </div>
                            
                            
                          
                            
                        </div>
                    }


                    <div className="modal-footer">
                        <button type='button' className="btn btn-lg btn-success" onClick={handleSubmit} disabled={isLoader}>Submit
                            {isLoader ? <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''}
                        </button>
                        <button type='button' className="btn btn-lg btn-warning" onClick={closeModal}>Close</button>
                    </div>

                </div>
            </Modal>
            <Modal animation={false}
                show={statusCam}
                onHide={(e) => setStatusCam(false)}
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-inner-content text-center pt-4">
                    <Webcam
                        audio={false}
                        height={200}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={300}
                        videoConstraints={videoConstraints}

                    />

                    <div className="modal-footer text-center  d-inline ">
                        <button type='button' onClick={capture} className="btn btn-lg btn-success mt-4" >Capture Photo</button>
                        <button type='button' className="btn btn-lg btn-danger mt-4" onClick={(e) => setStatusCam(false)}>Close</button>
                    </div>
                </div>
            </Modal>



  <Modal animation={false}
                show={chargeCam}
                onHide={(e) => setChargeCam(false)}
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-inner-content text-center pt-4">
                    <div className="row mt-3" >
                        <div className="col-12 " >

                            <label className="fw-bold">Total: {extraLoad.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)}</label>

                        </div>
                    </div>
 <div className="row mt-3" >
                        <div className="col-sm-12 " >

                    <Webcam
                        audio={false}
                        height={200}
                        ref={webcamChargeRef}
                        screenshotFormat="image/jpeg"
                        width={300}
                        videoConstraints={videoConstraints}

                    />
                    
                     </div>
                    </div>
                    
                    
                     {chargeImages && chargeImages.length > 0 &&
                        <div className="row mt-3" >

                            {chargeImages.map((el, i) => (
                                <div className="col-sm-6 col-md-4 col-lg-3 item position-relative" >

                                    <img className="img-fluid mb-3" src={el} />
                                    <button onClick={(e) => {
                                        let arr = [...chargeImages];
                                        arr.splice(i, 1)
                                        setChargeImages(arr);
                                    }} type="button" className="btn btn-link text-white position-absolute top-0 end-0 px-3 py-0" ><i className="fa fa-remove" /></button>

                                </div>

                            ))}

                        </div>
                    }
                   

                    <div className="modal-footer text-center  d-inline ">
                        <button type='button' onClick={captureCharge} className="btn btn-lg btn-info mt-4" >Capture Photo</button>
                        <button type='button' className="btn btn-lg btn-danger mt-4" onClick={(e) => { setChargeCam(false);}}>Close</button>
                    </div>
                </div>
            </Modal>




        </>
    );
};
export default Orders;
