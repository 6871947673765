import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { HTTPURL } from "../../constants/Matcher";
import html2pdf from 'html2pdf.js';
import './../../styles/OrderDetails.css';
import { async } from "q";
import { toast } from "react-toastify";



const OrderDetails = () => {
    const navigate = useNavigate();
    const params = useParams();
    const stutuses = [{ ids: [], title: "New" }, { ids: [0], title: "Picked" }, { ids: [0, 8], title: "Washed" }, { ids: [0, 1, 8], title: "Dried" }, { ids: [0, 1, 2, 8], title: "Folded" }, { ids: [0, 1, 2, 3, 8], title: "Packed" }, { ids: [0, 1, 2, 3, 4, 8], title: "Done" }]

    const order_statuses = ["New", "Washed", "Dried", "Folded", "Packed", "-", "Complete", "-", "Picked"];
    const [orderDetails, setOrderDetails] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(0);
    const [weight, setWeight] = useState([0]);
    const [isLoader, setIsLoader] = useState(false);
    const [estimatedLoads, setEstimatedLoads] = useState(0);
    const [price, setPrice] = useState(0);
    const [minOrder, setMinOrder] = useState(0);
    const [totalLoads, setTotalLoads] = useState(0);
    const [bin, setBin] = useState('');
    const [paidAmount, setPaidAmount] = useState(0);
     const [orderTimings, setOrderTimings] = useState(null)
    const [orderImages, setOrderImages] = useState(null)


    useEffect(() => {
        getOrderDetails();
         getOrderTimings();
        getOrderImages();
    }, []);
    
    
    
    const getOrderTimings = async () => {
        let booking_id = params?.order_id
        axios.post(HTTPURL + `/api/get-driver-order-timings`, {
            booking_id: booking_id
        }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            let data = response?.data;
            if (data?.status && data?.data.length > 0) {
                setOrderTimings(data?.data[0])
            }
        }).catch((error) => {
            console.log(error);
        })
    }


    const getOrderImages = async () => {
        let booking_id = params?.order_id
        axios.post(HTTPURL + `/api/get-driver-order-images`, {
            booking_id: booking_id
        }, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            let data = response?.data;
            if (data?.status && data?.data.length > 0) {
                setOrderImages(data?.data[0])
            }
        }).catch((error) => {
            console.log(error);
        })
    }



    const getOrderDetails = () => {
        axios.get(HTTPURL + `/api/get-driver-order-detail/${params.order_id}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {

            if (response?.data?.status) {

                let data = response?.data?.data;

                setOrderDetails(data)
                setEstimatedLoads(data.estimated_loads);
                setPrice(data.price);
                setMinOrder(data.min_order);
                setTotalLoads(data.total_loads);
                setBin(data.bin);
                setPaidAmount(data.paid_amount);


            }
            else {
                toast.error(response?.data?.message)
                navigate("/driver-dashboard");
            }
        }).catch((error) => {
            toast.error(error)

        })
    }



    const detailsPage = (id) => {
        navigate("/customer-details/" + id)
    }



    const addNewWeight = (e) => {
        var tmp = Object.values({ ...weight });
        if (tmp.length < 20) {
            tmp.push(0);
            setWeight(tmp);


        } else {
            toast.error("Max 20 Wt. scale reading allowed.")
        }

    }


    const updateStatus = (status) => {


        if (status == 6 && orderDetails?.paid_amount <= 0) {
            toast.error("Laundry is not charged yet and cannot be marked as completed until paid.")
        }
        else {
            const form = new FormData();
            form.append('booking_id', orderDetails?.id);
            form.append('status', status);

            axios.post(HTTPURL + "/api/update-driver-booking-status/", form, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                }
            }).then((response) => {
                if (response?.data?.status) {
                    toast.success(response?.data?.message)
                    getOrderDetails();


                } else {
                    toast.error(response?.data?.message)
                }
            })
        }

    }



    const handleCompletedStatus = async () => {

        setIsLoader(true)

        var consent = true;
        const form = new FormData();
        form.append('booking_id', orderDetails?.id);
        form.append('status', 6);


        if (orderDetails?.unit == 2 && orderDetails.paid_amount <= 0) {

            const tmp_weight = weight.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0)
            form.append('weight', tmp_weight);



            let total_amount = orderDetails?.min_order * orderDetails?.price;
            if (tmp_weight >= orderDetails?.min_order) {
                total_amount = tmp_weight * orderDetails?.price;
            }

            if (orderDetails.credit < total_amount) {
                const balance = total_amount - orderDetails.credit;
                const str = "Customer credit ( USD " + orderDetails.credit + ") is low than his bill( USD" + total_amount + ") for " + tmp_weight + "lbs" + " An amount of USD" + balance + " will be deducted from customer credit card. Are you sure, you want to proceed.";
                if (!window.confirm(str)) {
                    consent = false;

                    setIsLoader(false);

                }

            }
        }

        if (consent) {

            axios.post(HTTPURL + "/api/update-booking-status/", form, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                }
            }).then((response) => {
                if (response?.data?.status) {
                    toast.success(response?.data?.message)
                    getOrderDetails();
                    setIsLoader(false);
                } else {
                    toast.error(response?.data?.message)
                }
            })


        }


    }






    const handleUpdateOrder = async () => {



        if (orderDetails.paid_amount > 0 && (paidAmount <= 0 || totalLoads <= 0)) {

            toast.error("This Order is already charged, Please enter correct charged amount & Actual Weight/Bags");

        }
        else if (orderDetails.paid_amount <= 0 && (paidAmount > 0)) {

            toast.error("This Order is not yet charged, Charge amount must be zero. Please charge first.");

        }
        else if (price <= 0 || estimatedLoads <= 0 || minOrder <= 0) {

            toast.error("Price, Min Weight and Estimated Weigth are required");

        }
        else {
            setIsLoader(true)
            const form = new FormData();
            form.append('booking_id', orderDetails?.id);
            form.append('price', price);
            form.append('min_order', minOrder);
            form.append('estimated_loads', estimatedLoads);
            form.append('total_loads', totalLoads);
            form.append('bin', bin);
            form.append('paid_amount', paidAmount);

            axios.post(HTTPURL + "/api/update-order/", form, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                }
            }).then((response) => {
                if (response?.data?.status) {
                    toast.success(response?.data?.message);
                    getOrderDetails();
                    setIsLoader(false);
                } else {
                    toast.error(response?.data?.message)
                }
            })


        }


    }






    return (
        <div className="container-fluid px-0" id="order_detail_cont">
            <div className="right-nav py-3 px-2">
                <h3>Order Details #{orderDetails?.id}</h3>


                <div class="row mt-3">

                    <div class="col-lg-6 mb-3">
                        <div className="card  rounded-4">
                            <h5 className="card-header">Order Details</h5>
                            <div className="card-body form-group" >
                                <div className="row">
                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Order ID:</label>{orderDetails?.id}</div>
                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Category:</label>{orderDetails?.category_title}</div>
                                </div>
                                <div className="row">
                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Pickup Address:</label>{orderDetails?.pickup_address}</div>
                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Delivery Address:</label>{orderDetails?.delivery_address}</div>
                                </div>
                                <div className="row">
                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Order Status:</label>{order_statuses[parseInt(orderDetails?.order_status)]}</div>
                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Bin #:</label>{orderDetails?.bin}</div>
                                </div>

                                <div className="row">
                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Dreiver Instructions:</label>{orderDetails?.driver_instructions}</div>
                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Customer Name:</label>{orderDetails?.name.replace(/ .*/, '')}</div>
                                </div>

                            </div>




                        </div>
                    </div>


                    <div class="col-lg-6 mb-3">
                        <div className="card  rounded-4 ">
                            <h5 className="card-header">Update Order Status</h5>

                            <div className='row'>

                                <div className="col-lg-12 mt-4 text-center">

                                    <div className=' mt-2'>


                                        {orderDetails?.order_status == 0 &&
                                            <button onClick={(e) => updateStatus(8)} class="btn btn-lg btn-info me-1 mb-1" >Order Pickup</button>
                                        }
                                        {[0, 8, 1, 2, 3, 4].includes(orderDetails?.order_status) &&
                                            <button onClick={(e) => updateStatus(6)} class="btn btn-lg btn-info me-1 mb-1" >Completed</button>
                                        }


                                    </div>



                                </div>

                            </div>


                            <div className="track m-1">

                                <ul className="track_item " >
                                    {orderDetails && stutuses.map((stutus) => (
                                        <li className={`${(stutus.ids.includes(orderDetails?.order_status)) ? "" : "active"}`} >
                                            <div className={`check_icon ${(stutus.ids.includes(orderDetails?.order_status)) ? "uncheck" : ""}`} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                                                    <path d="M1 3.85714L3.5 6.35714L8.5 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="pick_title pt-3 pb-0 m-0">{stutus.title}</div>
                                        </li>
                                    ))}
                                </ul>


                            </div>
                        </div>
                    </div>

                </div>



  <div className="row">
                    <div className="col-lg-12 mb-3">

                        <div className="card  rounded-4">
                            <div className="card-header pt-3 pb-4 fw-bold">Order Images</div>
                            <div className="card-body">

                                {orderImages && orderImages.pickup_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Pickup Images</h5>

                                            {orderImages.pickup_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}


                                {orderImages && orderImages.wash_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Wash Images</h5>

                                            {orderImages.wash_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}


                                {orderImages && orderImages.dry_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Dry Images</h5>

                                            {orderImages.dry_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}

                                {orderImages && orderImages.fold_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Fold Images</h5>

                                            {orderImages.fold_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}

                                {orderImages && orderImages.pack_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5 className="my-2">Pack Images</h5>

                                            {orderImages.pack_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}

                                {orderImages && orderImages.drop_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Delivery Images</h5>

                                            {orderImages.drop_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}

                                {orderImages && orderImages.charge_images && (
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h5>Charge Images</h5>

                                            {orderImages.charge_images.split(",").map((img) =>

                                                <div className="p-1 float-start"> <img src={HTTPURL + "/uploads/orders/" + orderDetails?.id + "/" + img} /></div>

                                            )

                                            }
                                        </div>
                                    </div>
                                )}



                            </div>
                        </div>
                    </div></div>

            </div>
        </div>
    )
}

export default OrderDetails