import { HTTPURL } from "../constants/Matcher";
import axios from "axios";


  //get all users
 
export async function GetAllCustomers() {
    try {
        
        const response=await axios.get(HTTPURL+`/api/get-all-customers`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

      
       // const responseData = await response.json();
        return response.data;
    } catch (error) {
        return error.message;
    }
}



 //get all users
 
export async function GetAllDrivers() {
    try {
        
        const response=await axios.get(HTTPURL+`/api/get-all-drivers`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

      
       // const responseData = await response.json();
        return response.data;
    } catch (error) {
        return error.message;
    }
}