import axios from "axios";
import React, { useEffect, useState } from "react";
import { HTTPURL, HTTPURLFRONT } from "../../../constants/Matcher";

import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { updateUserStatus } from './../../../apis/Items'
import { GetAllDrivers } from "./../../../apis/Users";
import { GetCounties } from "./../../../apis/Settings";

import DriversDataTable from "./DriversDataTable";
import { Modal, ModalHeader } from "react-bootstrap";
import { useRef } from "react";
import { toast } from "react-toastify";
import dateFormat from 'date-and-time';
import Multiselect from 'multiselect-react-dropdown';

export const columns = [
    {
        name: "ID",
        selector: row => row.id_link,
        sortable: true,
        width: "80px",
    },
     {
        name: "PIC",
        selector: row => row.picture,
        sortable: true,
       
    },
    {
        name: "Name",
        selector: row => row.name,
        sortable: true,
    },
    {
        name: "Email",
        selector: row => row.email,
        sortable: true,
    },
    {
        name: "Mobile",
        selector: row => row.mobile,
        sortable: true,
    },
    {
        name: "Address",
        selector: row => row.pickup_address,
        sortable: true,
    },
    {
        name: "Counties",
        selector: row => row.operational_counties,
        sortable: true,
    },
    {
        name: "STATUS",
        selector: row => row.status,
        width: "90px",
    },
    {
        name: "Actions",
        selector: row => row.action,
        sortable: true,
         width: "120px",
       
    },

];
const Drivers = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isLoader, setIsLoader] = useState(false);      
    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);   
    const [drivers, setDrivers] = useState([]);
    const [newDriver, setNewDriver] = useState([]);
    const [editDriver, setEditDriver] = useState([]);
    const [isBtn, setIsBtn] = useState(false);
    const [counties, setCounties] = useState([]);
    const [countiesList, setCountiesList] = useState([]);
     const [id, setId] = useState();


    async function get_data() {
        try {

            const result = await GetAllDrivers();
            setDrivers(result?.data);
          
            const arrayData = result?.data?.map((elem, index) => {
                const {id, status, credit, name, email, mobile, pickup_address,zip_code,profile_image,operational_counties} = elem;

               

                return {
                    id: id,
                    id_link: <Link title="View Driver Details" to={'/driver-details/' + id} >{id}</Link>,
                    picture: <img className="width-100"  src={HTTPURL+"/uploads/drivers/"+profile_image} />,
                    name: name,
                    email: email,
                    mobile: mobile,
                    pickup_address: pickup_address+", "+zip_code,
                    operational_counties:operational_counties,
                    status: <div className="toggling form-check form-switch" title="Click here to activate or deactivate" onClick={() => toggle(id, status)}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={status ? true : false} />
                    </div>,
                    action: <>
                        <button className="btn " title="Edit Driver" onClick={() => {  openEditDriverModal(elem); }} ><i className="fa-solid fa-pen-to-square"></i></button>
                        <button className="btn " title="Delete Driver" onClick={() => {   setId(id); setDeleteModal(true);}} ><i className="fa-solid fa-trash"></i></button>
                    </>,
                };
            });
   

            console.log(arrayData);
            setData(arrayData, setShowResults(true));
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        
        if(countiesList?.length >0)
        {
           get_data();
        }
        
        
    }, [countiesList]);
    
    useEffect(() => {
    
        getCounties();
        
    }, []);
    
    async function getCounties() {
        const result = await GetCounties();
        if (result?.data) {
            setCounties(result.data);
            let tmp = [];
            result.data.forEach(element => {
                        
                  tmp[element.id]= element.title;
            });
            
            setCountiesList(tmp);
        }
    }

    const toggle = async (id, status) => {
        if (id) {
            let result = await updateUserStatus(id, status)
            if (result.status) {
                toast.success(result.message)
                get_data();
            } else {
                toast.error(result.message)
            }
        }

    }

    const openAddDriverModal = async () => {
        
        setNewDriver({
           ...newDriver,
            profile_image: { preview: '', data: '' },
            name: '',
            email: '',
            mobile: '',
            pickup_address:'',
            zip_code:'',
            licence_front_image:{ preview: '', data: '' },
            licence_back_image:{ preview: '', data: '' },
            operational_counties:[],
            status: true
            
        });
        setAddModal(true);

    }


     
     
    const openEditDriverModal = async (e) => {
        
          const operational_counties_ids = e.operational_counties_ids?.split(",").map( Number );;
                   // console.log(operational_counties_ids);

        let operational_counties = counties.filter(function (el) {
           // console.log(el.id);
            return operational_counties_ids?.includes( el.id )
                  }
         );

         console.log(operational_counties);
     
        setEditDriver({
           ...editDriver,
            id: e.id,
            profile_image: { preview: e.profile_image?HTTPURL+"/uploads/drivers/"+e.profile_image:'', data: '' },
            name: e.name,
            email: e.email,
            mobile: e.mobile,
            pickup_address:e.pickup_address,
            zip_code:e.zip_code,
            licence_front_image:{ preview: e.licence_front_image?HTTPURL+"/uploads/drivers/"+e.licence_front_image:'', data: '' },
            licence_back_image:{ preview: e.licence_back_image?HTTPURL+"/uploads/drivers/"+e.licence_back_image:'', data: '' },
            operational_counties:operational_counties,
            status: e.status,
            profile_image_old:e.profile_image,
            licence_front_image_old: e.licence_front_image,
            licence_back_image_old:e.licence_back_image,
            
        });
        setEditModal(true);

    }

    const saveNewDriver = async () => {
        

         try{
        let content_type = "multipart/form-data";
        console.log(newDriver);
        const form = new FormData();
            form.append('profile_image', newDriver?.profile_image.data);
            form.append('name', newDriver?.name);
            form.append('email', newDriver?.email);
            form.append('mobile', newDriver?.mobile);
            form.append('zip_code', newDriver?.zip_code);
            form.append('status', newDriver?.status);
            form.append('pickup_address', newDriver?.pickup_address);
            form.append('licence_front_image', newDriver?.licence_front_image.data);
            form.append('licence_back_image', newDriver?.licence_back_image.data);
            const operational_counties = newDriver?.operational_counties.map(a => a.id);

            form.append('operational_counties', operational_counties);


        const response=await axios.post(HTTPURL+`/api/add-driver`,form,{
            headers: {
                "Content-Type": content_type,
                'Authorization': localStorage.getItem('token')
            }
        });
        
            const result  = response?.data;
            if (result.status) {
                toast.success("Driver Added Successfully")
                get_data();
                        setAddModal(false);

            } else {
                toast.error(result.message)
            }
            
     
        
    }catch(error){

    }
        

    }




    const saveEditDriver = async () => {
        

         try{
        let content_type = "multipart/form-data";
        console.log(editDriver);
        const form = new FormData();
            form.append('id', editDriver?.id);
            form.append('profile_image', editDriver?.profile_image.data);
            form.append('name', editDriver?.name);
            form.append('email', editDriver?.email);
            form.append('mobile', editDriver?.mobile);
            form.append('zip_code', editDriver?.zip_code);
            form.append('status', editDriver?.status);
            form.append('pickup_address', editDriver?.pickup_address);
            form.append('licence_front_image', editDriver?.licence_front_image.data);
            form.append('licence_back_image', editDriver?.licence_back_image.data);
            
            
            form.append('profile_image_old', editDriver?.profile_image_old);
            form.append('licence_front_image_old', editDriver?.licence_front_image_old);
            form.append('licence_back_image_old', editDriver?.licence_back_image_old);
            
            
            
            const operational_counties = editDriver?.operational_counties.map(a => a.id);

            form.append('operational_counties', operational_counties);


        const response=await axios.post(HTTPURL+`/api/update-driver`,form,{
            headers: {
                "Content-Type": content_type,
                'Authorization': localStorage.getItem('token')
            }
        });
        
            const result  = response?.data;
            if (result.status) {
                toast.success("Driver Updated Successfully")
                get_data();
                        setEditModal(false);

            } else {
                toast.error(result.message)
            }
            
     
        
    }catch(error){

    }
        

    }



    const deleteDriver = async () => {
        if (id) {
            setIsBtn(true)
            const response = await axios.post(HTTPURL + `/api/delete-driver`, {id: id}, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': localStorage.getItem('token')
                }
            });

            const result = response?.data;
            setIsBtn(false)
            if (result?.status) {
                toast.success(result?.message);
                get_data();
                 setDeleteModal(false);
            } else {
                toast.error(result?.message)
            }
        }


    }





    return (
        <>
            <div className="right-nav py-4 px-2" id="dash-event">
                <h3>Drivers List</h3>
                {showResults ? (
                    <>
                        <DriversDataTable columns={columns} data={data} onNewDriver={openAddDriverModal} />

                    </>
                ) : (
                    <DataLoader />
                )}





                <Modal animation={false}
                    show={addModal}
                    onHide={() => { setAddModal(false); }}
                    keyboard={false}
                    backdrop="static"
                    id="add-driver-modal"
                    size="lg"
                >
                    <Modal.Body>
                        <div className="modal-inner-content">
                            <h3>ADD Driver</h3>
                            <form className="form-horizontal"   >



                                 <div className="row">
                                    <div className='col-12 form-group mt-3'>
                                        <label>Profile Photo:<span className="text-danger">*</span></label>
                                         {newDriver?.profile_image?.preview && <img className="mb-1 me-1"  src={newDriver.profile_image.preview} width='100' height='100' />}
                                          
                                         <input className="form-control"  type='file' name='file' onChange={(e) => {
                                             
                                                         const img = {
                                                                          preview: URL.createObjectURL(e.target.files[0]),
                                                                          data: e.target.files[0],
                                                                        }
                                                         setNewDriver({ ...newDriver, profile_image: img })
                                                     
                                                     }
                                                     
                                                     } />
                                     
                                    </div>

                                </div>
                                <div className="row">
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Name:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={newDriver?.name} onChange={(e) => setNewDriver({ ...newDriver, name: e.target.value })} />
                                    </div>

                                
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Email:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={newDriver?.email} onChange={(e) => setNewDriver({ ...newDriver, email: e.target.value })} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Phone:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={newDriver?.mobile} onChange={(e) => setNewDriver({ ...newDriver, mobile: e.target.value })} />
                                    </div>

                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Address:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={newDriver?.pickup_address} onChange={(e) => setNewDriver({ ...newDriver, pickup_address: e.target.value })} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Zipcode:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={newDriver?.zip_code} onChange={(e) => setNewDriver({ ...newDriver, zip_code: e.target.value })} />
                                    </div>

                               
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Operational Counties:<span className="text-danger">*</span></label>
                                        <Multiselect
                                            displayValue="title"
                                            onSelect={function noRefCheck(e) {
                                                console.log("data", e);
                                                setNewDriver({ ...newDriver, operational_counties: e })
                                            }}
                                            options={counties}
                                            placeholder="Select multiple counties/areas"
                                            selectedValues={newDriver?.operational_counties}
                                        />

                                    </div>
                                </div>


                                 <div className="row">
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Licence Front Image:<span className="text-danger">*</span></label>
                                         {newDriver?.licence_front_image?.preview && <img className="mb-1 me-1"  src={newDriver.licence_front_image?.preview} width='100' height='100' />}
                                       
                                         <input  className="form-control"  type='file' name='file' onChange={(e) => {
                                             
                                                         const img = {
                                                                          preview: URL.createObjectURL(e.target.files[0]),
                                                                          data: e.target.files[0],
                                                                        }
                                                         setNewDriver({ ...newDriver, licence_front_image: img })
                                                     
                                                     }
                                                     
                                                     } />
                                     
                                    </div>

                                
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Licence Back Image:<span className="text-danger">*</span></label>
                                         {newDriver?.licence_back_image?.preview && <img className="mb-1 me-1"  src={newDriver.licence_back_image?.preview} width='100' height='100' />}
                                         
                                         <input  className="form-control"  type='file' name='file' onChange={(e) => {
                                             
                                                         const img = {
                                                                          preview: URL.createObjectURL(e.target.files[0]),
                                                                          data: e.target.files[0],
                                                                        }
                                                         setNewDriver({ ...newDriver, licence_back_image: img })
                                                     
                                                     }
                                                     
                                                     } />
                                     
                                    </div>

                                </div>
                                
                                
                                



                                <div className="row">
                                    <div className='form-group mt-3 d-flex justify-content-start'>
                                        <div className="me-2 my-auto fw-bold">Status: </div>
                                        <div className="toggling form-check form-switch" onClick={(e) => setNewDriver({ ...newDriver, status: !newDriver.status })}  >
                                            <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={newDriver.status ? true : false} />
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type='button' className="btn btn-lg btn-success" onClick={saveNewDriver} disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : ''}
                                    </button>
                                    <button type='button' className="btn btn-lg btn-danger" onClick={() => { setAddModal(false); }}>Close</button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>




                <Modal animation={false}
                    show={editModal}
                    onHide={() => { setEditModal(false); }}
                    keyboard={false}
                    backdrop="static"
                    id="edit-driver-modal"
                    size="lg"
                >
                    <Modal.Body>
                        <div className="modal-inner-content">
                            <h3>Edit Driver</h3>
                            <form className="form-horizontal"   >



                                 <div className="row">
                                    <div className='col-12 form-group mt-3'>
                                        <label>Profile Photo:<span className="text-danger">*</span></label>
                                         {editDriver?.profile_image?.preview && <img className="mb-1 me-1" src={editDriver.profile_image.preview} width='100' height='100' />}
                                          
                                         <input  className="form-control"  type='file' name='file' onChange={(e) => {
                                             
                                                         const img = {
                                                                          preview: URL.createObjectURL(e.target.files[0]),
                                                                          data: e.target.files[0],
                                                                        }
                                                         setEditDriver({ ...editDriver, profile_image: img })
                                                     
                                                     }
                                                     
                                                     } />
                                     
                                    </div>

                                </div>
                                <div className="row">
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Name:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={editDriver?.name} onChange={(e) => setEditDriver({ ...editDriver, name: e.target.value })} />
                                    </div>

                                
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Email:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={editDriver?.email} onChange={(e) => setEditDriver({ ...editDriver, email: e.target.value })} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Phone:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={editDriver?.mobile} onChange={(e) => setEditDriver({ ...editDriver, mobile: e.target.value })} />
                                    </div>

                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Address:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={editDriver?.pickup_address} onChange={(e) => setEditDriver({ ...editDriver, pickup_address: e.target.value })} />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Zipcode:<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={editDriver?.zip_code} onChange={(e) => setEditDriver({ ...editDriver, zip_code: e.target.value })} />
                                    </div>

                               
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Operational Counties:<span className="text-danger">*</span></label>
                                        <Multiselect
                                            displayValue="title"
                                            onSelect={function noRefCheck(e) {
                                                console.log("data", e);
                                                setEditDriver({ ...editDriver, operational_counties: e })
                                            }}
                                            options={counties}
                                            placeholder="Select multiple counties/areas"
                                            selectedValues={editDriver?.operational_counties}
                                        />

                                    </div>
                                </div>


                                 <div className="row">
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Licence Front Image:<span className="text-danger">*</span></label>
                                         {editDriver?.licence_front_image?.preview && <img className="mb-1 me-1" src={editDriver.licence_front_image?.preview} width='100' height='100' />}
                                       
                                         <input  className="form-control"  type='file' name='file' onChange={(e) => {
                                             
                                                         const img = {
                                                                          preview: URL.createObjectURL(e.target.files[0]),
                                                                          data: e.target.files[0],
                                                                        }
                                                         setEditDriver({ ...editDriver, licence_front_image: img })
                                                     
                                                     }
                                                     
                                                     } />
                                     
                                    </div>

                                
                                    <div className='col-md-6 form-group mt-3'>
                                        <label> Licence Back Image:<span className="text-danger">*</span></label>
                                        {editDriver?.licence_back_image?.preview && <img className="mb-1 me-1" src={editDriver.licence_back_image?.preview} width='100' height='100' />}
                                         
                                         <input  className="form-control"  type='file' name='file' onChange={(e) => {
                                             
                                                         const img = {
                                                                          preview: URL.createObjectURL(e.target.files[0]),
                                                                          data: e.target.files[0],
                                                                        }
                                                         setEditDriver({ ...editDriver, licence_back_image: img })
                                                     
                                                     }
                                                     
                                                     } />
                                     
                                    </div>

                                </div>
                                
                                
                                



                                <div className="row">
                                    <div className='form-group mt-3 d-flex justify-content-start'>
                                        <div className="me-2 my-auto fw-bold">Status: </div>
                                        <div className="toggling form-check form-switch" onClick={(e) => setEditDriver({ ...editDriver, status: !editDriver.status })}  >
                                            <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={editDriver.status ? true : false} />
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type='button' className="btn btn-lg btn-success" onClick={saveEditDriver} disabled={isBtn}>Update{isBtn ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : ''}
                                    </button>
                                    <button type='button' className="btn btn-lg btn-danger" onClick={() => { setEditModal(false); }}>Close</button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>



                <Modal animation={false}
                    show={deleteModal}
                    onHide={() => { setDeleteModal(false); }}
                    backdrop="static"
                    keyboard={false}
            
                    >
                    <div className="modal-inner-content">
                        <div className="modal-heading"><h3>Do you really want to delete this driver?</h3></div>
                        <div className="modal-footer">
                            <button type='button' onClick={deleteDriver} className="btn btn-lg btn-success" disabled={isBtn}>Yes
                                {isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                            </button>
                            <button type='button' className="btn btn-lg btn-success" onClick={() => { setDeleteModal(false); }}>No</button>
                        </div>
                    </div>
                </Modal>

            </div>


















        </>
    );
};
export default Drivers;
