import React from 'react';
import { Navigate, Outlet, useLocation} from 'react-router-dom';

function checkToken(){
  var now = new Date().getTime();
  var setupTime = JSON.parse(localStorage.getItem('setupTime'));
  if (setupTime != null) {
    console.log("check admin is logged in or not...")
    if(now-setupTime > 60*60*3000) {
      localStorage.clear();
      localStorage.removeItem("token");
    }
  }  
}

function RequireAuthAdmin() {
  checkToken();
  // let userData = JSON.parse(localStorage.getItem("userData"));
  let token = localStorage.getItem("token");
  let role = localStorage.getItem("role");

  let location = useLocation();
  if (token && role == 6) 
  {
    return <Outlet />;
  }
  else if (token && role == 2)  {
       return <Navigate replace to='/driver-dashboard'  state={{ from: location }}/>
  }
  else{
        localStorage.clear();
  localStorage.removeItem("token");
  localStorage.removeItem("role");
    localStorage.removeItem("email");
      return <Navigate replace to='/login'  state={{ from: location }}/>
  }
}

function AdminLogout() {
  localStorage.clear();
  localStorage.removeItem("token");
  localStorage.removeItem("role");
    localStorage.removeItem("email");
 localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/login' />
}




function RequireAuthDriver() {
  checkToken();
  let token = localStorage.getItem("token");
  let role = localStorage.getItem("role");

  let location = useLocation();
  if (token && role == 2) 
  {
    return <Outlet />;
  }
  else if (token && role == 6)  {
       return <Navigate replace to='/dashboard'  state={{ from: location }}/>
  }
  else{
        localStorage.clear();
  localStorage.removeItem("token");
  localStorage.removeItem("role");
    localStorage.removeItem("email");
      return <Navigate replace to='/login'  state={{ from: location }}/>
  }
}

function DriverLogout() {
   localStorage.clear();
  localStorage.removeItem("token");
  localStorage.removeItem("role");
    localStorage.removeItem("email");
 localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/login' />
}

export{RequireAuthAdmin , AdminLogout,RequireAuthDriver, DriverLogout}

