import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";
import { HTTPURL, APPID, APPLOCATION, SQUARESCRIPT } from "../../../constants/Matcher";
import { MyCalendar } from '../../common/MyCalendar';
import { Radio } from '../../common/Radio';

import Select from 'react-select';
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "../../../styles/PlaceOrder.css";
import { v4 as uuidv4 } from 'uuid';


const AddCredit = () => {

    const navigate = useNavigate();
    const params = useParams();
    const [amount, setAmount] = useState(100);
    const [credit, setCredit] = useState(0);
    const [user, setUser] = useState([]);

    const [cards, setCards] = useState();
    const [card, setCard] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isBtn, setIsBtn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isCardLoaded, setIsCardLoaded] = useState(false);
    const [idempotencykey, setIdempotencykey] = useState('');


    useEffect(() => {

        getUser();

    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });

        setIdempotencykey(uuidv4());
        if (user?.id > 0) {
            getCredit();
            getCards();
            const script = document.createElement("script");
            script.src = SQUARESCRIPT;
            script.async = true;
            script.onload = () => setLoad();
            document.body.appendChild(script);
        }
        //  getPrivacyAndTerms();



    }, [user])


    const getUser = () => {
        axios.get(HTTPURL + "/api/get-User/" + params.id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': localStorage.getItem('token')
            }
        })
            .then(function (response) {
                // console.log(response?.data);
                if (response?.data?.status) {

                    setUser(response?.data?.data)

                } else {
                    toast.error(response?.data?.message)
                }

            })

    }


    const getCards = () => {
        axios.get(HTTPURL + "/api/get-cards/" + params.id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': localStorage.getItem('token')
            }
        })
            .then(function (response) {
                if (response?.data?.status) {
                    setIsCardLoaded(true);
                    setCards(response?.data?.CardList)
                    if (response?.data?.CardList.length > 0) {
                        // console.log(response?.data?.CardList[0].cardId);
                        setCard(response?.data?.CardList[0].cardId);
                    } else {
                        //  console.log(response?.data?.CardList);
                        setCard(0);
                    }


                } else {
                    toast.error(response?.data?.message)
                }

            })

    }




    const getCredit = () => {
        axios.get(HTTPURL + "/api/get-credit/" + params.id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': localStorage.getItem('token')
            }
        })
            .then(function (response) {

                if (response?.data?.status) {
                    setCredit(response?.data?.credit)

                } else {
                    toast.error(response?.data?.message)
                }

            })

    }


    async function disableCard(card_id) {
        // alert(card_id);

        try {

            const tokenVal = localStorage.getItem('token');

            axios.post(HTTPURL + "/api/remove-card/" + params.id, { card_id: card_id, user_id: params.id },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${tokenVal}`
                    }
                })
                .then(function (response) {

                    if (response?.data?.status) {

                        toast.success("('Card removed successfully.");
                        getCards();

                    } else {
                        console.log(response?.data);
                        toast.error(response?.data?.message);

                    }
                })
                .catch(function (error) {

                    toast.error(error.message);
                });


            // const paymentResults = await createPayment(token);

        } catch (e) {
            toast.error("Card cannot be deleted, please try later");
            console.error(e.message);
        }

    }





    const setLoad = async () => {



        const initializeCard = async (payments) => {
            const card = await payments.card();

            await card.attach('#card-container');


            return card;
        };

        const payments = window.Square.payments(APPID, APPLOCATION);
        let newCard;
        try {
            newCard = await initializeCard(payments);
            setIsScriptLoaded(true);

        } catch (e) {
            console.error('Initializing Card failed.', e);
            return;
        }


        async function tokenize(paymentMethod) {


            const tokenResult = await paymentMethod.tokenize();
            console.log("rest");
            console.log(tokenResult);
            if (tokenResult.status == 'OK') {
                return tokenResult.token;
            } else {
                let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
                if (tokenResult.errors) {
                    errorMessage += ` and errors: ${JSON.stringify(
                        tokenResult.errors
                    )}`;
                }
                throw new Error(errorMessage);
            }
        }



        document.getElementById('card-button').addEventListener('click', async function (event) {

            setIsBtn(true);
            const amount = event.target.getAttribute("amount");
            const card = event.target.getAttribute("card");
            const idempotencykey = event.target.getAttribute("idempotencykey");


            let isChecked = 0;
            if (document.getElementById("flexCheckDefault").checked) {
                isChecked = 1;
            }


            var card1 = document.querySelector('input[name="customerCard"]:checked').value;
            console.log(card1);
            console.log(card);

            try {

                //  setIsBtn(true);
                let token;
                if (card == 0) {

                    token = await tokenize(newCard);
                    console.log(token);

                } else {
                    isChecked = 0;
                    token = card;
                }


                let data = {
                    locationId: APPLOCATION,
                    idempotencyKey: idempotencykey,
                    sourceId: token,
                    amount: amount,
                    card_status: isChecked,
                    user_id: params.id,

                }
                //console.log(data);



                const tokenVal = localStorage.getItem('token');

                axios.post(HTTPURL + "/api/add-credit/" + params.id, data,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': `Bearer ${tokenVal}`
                        }
                    })
                    .then(function (response) {
                        setIsBtn(false)
                        if (response?.data?.status) {
                            console.log(response?.data);
                            toast.success("('Credit has been added successfully.");
                            getCredit();
                            getCards();
                            setIdempotencykey(uuidv4());

                            // navigate("/thanks-request-pickups")


                        } else {
                            console.log(response?.data);
                            toast.error(response?.data?.message);

                        }
                    })
                    .catch(function (error) {
                        setIsBtn(false)
                        toast.error(error.message);
                    });


                // const paymentResults = await createPayment(token);

            } catch (e) {
                setIsBtn(false)
                toast.error("Payment is not done, please try later");
                console.error(e.message);
            }


        });



    }


    return (

        <Fragment>
            {(!isScriptLoaded || !isCardLoaded) &&
                <div className="loader">

                    <div className="loader-text text-center"><h2>Loading Info.....</h2></div>
                </div>
            }
            <div className={isScriptLoaded && isCardLoaded ? "Uf-registration place-order" : "d-none"}>

                <div className="right-nav admin-applications-content " id="applications-content">
                    <form>
                        <h3 className="pb-3">Add Credit to {user?.name}</h3>
                        <div className="row  pb-2">
                            <div className="col-12 ">

                                <div className="row ">

                                    <div className="col-12 "><label className="min-label">Your Available Balance: </label> <span className="bold-text">USD {credit}</span></div>


                                </div>

                            </div>

                        </div>



                        <div className="row mb-4">

                            <div className="col-12 d-md-inline-flex align-items-center">
                                <label className="min-label">Enter amount to be added <sup>*</sup>:&nbsp;&nbsp;</label>
                                <input style={{ width: "100px" }} className="form-control mt-2" type="text" name="amount" value={amount} onChange={
                                    (e) => setAmount(e.target.value)} />  <label className="min-label ps-1">USD</label>
                            </div>

                        </div>
                        <div className="row mb-4 pb-2">
                            <div className="col-12 mt-3">

                                {cards && cards?.length > 0 && cards.map((c, index) => (
                                    <div className="shadow-lg p-3 mb-4 bg-body rounded card-token" key={c?.cardId}>


                                        <input
                                            className=""
                                            type="radio"
                                            name="customerCard"
                                            value={c?.cardId}
                                            onChange={(e) => setCard(e.target.value)}
                                            checked={card == c?.cardId ? true : false}
                                        />
                                        <label onClick={(e) => setCard(c?.cardId)} className="card-token-label" htmlFor={c?.cardId}>Add credit with {c?.brand} ending in {c?.last4}</label>

                                        <label className="card-token-trash float-end" onClick={(e) => {
                                            disableCard(c?.cardId);
                                        }} >
                                            <i className="fa fa-trash-can " ></i>
                                        </label>

                                    </div>

                                ))}

                                <div className="shadow-lg p-3 mb-4 bg-body rounded card-token" key={card?.cardId}>
                                    <input
                                        className=""
                                        type="radio"
                                        name="customerCard"
                                        value={0}
                                        onChange={(e) => setCard(0)}
                                        checked={card == 0 ? true : false}
                                    />
                                    <label className="card-token-label mb-4" onClick={(e) => setCard(0)} >Add new billing information to add credit</label>

                                    <div className={card != 0 ? 'd-none' : undefined} id="card-container"></div>
                                    <div className={card != 0 ? 'd-none' : "set-chkbox"}  >
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isChecked}
                                                onChange={(e) => setIsChecked(e.target.checked)}
                                            />
                                            <label className="card-token-label" htmlFor="flexCheckDefault"  >
                                                Save card details for future payment
                                            </label>
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>




                        <div className="row">


                            <div className="col-12">
                                <div className="combo-btn">
                                    <div className="sap-blue-btn">
                                        <button
                                            className="btn btn-lg btn-success"
                                            amount={amount}
                                            card={card}
                                            idempotencykey={idempotencykey}
                                            id="card-button"
                                            type="button"
                                            disabled={isBtn}>Add ${amount}
                                            {isBtn ? <div className="spinner-border" role="status"><span className="sr-only">Loading...</span> </div> : ''}
                                        </button>
                                    </div>
                                </div>


                                <div id="payment-status-container"></div>
                            </div>
                        </div>








                    </form>


                </div>

            </div>

        </Fragment>

    );
};
export default AddCredit
