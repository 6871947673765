import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";
import axios from "axios";

// get customer details
export async function get_user() {

    try {
        const response = await fetch(HTTPURL + '/api/customer-list');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}
//get Driver details
export async function get_driver() {
    try {
        const response = await fetch(HTTPURL + '/api/drivers-list');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}
//get order details
export async function get_order() {
    try {
        const response = await fetch(HTTPURL + '/api/order-list');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}

//add new folder/driver details
// export async function add_folder(data) {
//     try {
//         const response = await fetch(HTTPURL + `/api/register-employee`, {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify(data)
//         });
//         const responseData = await response.json();
//         if (responseData.status) {
//             toast.success(responseData?.message);
//         } else {
//             toast.error(responseData?.message);
//         }
//         return responseData;
//     } catch (error) {
//         console.log(error.message);
//         return error.message;
//     }
// }

export async function add_folder(data)
{
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        const response = await fetch(HTTPURL + '/api/order-managament-user-singup', requestOptions);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//edit folder/driver details
// export async function update_folder(data) {
//     try {
//         const response = await fetch(HTTPURL + `/api/update-employee`, {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify(data)
//         });
//         const responseData = await response.json();
//         if (responseData.status) {
//             toast.success(responseData?.message);
//         } else {
//             toast.error(responseData?.message);
//         }
//         return responseData;
//     } catch (error) {
//         console.log(error.message);
//         return error.message;
//     }
// }

export async function update_folder(data) {
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        const response = await fetch(HTTPURL + '/api/order-managament-user-update', requestOptions);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function folder_driver_status(data) {
    try {
        const response = await fetch(HTTPURL + `/api/update-user-status`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//delete folder/driver
export async function delete_folder(data) {
    console.log("id",data)
    try {
        const response = await fetch(HTTPURL + `/api/delete-employee`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//get folder details
export async function get_folder() {
    try {
        const response = await fetch(HTTPURL + '/api/folders-list');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}
// add item



// item edit
export async function item_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/item-edit?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// item update
export async function item_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/item-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
export async function update_user_status(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/item-update/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export  const get_group_list=async()=>{
    try {
        const response = await fetch(HTTPURL + `/api/get_group_list`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export const create_group=async(data)=>{
    try{
        const response=await axios.post(HTTPURL+"/api/create_group",data,{
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': localStorage.getItem('token')
            }
        })
        const responseData = await response.data;
        return responseData;
        
    }catch(error){

    }
}


export const update_group=async(data)=>{
    try{
        const response=await axios.post(HTTPURL+"/api/update_group",data,{
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': localStorage.getItem('token')
            }
        })
        return response;
        
    }catch(error){

    }
}

export const delete_group=async(id)=>{
    try{
        const response=await axios.post(HTTPURL+"/api/delete_group",{
            id:id,
            isDelete:1
        },{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response.data;
    }catch(error){

    }
}

// Add role for roles and permissions feature
export const addRole=async(role,id)=>{
    try{
        let data;
        if(id){
            data={
                role:role,
                role_id:id
            }
        }else{
            data={
                role:role,
            }
        }
        const response=await axios.post(HTTPURL+"/api/addRole",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    }
}

export  const getRole=async()=>{
    try {
        const response = await fetch(HTTPURL + `/api/getRole`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export const delRole=async(id)=>{
    try{
        const response=await axios.get(HTTPURL+`/api/delRole/${id}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response.data;
    }catch(error){

    }
}

export  const getPermissions=async()=>{
    try {
        const response = await fetch(HTTPURL + `/api/getPermissions`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export const getRoleAndPermissionById=async(id)=>{
    try{
        const response=await fetch(HTTPURL+`/api/getRoleAndPermissionById/${id}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        const responseData = await response.json();
        return responseData;
    }catch(error){

    }
}

export const addRoleAndPermission=async(id,permission_id,role_id,status)=>{
    try{
        let data={
                id:id,
                role_id:role_id,
                permission_id:permission_id,
                status:status
            }
        
        const response=await axios.post(HTTPURL+"/api/addRoleAndPermission",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    }
}

export const getUsers=async()=>{
    try{
        let response=await fetch(HTTPURL+"/api/get_userList",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        const responseData = await response.json();
        return responseData;    
    }catch(error){

    }
}

export const get_all_userList=async()=>{
    try{
        let response=await fetch(HTTPURL+"/api/get_all_userList",{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
        let responseData=await response.json();
        return responseData;
    }catch(error){

    }
}

export const assignRole=async(user_id,role_id)=>{
    try{
        let data={
            user_id:user_id,
            role_id:role_id
            }
        
        const response=await axios.post(HTTPURL+"/api/assignRole",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    }

}

export const updateAssignRole=async(user_id,role_id)=>{
    try{
        let data={
            user_id:user_id,
            role_id:role_id
            }
        
        const response=await axios.post(HTTPURL+"/api/updateAssignRole",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    }

}


export const toggling=async(user_id)=>{
    try{
        let data={
            user_id:user_id
            }
        
        const response=await axios.post(HTTPURL+"/api/updateLoginAccess",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    }

}

export const get_states=async(country_id)=>{
    try{
        let response=await fetch(HTTPURL+`/api/get_states/${country_id}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
        let responseData=await response.json();
        return responseData;
    }catch(error){

    }
}

export const get_county=async(state_id)=>{
    try{
        let response=await fetch(HTTPURL+`/api/get_cities/${state_id}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
        let responseData=await response.json();
        return responseData;
    }catch(error){

    }
}/**Get city names from county name***/
export const get_county_cities=async(county_name)=>{
    try{
        let response=await fetch(HTTPURL+`/api/get_county_cities/${county_name}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
        let responseData=await response.json();
        return responseData;
    }catch(error){

    }
}

export const update_password=async(oldPwd,newPwd)=>{
    try{
        let response = await axios.post(HTTPURL + "/api/update-admin-password", {
            oldpassword: oldPwd,
            newpassword: newPwd
        },{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
        
        return response.data;
    }catch(error){

    }
}

export const updateAdminEmail=async(email)=>{
    try{
        let response = await axios.post(HTTPURL + "/api/update-admin-email", {
            email: email
        },{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
        
        return response.data;
    }catch(error){
        
    }
}

// Get all the Counties
export  const getCounties=async()=>{
    try {
        const response = await fetch(HTTPURL + `/api/get_all_county_list`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        const responseData = await response.json();
        return responseData.data;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// Add county correspondence to city
export const addCounty=async(county,city_id,state_id)=>{
    try{
        let data={
            name:county,
            city_id:city_id,
            state_id:state_id
        };
        const response=await axios.post(HTTPURL+"/api/create_county",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    }
}


export const update_county_status=async(id,status)=>{
    try{
        let data={
            id:id,
            status:!status
            }
        
        const response=await axios.post(HTTPURL+"/api/update_county_status",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    }

}

export const update_county=async(id,county,city_id)=>{
    try{
        let data={
            name:county,
            city_id:city_id,
            id:id
        };
        const response=await axios.post(HTTPURL+"/api/update_county",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        console.log("response---",response);
        return response.data;
    }catch(error){

    }
}

export const delete_County=async(id)=>{
    let data={id:id,isDeleted:1}
    try{
        const response=await axios.post(HTTPURL+`/api/delete_County`,data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response.data;
    }catch(error){

    }
}


// Get all the Active counties for drop down
export  const get_county_list=async(state_id)=>{
    try {
        const response = await fetch(HTTPURL + `/api/get_county_list/${state_id}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export const get_emailTemplate_detail=async(id)=>{
    try{
        const response = await fetch(HTTPURL+`/api/get_emailTemplate_detail/${id}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
        const responseData = await response.json();
        return responseData;
    }catch(error){

    }
}

export const sendNotification=async(data)=>{
    try{
        const response=await axios.post(HTTPURL+`/api/sendNotification`,data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response;
    }catch(error){

    }
}

/******Assign Driver******/

export const assignDriver=async(booking_id,driver_id)=>{
    try{
        let data={
            booking_id:booking_id,
            driver_id:driver_id
        }
        const response=await axios.post(HTTPURL+`/api/assign-driver`,data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response;
    }catch(error){

    }
}


/******Assign Folder******/

export const assignFolder=async(booking_id,folder_id)=>{
    try{
        let data={
            booking_id:booking_id,
            folder_id:folder_id
        }
        const response=await axios.post(HTTPURL+`/api/assign-folder`,data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response;
    }catch(error){

    }
}
/******Update Folder Instructions from Admin Panel******/
export const updateInstruction=async(id,instruction,type)=>{
    try{
        let data={
            id:id,
            instruction:instruction,
            type:type
        }
        const response=await axios.post(HTTPURL+`/api/Update-Instruction`,data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response?.data;
    }catch(error){

    }
}



/******Get Mobile content******/

export const mobile_screen_content=async()=>{
    try{
        const response=await axios.get(HTTPURL+`/api/mobile_screen_content`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response?.data;
    }catch(error){

    }
}

/******Update Mobile content******/

export const update_mobile_content=async(data)=>{
    try{
        const response=await axios.post(HTTPURL+`/api/update_mobile_content`,data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })

        return response?.data;
    }catch(error){

    }
}

/******Update User Status******/

export const updateUserStatus=async(user_id,status)=>{
    try{
        let data={
            id:user_id,
            status:!status
            }
        
        const response=await axios.post(HTTPURL+"/api/update_user_status",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    }

}


export const add_new_city=async(city_name,state_id)=>{
    try{
        let data={
            city_name:city_name,
            state_id:state_id
            }
        
        const response=await axios.post(HTTPURL+"/api/add_new_city",data,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        });
        return response.data;
    }catch(error){

    } 
}

