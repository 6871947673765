import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable";
import { GetHolidays, GetCounties, AddHoliday } from "../../../apis/Settings";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { Calendar } from "react-multi-date-picker"
import Multiselect from 'multiselect-react-dropdown';

const Prices = () => {

    const [holidays, setHolidays] = useState([]);
    const [counties, setCounties] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [county, setCounty] = useState([{"id":0,"title":"All"}]);



    useEffect(() => {
        getZipCodes();
        getHolidays(county[0]?.id);
    }, [county])

    async function getZipCodes() {
        let result = await GetCounties();
        console.log(result);
        if (result?.data) {
            
            result.data.unshift({"id":0,"title":"All"});
            setCounties(result.data)
        }
    }


    async function getHolidays(county = 0) {
        const result = await GetHolidays(county);
        console.log(result);
        if (result?.data) {
            setHolidays(result.data.map((element) => {  return element.date; }))
    }
    }

    const [isBtn, setIsBtn] = useState(false);

    const handleSubmit = async (date_focused, date_clicked) => {
        var do_remove = 1;


        if (date_focused?.format?.() == date_clicked?.format?.()) {
            do_remove = 0;
        }
        let response = await AddHoliday(county[0]?.id, date_clicked, do_remove);
        if (response?.status) {
            toast.success(response?.message)

        } else {
            toast.error(response?.message)
        }


    }

    return (
           
            <div className="right-nav admin-county  col-4 align-self-center p-3" id="admin-county">
                <h3 className="">Holidays</h3>
                <div className="row ">
                    <div className='form-group '>
                        <label>Zip Code (Default all, if not selected):</label>
                        <Multiselect displayValue="title"
                                     onSelect={function noRefCheck(e) {
                                                     console.log("data", e[0].id);
                                                     setCounty(e)
                                                 }}
                                     options={counties}  placeholder="Select zip code"    selectedValues={county}   singleSelect="true"      />
            
                    </div>
                </div>
                <Calendar multiple value={holidays}   minDate={new Date()} onFocusedDateChange={(dateFocused, dateClicked) => {
                        handleSubmit(dateFocused, dateClicked)
                              }} />
            
            
            </div>
            )
}

export default Prices