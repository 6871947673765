import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GetOrderCustomerInstructions, UpdateCustomerInstructions } from '../../../apis/CMS'
import { HTTPURL } from "../../../constants/Matcher";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const CustomerInstructions = () => {

    const [customerInstructions, setCustomerInstructions] = useState('')

    const [isBtn1, setIsBtn1] = useState(false);


    useEffect(() => {
        getOrderCustomerInstructions();

    }, [])
    const getOrderCustomerInstructions = async () => {

        let result = await GetOrderCustomerInstructions();
        console.log(result?.order_customer_instructions);
        if (result?.status) {
            if (result?.order_customer_instructions) {
                setCustomerInstructions(result.order_customer_instructions);
            }

        }
    }


    const addCustomerInstructions = async () => {

        setIsBtn1(true);
        let data = {}
        data.order_customer_instructions = customerInstructions;
        let result = await UpdateCustomerInstructions(data);
        setIsBtn1(false);
        if (result?.status) {
            toast.success(result?.message);
        } else {
            toast.error(result?.message);
        }
    }




    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [
                { 'color': [] },
                // Define custom color options
                { 'background': [] },
                // Add other formatting options as needed
            ],
        ],
    };

    const formats = ['header', 'font', 'list', 'bold', 'italic', 'underline', 'link', 'color'];





    return (
        <>
            <div className="right-nav admin-applications-content p-3" id="applications-content">



                <h4 >Customer Instructions</h4>
                <div className='row'>
                    <div className='col-md-12 col-sm-12'>


                        <div className='form-group '>
                            <ReactQuill
                                value={customerInstructions}
                                onChange={(e) => setCustomerInstructions(e)}
                                modules={modules}
                                formats={formats}
                                placeholder="write body ...."
                            />

                        </div>
                        <div className='form-group mt-4'>
                            <div className="modal-footer">
                                <button type='button' onClick={() => addCustomerInstructions()} className="btn btn-lg btn-success" disabled={isBtn1}>Save
                                    {isBtn1 ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : ''}
                                </button>
                            </div>
                        </div>


                    </div>
                </div>






            </div>
        </>
    )
}

export default CustomerInstructions;

