import React from 'react'
import ReactDOM from 'react-dom'
import "../../styles/MyCalendar.css";
import { HTTPURL } from '../../constants/Matcher';




class MyCalendarMonth extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

        };
    }

    handleClick(e) {
        
     if (e.target.getAttribute("isdisabled")==1) {
    //  console.log(e.target.getAttribute("is_selected"));
            this.props.handler(e.target.getAttribute("datemysql"),e.target.getAttribute("is_selected"));  
            
     }
         
        
       
    }


    render() {
        let d = new Date(this.props.month.year, this.props.month.month);
        let first = d.getDay();
        let today = new Date();
        let  today_time= today.getTime()
        let today_date =  today.getFullYear()+'-' +(today.getMonth()<9?'0':'') + (today.getMonth()+1) + '-'+ (today.getDate()<10?'0':'') + today.getDate();//prints expected format.
        
        //console.log(this.props.holidays );
        
        let rows = [];
        let cols = [];
        let mysql_date ='';
        //let class_dates='rmdp-day';
        for (let i = 0; i < first; i++) {
            cols.push(<div tabIndex="-1" className="rmdp-day rmdp-day-hidden"><span className=""></span></div>);

        }
           // console.log(this.props.todayPickup)

        while (d.getMonth() === this.props.month.month) {

            
            let class_dates='rmdp-day';
            let delivery_info="";
            let  delivery_info1="";
            let is_disabled=0;
            let is_selected =0;

            mysql_date = d.getFullYear()+'-' +(d.getMonth()<9?'0':'') + (d.getMonth()+1) + '-'+ (d.getDate()<10?'0':'') + d.getDate();//prints expected format.
           //  console.log(mysql_date +"===="+today.getTime() +"----"+ d.getTime());
            
            if ( (today_time < d.getTime()) || (this.props.todayPickup===true && today_date == mysql_date) ){ //console.log(today.getTime() +"----"+ d.getTime());
              is_disabled = 1;   
            }
            if(this.props.holidays.includes(mysql_date)) {
                class_dates+= " disabled";
                is_disabled = 1;
            }
            else if(this.props.selectedValues.includes(mysql_date)) {
                class_dates+= " selected ";
                is_selected =  1;
             //   console.log(this.props.delivery);
                if(this.props.delivery?.value){
                   class_dates+= " mytooltip ";
                   var followingDay = new Date(d.getTime() + 86400000*this.props.delivery.days_gap); // + 1 day in ms

                   delivery_info= "Date: "+followingDay.toDateString();
                   delivery_info1= "Time:"+this.props.delivery.time_slot;


           
            }
            }
            cols.push(<div  onClick={this.handleClick.bind(this)} tabIndex="-1" className={class_dates} ><span  is_selected={is_selected} isdisabled={is_disabled} datemysql={mysql_date}  >{d.getDate()}</span>  <div className="tooltiptext"><h6>Delivery Info</h6>{delivery_info} {delivery_info1}</div></div>)

            if (d.getDay() % 7 === 6) { // sunday, last day of week - newline
                rows.push(<div className="rmdp-week">{cols}</div>)
                cols = [];

            }

            d.setDate(d.getDate() + 1);
        }

        if (d.getDay() !== 0) {
            for (let i = d.getDay(); i < 7; i++) {
                cols.push(<div tabIndex="-1" className="rmdp-day rmdp-day-hidden"><span className=""></span></div>);

            }
        }
        rows.push(<div className="rmdp-week">{cols}</div>)



        return (
            <div role="dialog" className="rmdp-wrapper rmdp-shadow " >
                <div className="rmdp-top-class ">
                    <div className="rmdp-calendar  rmdp-border-right">
                        <div className="rmdp-header">
                            <div className="rmdp-header-inner">
                                <div className="rmdp-header-values"><span className="cursor-pointer">{this.state.months[this.props.month.month]}</span>,
                                    <span className="cursor-pointer">{this.props.month.year}</span>
                                </div>
                            </div>
                        </div>

                        <div className="rmdp-day-picker " >

                            <div className="rmdp-week">
                                <div className="rmdp-week-days">Sun</div>
                                <div className="rmdp-week-days">Mon</div>
                                <div className="rmdp-week-days">Tue</div>
                                <div className="rmdp-week-days">Wed</div>
                                <div className="rmdp-week-days">Thu</div>
                                <div className="rmdp-week-days">Fri</div>
                                <div className="rmdp-week-days">Sat</div>

                            </div>
                            
                            {rows}


                        </div>


                    </div>
                </div>
            </div>


        );
    }
}

export class MyCalendar extends React.Component {

    constructor(props) {
        super(props);

    }

    clickCalendarItem(mysql_date,is_selected) {
        this.props.onChange({date:mysql_date,is_selected:is_selected});
    }

    render() {

        const allMonths = this.props.months.map((month) => {
            return <MyCalendarMonth 
            key={month.month}
            selectedValues={this.props.selectedValues} 
            month={month} 
            holidays={this.props.holidays}
            delivery={this.props.delivery}
            todayPickup={this.props.todayPickup}
            handler={this.clickCalendarItem.bind(this)}
            
            />
        });

        return (
            <div className="myCalendar" >{allMonths}</div>
        );
    }
}
export default [MyCalendar,];

