import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable";
import { GetTimeSlots, AddTimeSlot, UpdateTimeSlot, UpdateTimeSlotStatus, DeleteTimeSlot } from "../../../apis/Settings";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';


export const columns = [
    {
        name: 'Sr.No',
        selector: row => row.index,
        sortable: true,
    },
    {
        name: 'Start Time',
        selector: row => row.start_time,
        sortable: true,
    },
    {
        name: 'End Time',
        selector: row => row.end_time,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'ACTION',
        selector: row => row.action,
        sortable: false,
        width: '160px'
    },
];

const TimeSlots = () => {

    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    const [timeSlot, setTimeSlot] = useState({
        start_time: '00:00',
        end_time: '00:00',
        status: true
    })

    const [editTimeSlot, setEditTimeSlot] = useState({
        start_time: '00:00',
        end_time: '00:00',
        status: true,
        id: 1
    })

    useEffect(() => {
        getData();
    }, [])
    const openAddModal = () => {

        setTimeSlot({
            ...timeSlot,
            start_time: '',
            end_time: '',
            status: true
        })
        setIsAddModalOpen(true)
    }

    const [id, setId] = useState();
    const [isBtn, setIsBtn] = useState(false);

    async function getData() {
        const result = await GetTimeSlots();
        console.log(result);
        if(result?.data){
          displayRecords(result.data)
        }
    }

    const toggle = async (id, status) => {
        if (id) {
            let response = await UpdateTimeSlotStatus(id, status)
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const editModal = async (id, data) => {
        if (data?.id) {
            setId(id);
            setEditTimeSlot({
                ...editTimeSlot,
                start_time: data?.start_time,
                id: data?.id,
                end_time: data?.end_time,
                status: data?.status
            })
            setIsEditModalOpen(true);
        }
    }
    const displayRecords = (result) => {
        const arrayData = result?.map((elem, index) => {
            const { id, start_time, status, end_time } = elem;
            let current_id = id;
            return {
                start_time: start_time,
                index: index + 1,
                end_time: end_time,
                status: <>
                    <div className="toggling form-check form-switch" onClick={() => toggle(id, !status)}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={status ? true : false} onChange={e => {}} />
                    </div>
                </>,
                action: <>
                    <a className='edit-btn'
                        onClick={(e) => editModal(e, elem)}
                    >Edit
                    </a>
                    <button className="dlt-btn" onClick={() => isDestroy(current_id, index)}>Delete</button>
                </>,
            };
        });
        setData(arrayData);
        setShowResults(true)
    }

    const isDestroy = (id, indx) => {
        setId(id)
        setIsConfirm(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsBtn(true);
        if (!timeSlot?.start_time || !timeSlot?.end_time) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {

            let response = await AddTimeSlot(timeSlot.start_time, timeSlot.end_time, timeSlot.status);
            setIsBtn(false);
            if (response?.status) {
                toast.success(response?.message)
                closeModal();
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const closeModal = () => {
        setIsAddModalOpen(false)
        setIsEditModalOpen(false)
        setIsConfirm(false)
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault()
        setIsBtn(true);
        if (!editTimeSlot?.start_time || !editTimeSlot?.end_time) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {
            let response = await UpdateTimeSlot(editTimeSlot.id, editTimeSlot.start_time, editTimeSlot.end_time, editTimeSlot.status);
            setIsBtn(false);
            closeModal();
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }


    const deleteTimeSlot = async () => {
        if (id) {
            setIsBtn(true)
            let result = await DeleteTimeSlot(id);
            setIsBtn(false)
            closeModal();
            getData();
            if (result?.status) {
                toast.success(result?.message);
            } else {
                toast.error(result?.message)
            }
        }
    }




    return (
        <div className="right-nav admin-county" id="admin-county">
            <div className="font-42 text-black text-center mb-4">TIME SLOTS LIST</div>
           
                <button type='button' onClick={openAddModal} className="btn btn-lg btn-success  float-end ">ADD TIME SLOT</button>
            
            {showResults ? <CustomTable columns={columns} data={data} /> : null}

            <Modal animation={false} show={isAddModalOpen}   onHide={() => { setIsAddModalOpen(false); }}    keyboard={false}    backdrop="static"  id="time-slot-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content">
                        <div className="modal-heading mb-4"><h3>ADD TIME SLOT</h3></div>
                        <form className="form-horizontal"  onSubmit={handleSubmit} >
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Start Time<span className="text-danger">*</span></label>
                                    <TimePicker value={timeSlot.start_time} onChange={(value) => setTimeSlot({ ...timeSlot, start_time: value })} />

                                
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group mt-2">
                                    <label> End Time<span className="text-danger">*</span></label>
                                    <TimePicker value={timeSlot.end_time} onChange={(value) => setTimeSlot({ ...timeSlot, end_time: value })} />

                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3 d-flex justify-content-start'>
                                    <div className="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setTimeSlot({ ...timeSlot, status: !timeSlot.status })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={timeSlot.status ? true : false}  onChange={e => {}} />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false}
                show={isEditModalOpen}
                onHide={() => { setIsEditModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="time-slot-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content">
                        <div className="modal-heading mb-4"><h3>UPDATE TIME SLOT</h3></div>
                        <form className="form-horizontal" onSubmit={handleEditSubmit} >
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Start Time<span className="text-danger">*</span></label>
    <TimePicker value={editTimeSlot.start_time} onChange={(value) => setEditTimeSlot({ ...editTimeSlot, start_time: value })} />
         </div>
                            </div>
                            <div className='row'>
                                <div className="form-group mt-2">
                                    <label> End Time <span className="text-danger">*</span></label>
                                   <TimePicker value={editTimeSlot.end_time} onChange={(value) => setEditTimeSlot({ ...editTimeSlot, end_time: value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3  d-flex justify-content-start'>
                                    <div className="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setEditTimeSlot({ ...editTimeSlot, status: !editTimeSlot.status })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={editTimeSlot.status ? true : false} onChange={e => {}}  />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Update {isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>   </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false}
                show={isConfirm}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-inner-content">
                    <div className="modal-heading"><h3>Do you really want to delete this time slot?</h3></div>
                    <div className="modal-footer">
                        <button type='button' onClick={deleteTimeSlot} className="btn btn-lg btn-success" disabled={isBtn}>Yes
                            {isBtn ? <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''}
                        </button>
                        <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>No</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default TimeSlots