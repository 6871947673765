export const ROUTES = [
    {
        path: "/cms-privacy-and-terms",
        title: "CMS",
        icon: "fa fa-tasks",
        extralink: false,
        submenu:
            [
                {
                    path: "/cms-privacy-and-terms",
                    title: "Privacy & Terms",
                    icon: "fa fa-user-secret",
                    extralink: false,
                    submenu: []

                },
                {
                    path: "/cms-customer-instructions",
                    title: "Customer Instructions",
                    icon: "fa fa-user-secret",
                    extralink: false,
                    submenu: []

                },
            ]
    },
    {
        path: "/counties",
        title: "Settings",
        icon: "fa fa-cog",

        extralink: false,
        submenu:
            [
                {
                    path: "/counties",
                    title: "Area/Counties",
                    icon: "fa fa-location-dot",

                    extralink: false,
                    submenu: []
                },
                {
                    path: "/delivery-days",
                    title: "Delivery Days",
                    icon: "fa fa-calendar-days",

                    extralink: false,
                    submenu: []
                },
                {
                    path: "/categories",
                    title: "Categories",
                    icon: "fa fa-layer-group",

                    extralink: false,
                    submenu: []

                },
                {
                    path: "/prices",
                    title: "Prices",
                    icon: "fa fa-money-check",

                    extralink: false,
                    submenu: []

                },
                {
                    path: "/holidays",
                    title: "Holidays",
                    icon: "fa fa-money-check",

                    extralink: false,
                    submenu: []

                },
            ]
    },

    {
        path: "/customers-list",
        title: "Users",
        icon: "fa fa-users",

        extralink: false,
        submenu:
            [
                {
                    path: "/customers-list",
                    title: "Customer List",
                    icon: "fa-solid fa-clipboard-user",

                    extralink: false,
                    submenu: [],
                },
                {
                    path: "/drivers-list",
                    title: "Driver List",
                    icon: "fa-solid fa-clipboard-check",

                    extralink: false,
                    submenu: [],
                }, {
                    path: "/folders-list",
                    title: "folders-list",
                    icon: "fa-solid fa-clipboard-check",

                    extralink: false,
                    submenu: [],
                },
            ]
    },
    {
        path: "/laundary-orders-list",
        title: "Orders",
        icon: "fa fa-cart-arrow-down",

        extralink: false,
        submenu:
            [
                {
                    path: "/laundary-orders-list",
                    title: "Laundary List View",
                    icon: "fa-brands fa-critical-role",

                    extralink: false,
                    submenu: [],
                },
                {
                    path: "/laundary-orders-history",
                    title: "Laundary History",
                    icon: "fa-brands fa-critical-role",

                    extralink: false,
                    submenu: [],
                },
                {
                    path: "/qr-codes",
                    title: "QR Codes",
                    icon: "fa-brands fa-qrcode",

                    extralink: false,
                    submenu: [],
                }

            ]
    },

];
