import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable";
import { GetCategories, AddCategory, UpdateCategory, UpdateCategoryStatus, DeleteCategory } from "../../../apis/Settings";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { HTTPURL } from "../../../constants/Matcher";


export const columns = [
    {
        name: 'Sr.No',
        selector: row => row.index,
        sortable: true,
        width:'80px'
    },
    {
        name: 'Icon',
        selector: row => row.image_icon,
        sortable: true,
    },
    {
        name: 'Title',
        selector: row => row.title,
        sortable: true,
    },
    {
        name: 'Unit',
        selector: row => row.unit,
        sortable: true,
    },
    {
        name: 'Charge Max',
        selector: row => row.upfront_amount,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'ACTION',
        selector: row => row.action,
        sortable: false,
        width: '160px'
    },
];

const Categories = () => {

    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    const [category, setCategory] = useState({
        image_icon: '',
        title: '',
        unit: '',
        upfront_amount: 1,
        status: true
    })

    const [editCategory, setEditCategory] = useState({
        image_icon: '',
        title: '',
        unit: '',
        upfront_amount: 1,
        status: true,
        id: 1
    })

    useEffect(() => {
        getData();
    }, [])
    const openAddModal = () => {

        setCategory({
            ...category,
            image_icon: '',
            title: '',
            unit: '',
            upfront_amount: 1,
            status: true
        })
        setIsAddModalOpen(true)
    }

    const [id, setId] = useState();
    const [isBtn, setIsBtn] = useState(false);

    async function getData() {
        const result = await GetCategories();
        console.log(result);
        if (result?.data) {
            displayRecords(result.data)
        }
    }

    const toggle = async (id, status) => {
        if (id) {
            let response = await UpdateCategoryStatus(id, status)
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const editModal = async (id, data) => {
        if (data?.id) {
            setId(id);
            setEditCategory({
                ...editCategory,
                id: data?.id,
                image_icon: '',
                old_image_icon:data?.image_icon,
                title: data?.title,
                unit: data?.unit,
                upfront_amount: data?.upfront_amount,
                status: data?.status
            })
            setIsEditModalOpen(true);
        }
    }
    const displayRecords = (result) => {
        const arrayData = result?.map((elem, index) => {
            const {id, image_icon, title, unit, upfront_amount, status} = elem;
            let current_id = id;
            return {
                index: index + 1,
                image_icon: <><img src={HTTPURL+"/uploads/categories/" + image_icon} className="table-icon" /> </>,
                title: title,
                unit: <>{(unit == 1) ? "per bag" : "per pound"}</>,
                upfront_amount: <>{(upfront_amount == 1) ? "Max" : "Min"}</>,
                status: <>
                <div className="toggling form-check form-switch" onClick={() => toggle(id, !status)}>
                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={status ? true : false} onChange={e => {
                                    }}  />
                </div>
                </>,
                action: <>
<a className='edit-btn'
   onClick={(e) => editModal(e, elem)}
   >Edit
</a>
<button className="dlt-btn" onClick={() => isDestroy(current_id, index)}>Delete</button>
</>,
            };
        });
        setData(arrayData);
        setShowResults(true)
    }

    const isDestroy = (id, indx) => {
        setId(id)
        setIsConfirm(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsBtn(true);
        if (!category?.image_icon || !category?.title || !category?.unit) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {

            let response = await AddCategory(category.image_icon, category.title, category.unit, category.upfront_amount, category.status);
            setIsBtn(false);
            if (response?.status) {
                toast.success(response?.message)
                closeModal();
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const closeModal = () => {
        setIsAddModalOpen(false)
        setIsEditModalOpen(false)
        setIsConfirm(false)
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault()
        setIsBtn(true);
        if ( !editCategory?.title || !editCategory?.unit) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {
            let response = await UpdateCategory(editCategory.id, editCategory.image_icon, editCategory.old_image_icon,editCategory.title, editCategory.unit, editCategory.upfront_amount, editCategory.status);
            setIsBtn(false);
            closeModal();
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }


    const deleteCategory = async () => {
        if (id) {
            setIsBtn(true)
            let result = await DeleteCategory(id);
            setIsBtn(false)
            closeModal();
            getData();
            if (result?.status) {
                toast.success(result?.message);
            } else {
                toast.error(result?.message)
            }
        }
    }




    return (
            <div className="right-nav admin-county py-4 px-2" id="admin-county">
                <h3 >CATEGORIES LIST</h3>
                <button type='button' onClick={openAddModal} className="btn btn-lg btn-success  float-end ">Add Category</button>
            
                {showResults ? <CustomTable columns={columns} data={data} /> : null}
            
                <Modal animation={false}
                    show={isAddModalOpen}
                    onHide={() => {
                    setIsAddModalOpen(false);
                }}
                    keyboard={false}
                    backdrop="static"
                    id="zip-code-modal"
                    >
                    <Modal.Body>
                        <div className="modal-inner-content">
                            <h3>ADD CATEGORY</h3>
                            <form className="form-horizontal"   onSubmit={handleSubmit}  >
            
                                <div className="row">
                                    <div className='form-group mb-3'>
                                        <label> Icon<span className="text-danger">*</span></label>
                                        <input type='file' className="form-control form-control-lg" onChange={(e) => setCategory({...category, image_icon: e.target.files[0]})}   accept="image/*" />
            
            
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='form-group mb-3'>
                                        <label> Title<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={category.title} onChange={(e) => setCategory({...category, title: e.target.value})} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='form-group mb-3  '>
                                        <label> Unit<span className="text-danger">*</span></label>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="1"  checked={category.unit === 1  }  onChange={(e) => setCategory({...category, unit: 1})} />
                                            <label className="form-check-label">Per Bag</label>
                                        </div> <div className="form-check form-check-inline">       
                                            <input type="radio" className="form-check-input" value="2"  checked={category.unit === 2  }  onChange={(e) => setCategory({...category, unit: 2})} />
                                            <label  className="form-check-label">Per Pound</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="form-group mb-3">
                                        <label> Credit Wallet<span className="text-danger">*</span></label>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="1"  checked={category.upfront_amount === 1  }  onChange={(e) => setCategory({...category, upfront_amount: 1})} />
                                            <label className="form-check-label">Max</label>
                                        </div> <div className="form-check form-check-inline">       
                                            <input type="radio" className="form-check-input" value="0"  checked={category.upfront_amount === 0  }  onChange={(e) => setCategory({...category, upfront_amount: 0})} />
                                            <label  className="form-check-label">Min</label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='form-group mb-3 d-flex justify-content-start'>
                                        <div className="me-2 my-auto fw-bold">Status: </div>
                                        <div className="toggling form-check form-switch" onClick={(e) => setCategory({...category, status: !category.status})}  >
                                            <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={category.status ? true : false}  />
                                        </div>
                                    </div>
            
                                </div>
                                <div className="modal-footer">
                                    <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : ''}
                                    </button>
                                    <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            
                <Modal animation={false}
                    show={isEditModalOpen}
                    onHide={() => {
                    setIsEditModalOpen(false);
                }}
                    keyboard={false}
                    backdrop="static"
                    id="zip-code-modal"
                    >
                    <Modal.Body>
                        <div className="modal-inner-content">
                            <div className="modal-heading mb-4"><h3>UPDATE CATEGORY</h3></div>
                            <form className="form-horizontal"
                                  onSubmit={handleEditSubmit} >
                                <div className="row">
                                    <div className='form-group mb-3'>
                                        <label> Icon 
                                        <div className='btn btn-link toolt' >(View Icon) 
                                           <div className="tooltipinner">
                                              <img src={HTTPURL+"/uploads/categories/"+editCategory.old_image_icon} className="table-icon" />
                                            </div>
                                        </div>
                                        </label>
                                        <input type='file' className="form-control  form-control-lg" onChange={(e) => setEditCategory({...editCategory, image_icon: e.target.files[0]})}   accept="image/*" />
                                     
            
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='form-group mb-3'>
                                        <label> Title <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control " value={editCategory.title} onChange={(e) => setEditCategory({...editCategory, title: e.target.value})} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='form-group mb-3  '>
                                        <label> Unit<span className="text-danger">*</span></label>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="1"  checked={editCategory.unit === 1  }  onChange={(e) => setEditCategory({...editCategory, unit: 1})} />
                                            <label className="form-check-label">Per Bag</label>
                                        </div> <div className="form-check form-check-inline">       
                                            <input type="radio" className="form-check-input" value="2"  checked={editCategory.unit === 2  }  onChange={(e) => setEditCategory({...editCategory, unit: 2})} />
                                            <label  className="form-check-label">Per Pound</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="form-group mt-2">
                                        <label> Credit Wallet <span className="text-danger">*</span></label>
                                
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="1"  checked={editCategory.upfront_amount === 1  }  onChange={(e) => setEditCategory({...editCategory, upfront_amount: 1})} />
                                            <label className="form-check-label">Max</label>
                                        </div> <div className="form-check form-check-inline">       
                                            <input type="radio" className="form-check-input" value="0"  checked={editCategory.upfront_amount === 0  }  onChange={(e) => setEditCategory({...editCategory, upfront_amount: 0})} />
                                            <label  className="form-check-label">Min</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                     <div className='form-group mb-3 d-flex justify-content-start'>
                                        <div className="me-2 my-auto fw-bold">Status: </div>
                                        <div className="toggling form-check form-switch" onClick={(e) => setEditCategory({...editCategory, status: !editCategory.status})}  >
                                            <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={editCategory.status ? true : false}  />
                                        </div>
                                    </div>
            
                                </div>
                                <div className="modal-footer">
                                    <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Update {isBtn ? <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>   </div> : ''}
                                    </button>
                                    <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            
                <Modal animation={false}
                    show={isConfirm}
                    onHide={closeModal}
                    backdrop="static"
                    keyboard={false}
            
                    >
                    <div className="modal-inner-content">
                        <div className="modal-heading"><h3>Do you really want to delete this category?</h3></div>
                        <div className="modal-footer">
                            <button type='button' onClick={deleteCategory} className="btn btn-lg btn-success" disabled={isBtn}>Yes
                                {isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                            </button>
                            <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>No</button>
                        </div>
                    </div>
                </Modal>
            </div>
            )
}

export default Categories