import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable";
import { GetCounties, AddCounty, UpdateCounty, UpdateCountyStatus, DeleteCounty } from "../../../apis/Settings";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";

export const columns = [
    {
        name: 'Sr.No',
        selector: row => row.index,
        sortable: true,
    },
    {
        name: 'Area/County Title',
        selector: row => row.title,
        sortable: true,
    },
    {
        name: 'State Name',
        selector: row => row.city_name,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'ACTION',
        selector: row => row.action,
        sortable: false,
        width: '160px'
    },
];

const Counties = () => {

    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    const [county, setCounty] = useState({
        title: '',
        zip_code_list: '',
        city_name: '',
        status: true
    })

    const [editCounty, setEditCounty] = useState({
        title: '',
        zip_code_list: '',
        city_name: '',
        status: true,
        id: 1
    })

    useEffect(() => {
        getData();
    }, [])
    const openAddModal = () => {

        setCounty({
            ...county,
            title: '',
            zip_code_list: '',
            city_name: '',
            status: true
        })
        setIsAddModalOpen(true)
    }

    const [id, setId] = useState();
    const [isBtn, setIsBtn] = useState(false);

    async function getData() {
        const result = await GetCounties();
        console.log(result);
        if(result?.data){
          displayRecords(result.data)
        }
    }

    const toggle = async (id, status) => {
        if (id) {
            let response = await UpdateCountyStatus(id, status)
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const editModal = async (id, data) => {
        if (data?.id) {
            setId(id);
            setEditCounty({
                ...editCounty,
                title: data?.title,
                zip_code_list:data?.zip_code_list,
                id: data?.id,
                city_name: data?.city_name,
                status: data?.status
            })
            setIsEditModalOpen(true);
        }
    }
    const displayRecords = (result) => {
        const arrayData = result?.map((elem, index) => {
            const { id, title, status, city_name } = elem;
            let current_id = id;
            return {
                title: title,
                 index: index + 1,
                city_name: city_name,
                status: <>
                    <div className="toggling form-check form-switch" onClick={() => toggle(id, !status)}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={status ? true : false} onChange={e => {}} />
                    </div>
                </>,
                action: <>
                    <a className='edit-btn'
                        onClick={(e) => editModal(e, elem)}
                    >Edit
                    </a>
                    <button className="dlt-btn" onClick={() => isDestroy(current_id, index)}>Delete</button>
                </>,
            };
        });
        setData(arrayData);
        setShowResults(true)
    }

    const isDestroy = (id, indx) => {
        setId(id)
        setIsConfirm(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsBtn(true);
        if (!county?.title || !county?.city_name) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {

            let response = await AddCounty(county.title, county.zip_code_list, county.city_name, county.status);
            setIsBtn(false);
            if (response?.status) {
                toast.success(response?.message)
                closeModal();
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const closeModal = () => {
        setIsAddModalOpen(false)
        setIsEditModalOpen(false)
        setIsConfirm(false)
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault()
        setIsBtn(true);
        if (!editCounty?.title || !editCounty?.city_name) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {
            let response = await UpdateCounty(editCounty.id, editCounty.title,editCounty.zip_code_list, editCounty.city_name, editCounty.status);
            setIsBtn(false);
            closeModal();
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }


    const deleteCounty = async () => {
        if (id) {
            setIsBtn(true)
            let result = await DeleteCounty(id);
            setIsBtn(false)
            closeModal();
            getData();
            if (result?.status) {
                toast.success(result?.message);
            } else {
                toast.error(result?.message)
            }
        }
    }




    return (
        <div className="right-nav admin-county py-4 px-2" id="admin-county">
<h3 >AREA/COUNTIES LIST</h3>
               <button type='button' onClick={openAddModal} className="btn btn-lg btn-success  float-end ">ADD AREA/COUNTY</button>
           
           
            {showResults ? <CustomTable columns={columns} data={data} /> : null}

            <Modal animation={false}
                show={isAddModalOpen}
                onHide={() => { setIsAddModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="zip-code-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content">
                        <h3>ADD AREA/COUNTY</h3>
                        <form className="form-horizontal"
                            onSubmit={handleSubmit} >
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Area/County<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={county.title} onChange={(e) => setCounty({ ...county, title: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Zip code<span className="text-danger">*</span></label>
                                    <textarea className="form-control" type="text" onChange={(e) => setCounty({ ...county, zip_code_list: e.target.value })}  value={county.zip_code_list} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group mt-2">
                                    <label> State Name<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={county.city_name} onChange={(e) => setCounty({ ...county, city_name: e.target.value })} />

                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3 d-flex justify-content-start'>
                                    <div className="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setCounty({ ...county, status: !county.status })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={county.status ? true : false} onChange={e => {}}  />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false}
                show={isEditModalOpen}
                onHide={() => { setIsEditModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="zip-code-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content">
                        <div className="modal-heading mb-4"><h3>UPDATE AREA/COUNTY</h3></div>
                        <form className="form-horizontal"
                            onSubmit={handleEditSubmit} >
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Area/County <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={editCounty.title} onChange={(e) => setEditCounty({ ...editCounty, title: e.target.value })} />
                                </div>
                            </div>
                             <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Zip code<span className="text-danger">*</span></label>
                                    <textarea className="form-control" type="text" onChange={(e) => setEditCounty({ ...editCounty, zip_code_list: e.target.value })}  value={editCounty.zip_code_list} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group mt-2">
                                    <label> State Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={editCounty.city_name} onChange={(e) => setEditCounty({ ...editCounty, city_name: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                 <div className='form-group mb-3 d-flex justify-content-start'>
                                    <div className="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setEditCounty({ ...editCounty, status: !editCounty.status })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={editCounty.status ? true : false} onChange={e => {}} />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Update {isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>   </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false}
                show={isConfirm}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-inner-content">
                    <div className="modal-heading"><h3>Do you really want to delete this zip code?</h3></div>
                    <div className="modal-footer">
                        <button type='button' onClick={deleteCounty} className="btn btn-lg btn-success" disabled={isBtn}>Yes
                            {isBtn ? <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''}
                        </button>
                        <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>No</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Counties