import React from "react";
const Footer = () => {
    return (
        <footer className="py-1  mt-auto footer-copy-right">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                    <div className="text-muted">Copyright &copy; WECLEA {new Date().getFullYear()}</div>
                </div>
            </div>
        </footer>
    ); 
}
export default Footer;