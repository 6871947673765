import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'
import { HTTPURL, HTTPURLFRONT } from "../../../constants/Matcher";
import profile from './../../../image/profile.png'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './../../../styles/DriverDetails.css'
import dateFormat from 'date-and-time';

const CustomerDetails = () => {

    const params = useParams();
    const [customer, setCustomerId] = useState();
    const [orders, setOrders] = useState([]);
    const [customerInfo, setCustomerInfo] = useState([]);
    const [isBtn, setIsBtn] = useState(false)
    const order_statuses = ["New", "Washed", "Dried", "Folded", "Packed", "Complete", "-", "Picked"];


    useEffect(() => {
        setCustomerId(params.id)
        getCustomerDetails();
    }, [])

    console.log("${params.id}", params.id);

    const getCustomerDetails = () => {
        setIsBtn(true);
        axios.get(HTTPURL + `/api/get_user_history/${params.id}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            setIsBtn(false);
            if (response?.data?.status) {
                let data = response?.data;
                setCustomerInfo(data.customer)
                setOrders(data?.orders)
            }
        }).catch((error) => {

        })
    }
    const handleImageError = (e) => {
        e.target.src = profile; // Replace with the path to your default image
    }
    const visitTo = (id) => {
        let token = localStorage.getItem('token')
        window.open(HTTPURLFRONT + '/admin-login/' + id + '/' + token, '_blank')
    }
    return (
        <>
            <section class="bg-light customer-details details-cloned" id="users-details">
                <div class="container">
                    <h3>Customer Details</h3>
                    <div class="row">
                        <div class="col-lg-12 mb-4 mb-sm-5">
                            <div class="card card-style1 border-0">
                                <div class="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
                                    <div class="row align-items-center">
                                        <div class="col-lg-3 mb-4 mb-lg-0 text-center">
                                            <img
                                                src={HTTPURL + "/uploads/" + customerInfo?.profile_image}
                                                width="60"
                                                alt="Image"
                                                onError={handleImageError}
                                                className="customer-dp width-150"
                                            />
                                        </div>
                                        <div class="col-lg-9 " >


                                            <div className="card-body form-group" >
                                                <div className="row">
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">User ID:</label>{customerInfo?.user_id}</div>
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Name:</label>{customerInfo?.name}</div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Email:</label><a target="_blank" href={"mailto:" + customerInfo?.email}>{customerInfo?.email}</a></div>
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Phone:</label><a target="_blank" href="tel:{customerInfo?.mobile}">{customerInfo?.mobile}</a></div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Pickup Address:</label>{customerInfo?.pickup_address}</div>
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Delivery Address:</label>{customerInfo?.delivery_address}</div>
                                                </div>

                                                <div className="row">
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Credit:</label>USD{customerInfo?.credit}</div>
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Square ID:</label>{customerInfo?.customer_id ? "Yes, Saved" : "Not Saved Yet"}</div>
                                                </div>


                                                <div className="row">
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Driver Instructions:</label>{customerInfo?.driver_instructions}</div>
                                                    <div class="col-sm-6 font-black-medium"><label className="d-inline me-2">Folder Instructions:</label>{customerInfo?.folder_instructions}</div>
                                                </div>
                                                <div className="row">
                                                    <div class="col-sm-12 font-black-medium"><label className="d-inline me-2">How I found Weclea:</label>{customerInfo?.comment}</div>
                                                    <div class="col-sm-12 font-black-medium text-end">
                                                        <Link className="btn btn-lg btn-info me-1" title="Click here to login the user's account" to={HTTPURLFRONT + '/admin-login/' + customerInfo?.id + '/' + localStorage.getItem('token')} ><i class="fa-solid fa-right-to-bracket"></i></Link>
                                                        <Link className="btn btn-lg btn-danger me-1" title="New Laundry Booking" to={'/new-booking/' + customerInfo?.id} ><i class="fa-solid fa-truck"></i></Link>
                                                        <Link className="btn btn-lg btn-success me-1" title="Add Credit" to={'/add-credit/' + customerInfo?.id}><i class="fa-solid fa-usd"></i></Link>

                                                    </div>



                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-4 mb-sm-5">



                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="home" title="Orders list">
                                    <div className="table table-responsive bg-white">
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th>Order Id</th>
                                                    <th>Category</th>
                                                    <th>Pickup</th>
                                                    <th>Delivery</th>
                                                    <th>Estimated</th>
                                                    <th>Actual</th>
                                                    <th>Paid</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orders && orders.map((order, index) => (
                                                    <tr key={index}>
                                                        <td className="sorting_1 "><a href={`/order-details/${order?.id}`} >{order?.id}</a></td>
                                                        <td>{order?.category_title}</td>
                                                        <td>{dateFormat.format(new Date(order?.date), 'ddd, MMM DD, YY')}</td>
                                                        <td>{order?.delivery_day}</td>
                                                        <td>{(order.unit === 1) ? order.estimated_loads + ' bags' : +order.estimated_loads + ' lbs'}</td>
                                                        <td>{order.total_loads === 0 ? "N/A" : order.total_loads + ((order.unit === 1) ? ' bags' : ' lbs')}</td>
                                                        <td>{order?.paid_amount}</td>
                                                        <td>{order_statuses[order?.order_status]}</td>


                                                    </tr>
                                                ))}
                                                {!orders.length ? <tr align="center">
                                                    <td colSpan={5}>
                                                        <div className="text-muted" role="status">
                                                            <span className="no-data">No orders found.</span>
                                                        </div>
                                                    </td>
                                                </tr> : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab>
                                <Tab eventKey="Report" title="Report" disabled>
                                    Tab content for Report
                                </Tab>
                                <Tab eventKey="Attendance" title="Attendance" disabled>
                                    Tab content for Attendance
                                </Tab>
                                <Tab eventKey="Payment" title="Payment" disabled>
                                    Tab content for Payment
                                </Tab>
                            </Tabs>

                        </div>

                    </div>
                </div>
            </section>

        </>
    )

}


export default CustomerDetails