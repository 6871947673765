export const ROUTES = [
    {
        path: "/driver-laundary-orders-list",
        title: "Orders",
        icon: "fa fa-tasks",
        extralink: false,
        submenu: []
    },
    {
        path: "/driver-laundary-orders-history",
        title: "History",
        icon: "fa fa-cog",
        extralink: false,
        submenu: []
    },

];
