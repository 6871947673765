import React, { useState, useEffect } from "react";
import {
  add_folder,
  delete_folder,
  folder_driver_status,
  get_folder,
  update_folder,
} from "../../../apis/Items";
import CustomTable from "../../common/CustomTable";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
export const columns = [
  {
    name: "Sr.No.",
    selector: row => row.index,
    sortable: true,
    width: "100px"
  },
  // {
  //   name: "ID",
  //   selector: row => row.id",
  //   sortable: true,
  //   width:"100px"
  // },
  {
    name: "NAME",
    selector: row => row.name,
    sortable: true,
    width: "200px"
  },
  {
    name: "EMAIL",
    selector: row => row.email,
    sortable: true,
  },
  {
    name: "MOBILE",
    selector: row => row.mobile,
    sortable: true,
  },
  {
    name: "STATUS",
    selector: row => row.status,
    sortable: true,
  },
  {
    name: "ADDRESS",
    selector: row => row.address,
    sortable: true,
  },
  {
    name: "ACTION",
    selector: row => row.action,
    sortable: false,
  },
];
const Folders = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [ApiAddress, setApiAddress] = useState(null);
  const [address, setAddress] = useState(null);
  console.log("ApiAddress", ApiAddress);
  console.log("address", address);
  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
  });



  const [licenseImageFront, setLicenseImageFront] = useState(null);
  const [licenseImageBack, setLicenseImageBack] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [editLicenseImageFront, setEditLicenseImageFront] = useState(null);
  const [editLicenseImageBack, setEditLicenseImageBack] = useState(null);
  const [editProfileImage, setEditProfileImage] = useState(null);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    errors,
    getValues: getValue1,
    watch,
    setValue,
    reset,
  } = useForm();
  const {
    register: register2,
    formState: { errors2 },
    handleSubmit: handleSubmit2,
    getValues: getValue2,
    setValue: setValue2,
    reset: reset2,
    watch: watch2,
  } = useForm();
  useEffect(() => {
    if (address) {
      geocodeByAddress(address?.label || ApiAddress)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          setLocation({ ...location, latitude: lat, longitude: lng })
        );
    }
    if (address) {
      geocodeByPlaceId(address?.value?.place_id)
        // .then(results => setAddress_components(results))
        .then(results => {
          const city = results[0]?.address_components?.find(
            (component) =>
              component.types.includes('locality') || component.types.includes('administrative_area_level_2')
          );
          const state = results[0]?.address_components?.find(
            (component) => component.types.includes('administrative_area_level_1')
          );
          const zipCode = results[0]?.address_components?.find(
            (component) => component.types.includes('postal_code')
          );
          const country = results[0]?.address_components?.find(
            (component) => component.types.includes('country')
          );
          setValue('addCity', city ? city?.long_name : '')
          setValue('addState', state ? state?.long_name : '')
          setValue('addPostcode', zipCode ? zipCode?.long_name : '')
          setValue('addCountry', country ? country?.long_name : '')
          setValue2('editCity', city ? city?.long_name : '')
          setValue2('editState', state ? state?.long_name : '')
          setValue2('editPostcode', zipCode ? zipCode?.long_name : '')
          setValue2('editCountry', country ? country?.long_name : '')

        })
        .catch(error => console.error(error));
    }
  }, [address, ApiAddress]);

  async function get_data() {

    const timeout = setTimeout(() => {
      setError('It is taking longer than usual,please wait...');
    }, 5000);
    const result = await get_folder();
    try {
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          name,
          email,
          dob,
          mobile,
          status,
          latitude,
          longitude,
          address,
        } = elem;
        return {
          index: ++index,
          // id: id,
          name: name,
          email: email,
          mobile: mobile,
          action: (
            <>
              <a
                className="edit-btn"
                onClick={() => {
                  setApiAddress(address);
                  setLocation({
                    ...location,
                    latitude: latitude,
                    longitude: longitude,
                  });
                  setValue2("id", id);
                  setValue2("editname", name);
                  setValue2("editemail", email);
                  setValue2("editmobile", mobile);
                  setIsEditModalOpen(true);
                  setStatus("edit");
                }}
              >
                Edit
              </a>
              <button className="dlt-btn" onClick={() => destroy(id)}>
                Delete
              </button>
            </>
          ),
          status: (
            <select class="form-select" onChange={(e) => handleStatusChange(e, id, status)}>
              <option selected={status === 1} value="1">
                Active
              </option>
              <option selected={status === 0} value="0">
                Inactive
              </option>
            </select>
          ),
          address: <div className="address">{address}</div>,
        };
      });
      setData(arrayData, setShowResults(true));
    }
    finally {
      clearTimeout(timeout);
    }
  }
  // document.querySelectorAll('button[type="submit"]').removeAttribute("disabled");
  const destroy = async (id) => {
    const result = window.confirm(
      "Are you sure you want to delete the driver ? "
    );
    if (result) {
      let payload = {
        id: id,
      };
      await delete_folder(payload);
      await get_data();
    }
  };
  useEffect(() => {
    get_data();
  }, []);
  const handleStatusChange = async (e, id, status) => {
    const result = window.confirm(
      "Are you sure you want to change the status?"
    );
    if (result) {
      let payload = {
        status: Number(e.target.value),
        user_id: Number(id),
      };
      await folder_driver_status(payload);
      await get_data();
    } else {
      e.target.value = status;
    }
  };

  const onSubmit = async (data) => {
    console.log(data)
    const formData = new FormData();
    formData.append("role", 3);
    formData.append("name", getValue1("addname"));
    formData.append("email", getValue1("addemail"));
    formData.append("password", getValue1("addpwd"));
    formData.append("latitude", location?.latitude);
    formData.append("longitude", location?.longitude);
    formData.append("mobile", getValue1("addmobile"));
    formData.append("password", getValue1("addcpwd"));
    formData.append("dob", getValue1("addDOB"));
    formData.append("group_id", getValue1("addGroup"));
    formData.append("zip_code", getValue1("addPostcode"));
    formData.append("country", getValue1("addCountry"));
    formData.append("state", getValue1("addState"));
    formData.append("city", getValue1("addCity"));
    formData.append("address", address?.label);
    formData.append("licence_front_image", licenseImageFront);
    formData.append("licence_back_image", licenseImageBack);
    formData.append("profile_image", profileImage);
    formData.append("area", getValue1("addArea"));
    //     name:sagar
    // email:sagar@weclea.com
    // mobile:9685741235
    // role:2
    // latitude:
    // longitude:
    // dob:1998-09-28
    // group_id:
    // zip_code:124106
    // country:India
    // state:Haryana
    // city:Rohtak
    // address:Jaat College, rohtak,Haryana
    // area:Rohtak
    // password:Sagar@123
    // profile_image
    // licence_front_image
    // licence_back_image

    // let payload={
    //    role:3,
    //    // latitude:,
    //    // longitude:,
    //    mobile:data.addmobile,
    //    name:data.addname,
    //    email:data.addemail,
    // //    password:data.addpwd,
    //    latitude:location?.latitude,
    //    longitude:location?.longitude,
    //    mobile:data?.addmobile,
    //    address:address?.label || ApiAddress
    // }
    const result = await add_folder(formData);
    if (result?.status) {
      setIsAddModalOpen(false);
    } else {
      setIsAddModalOpen(true);
    }

    await get_data();
  };
  //name,email,password,latitude,longitude,role,mobile
  const onSubmit2 = async (data) => {
    //    let payload={
    //       id:data.id,
    //       mobile:data.editmobile,
    //       name:data.editname,
    //       email:data.editemail,
    //       latitude:location?.latitude,
    //       longitude:location?.longitude,
    //       address:address?.label || ApiAddress
    //    }
    //id,name,email,latitude,longitude,address,mobile
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("name", getValue2("editname"));
    // formData.append("email", getValue2("editemail"));
    formData.append("password", getValue2("editpwd"));
    formData.append("latitude", location?.latitude);
    formData.append("longitude", location?.longitude);
    // formData.append("mobile", getValue2("editmobile"));
    // formData.append("cpwd", getValue2("editcpwd"));
    // formData.append("DOB", getValue2("editDOB") || '20-10-2002');
    formData.append("group_id", getValue2("editGroup"));
    formData.append("zip_code", getValue2("editPostcode"));
    formData.append("country", getValue2("editCountry"));
    formData.append("state", getValue2("editState"));
    formData.append("city", getValue2("editCity"));
    formData.append("address", address?.label || ApiAddress);
    // formData.append("licenseImageFront", editLicenseImageFront);
    // formData.append("licenseImageBack", editLicenseImageBack);
    formData.append("profile_image", editProfileImage);
    formData.append("area", getValue2("editArea"));
    let result = await update_folder(formData);
    if (result?.status) {
      setIsEditModalOpen(false);
      setLocation({ ...location, latitude: "", longitude: "" });
    }
    setLocation({ ...location, latitude: "", longitude: "" });
    await get_data();
  };
  return (
    <>
      <div className="right-nav admin-folders-list" id="admin-folders-list">

        <div className="font-42 text-black text-center pb-2">Folder List</div>
        <div className="modal-footer">
          {/* {id.length?<button type='button' onClick={openConfirm} className="btn btn-lg btn-success">Delete</button>:null} */}
          <button type='button' onClick={() => {
            setLocation({ ...location, latitude: "", longitude: "" });
            setIsAddModalOpen(true);
            setStatus("add");
          }} className="btn btn-lg btn-success">Add Folder</button>
        </div>
        {/* <a
                  className="btn btn-lg btn-success"
                  onClick={() => {
                  setLocation({ ...location, latitude: "", longitude: "" });
                  setIsAddModalOpen(true);
                  setStatus("add");
                }}
              >
                Add
              </a> */}
        {showResults ? (
          <CustomTable columns={columns} data={data} />
        ) : (!error ? '' : <p style={{ textAlign: "center" }}>{error}</p>
        )}
      </div>
      {/* Add modal */}
      {
        <Modal
          show={isAddModalOpen}
          onHide={() => {
            setIsAddModalOpen(false);
            setLocation({ ...location, latitude: "", longitude: "" });
            setApiAddress("");
          }}
          keyboard={false}
          backdrop="static"
          className="folder-modal"
        >
          <Modal.Header className="p-3" closeButton></Modal.Header>
          <Modal.Body>
            <div className="modal-inner-content">
              <div className="form-group text-center"><h2>Add Folder</h2></div>
              <div className="form-group">
                <form
                  className="form-horizontal"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-6 ">
                      <div className="form-group">
                        <label> Name</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("addname", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Email</label>
                        <input
                          name='addemail'
                          type="text"
                          className="form-control"
                          {...register("addemail", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Password</label>
                        <input
                          name='addpwd'
                          type="password"
                          className="form-control"
                          {...register("addpwd", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Phone</label>
                        <input
                          name='addmobile'
                          type="text"
                          className="form-control"
                          {...register("addmobile", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="form-group">
                        <label> Confirm Password</label>
                        <input
                          name='addcpwd'
                          type="text"
                          className="form-control"
                          {...register("addcpwd", {
                            required: true,
                            validate: (val) => {
                              if (watch('addpwd') != val) {
                                return "passwords should match";
                              }
                            }
                          })}
                        />
                        <p className="alerts">{errors?.addcpwd && errors?.addcpwd?.message}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Date Of Birth</label>
                        <input
                          name='addemail'
                          type="date"
                          className="form-control"
                          {...register("addDOB", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Groups</label>
                        <input
                          name='addGroup'
                          type="text"
                          className="form-control"
                          {...register("addGroup", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="form-group">
                        <label> Address</label>
                        <GooglePlacesAutocomplete
                          className="form-control p-2 h-100"
                          apiKey="AIzaSyC-Jj3ZmyJ6U4v-0rZygBT5-S-pOEqTVts"
                          //  componentRestrictions={{ country: "us" }}
                          options={{
                            types: ["geocode", "establishment"],
                          }}
                          selectProps={{
                            defaultInputValue: ApiAddress,
                            address,
                            onChange: setAddress,
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Country</label>
                        <input
                          name='addCountry'
                          type="text"
                          className="form-control"
                          {...register("addCountry", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> State</label>
                        <input
                          name='addState'
                          type="text"
                          className="form-control"
                          {...register("addState", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> City</label>
                        <input
                          name='addCity'
                          type="text"
                          className="form-control"
                          {...register("addCity", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> PostCode</label>
                        <input
                          name='addPostcode'
                          type="text"
                          className="form-control"
                          {...register("addPostcode", { required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> License Image Front</label>
                        <input
                          name='addLicenseFront'
                          type="file"
                          className="form-control"
                          accept="image/*"
                          {...register("addLicenseFront")}
                          onChange={(e) =>
                            setLicenseImageFront(e.target.files[0])
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> License Image Back</label>
                        <input
                          name='addLicenseBack'
                          type="file"
                          className="form-control"
                          accept="image/*"
                          {...register("addLicenseBack")}
                          onChange={(e) => setLicenseImageBack(e.target.files[0])}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Profile Image</label>
                        <input
                          name="addProfileImage"
                          type="file"
                          className="form-control"
                          accept="image/*"
                          {...register("addProfileImage")}
                          onChange={(e) => setProfileImage(e.target.files[0])}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Area</label>
                        <input
                          name="addArea"
                          type="text"
                          className="form-control"
                          {...register("addArea", { required: true })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
      {/* Edit modal */}
      <Modal
        show={isEditModalOpen}
        onHide={() => {
          setIsEditModalOpen(false);
          setLocation({ ...location, latitude: "", longitude: "" });
          setApiAddress("");
        }}
        keyboard={false}
        backdrop="static"
        className="folder-modal"
      >
        <Modal.Header className="p-3" closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-inner-content">
            <div className="form-group text-center"><h2>Edit Folder</h2></div>
            <div className="form-group mt-4">
              <form
                className="form-horizontal"
                onSubmit={handleSubmit2(onSubmit2)}
              >
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label> Name</label>
                      <input
                        name='editname'
                        type="text"
                        className="form-control"
                        {...register2("editname")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Email</label>
                      <input
                        disabled
                        name="editemail"
                        type="text"
                        className="form-control"
                        {...register2("editemail")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Password</label>
                      <input
                        name="editpwd"
                        type="text"
                        className="form-control"
                        {...register2("editpwd", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Phone</label>
                      <input
                        disabled
                        name="editmobile"
                        type="text"
                        className="form-control"
                        {...register2("editmobile", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label> Confirm Password</label>
                      <input
                        name="editcpwd"
                        type="text"
                        className="form-control"
                        {...register2("editcpwd", {
                          required: true,
                          validate: (val) => {
                            if (watch2('editpwd') != val) {
                              return "passwords should match";
                            }
                          },
                        })}
                      />
                      {console.log('errors2', errors2)}
                      <p className="alerts">{errors2?.editcpwd?.message}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Date Of Birth</label>
                      <input
                        disabled
                        name="editDOB"
                        type="date"
                        className="form-control"
                        {...register2("editDOB")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Groups</label>
                      <input
                        name="editGroup"
                        type="text"
                        className="form-control"
                        {...register2("editGroup", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label> Address</label>
                      <GooglePlacesAutocomplete
                        className="form-control p-2 h-100"
                        apiKey="AIzaSyC-Jj3ZmyJ6U4v-0rZygBT5-S-pOEqTVts"
                        //  componentRestrictions={{ country: "us" }}
                        options={{
                          types: ["geocode", "establishment"],
                        }}

                        selectProps={{
                          defaultInputValue: ApiAddress,
                          address,
                          onChange: setAddress,
                        }}

                      />
                    </div>
                  </div>


                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Country</label>
                      <input
                        name="editCountry"
                        type="text"
                        className="form-control"
                        {...register2("editCountry", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> State</label>
                      <input
                        name="editState"
                        type="text"
                        className="form-control"
                        {...register2("editState", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> City</label>
                      <input
                        name="editCity"
                        type="text"
                        className="form-control"
                        {...register2("editCity", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> PostCode</label>
                      <input
                        name="editPostcode"
                        type="text"
                        className="form-control"
                        {...register2("editPostcode", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> License Image Front</label>
                      <input
                        disabled
                        name="editLicenseFront"
                        type="file"
                        className="form-control"
                        accept="image/*"
                        {...register2("editLicenseFront")}
                        onChange={(e) =>
                          setEditLicenseImageFront(e.target.files[0])
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> License Image Back</label>
                      <input
                        disabled
                        name="editLicenseBack"
                        type="file"
                        className="form-control"
                        accept="image/*"
                        {...register2("editLicenseBack")}
                        onChange={(e) =>
                          setEditLicenseImageBack(e.target.files[0])
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Profile Image</label>
                      <input
                        name="editProfileImage"
                        type="file"
                        className="form-control"
                        accept="image/*"
                        {...register2("editProfileImage")}
                        onChange={(e) => setEditProfileImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Area</label>
                      <input
                        name="editArea"
                        type="text"
                        className="form-control"
                        {...register2("editArea", { required: true })}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-lg btn-success">update</button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Folders;
