import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import CreateRoute from './routes/Route';

function App() {

  return (
    <CreateRoute />
  );
}

export default App;
