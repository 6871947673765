import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable";
import { GetPrices, AddPrice, UpdatePrice, UpdatePriceStatus, DeletePrice, GetCounties,AddCounty, GetDeliveryDays,AddDeliveryDay, GetCategories,AddCategory,AddTimeSlot } from "../../../apis/Settings";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';

import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';



export const columns = [
    {
        name: 'Sr.No',
        selector: row => row.index,
        sortable: true,
         width: '60px'
    },
    {
        name: 'County',
        selector: row => row.county,
        sortable: false,
          width: '150px'
        
    },
    {
        name: 'Category',
        selector: row => row.category,
        sortable: true,
         width: '130px'
    },
    {
        name: 'Delivery',
        selector: row => row.delivery,
        sortable: true,
    },
     {
        name: 'Price Title',
        selector: row => row.price_title,
        sortable: true,
    },
    {
        name: 'Price(USD)',
        selector: row => row.price,
        sortable: true,
         width: '120px'
    },
    {
        name: 'Min. Order',
        selector: row => row.min_order,
        sortable: true,
         width: '120px'
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
         width: '90px'
    },
    {
        name: 'ACTION',
        selector: row => row.action,
        sortable: false,
        width: '120px'
    },
];

const Prices = () => {

    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [categories,setCategories]=useState([]);
    const [counties,setCounties]=useState([]);
    const [timeSlots,setTimeSlots]=useState([]);
    const [deliveryDays,setDeliveryDays]=useState([]);

    const [addPrice, setAddPrice] = useState({
        counties: [],
        category: '',
        delivery_day: '',
        price_title: '',
        price: 0,
        min_order:0,
        is_on_demand:0,
        status: true
    })

    const [editPrice, setEditPrice] = useState({
        counties: [],
        category: '',
        delivery_day: '',
        price_title: '',
        price: 0,
        min_order:0,
        status: true,
        is_on_demand:0,
        id: 1
    })
    
    
    const [isAddCategoryModalOpen,setIsAddCategoryModalOpen]=useState(false);

    const [category, setCategory] = useState({
        image_icon: '',
        title: '',
        unit: '',
        upfront_amount: '',
        status: true
    })
  
    const [isAddCountyModalOpen,setIsAddCountyModalOpen]=useState(false);
    const [county, setCounty] = useState({
        title: '',
        zip_code_list: '',
        city_name: '',
        status: true
    })
    
     const [isAddDeliveryDayModalOpen,setIsAddDeliveryDayModalOpen]=useState(false);
     const [deliveryDay, setDeliveryDay] = useState({
        delivery_day: '',
        days_gap:0,
        status: true
    })



    useEffect(() => {
        getCounties();
        getDeliveryDays();
        getCategories();
        getData();
    }, [])
    
    async function getCounties() {
        const result = await GetCounties();
        console.log(result);
        if(result?.data){
          setCounties(result.data)
        }
    }
    
    
      async function getDeliveryDays() {
        const result = await GetDeliveryDays();
        console.log(result);
        if(result?.data){
          setDeliveryDays(result.data)
        }
    }
    
    
    
    async function getCategories() {
        const result = await GetCategories();
        console.log(result);
        if(result?.data){
          setCategories(result.data)
        }
    }
    
    
    const openAddModal = () => {

        setAddPrice({
            ...addPrice,
        county: [],
        category: '',
        delivery_day: '',
        price: 0,
        min_order:0,
        is_on_demand:0,
        status: true
        })
        setIsAddModalOpen(true)
    }

    const [id, setId] = useState();
    const [isBtn, setIsBtn] = useState(false);

    async function getData() {
        const result = await GetPrices();
        console.log(result);
        if(result?.data){
          displayRecords(result.data)
        }
    }

    const toggle = async (id, status) => {
        if (id) {
            let response = await UpdatePriceStatus(id, status)
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const editModal = async (id, data) => {
        if (data?.id) {
            setId(id);
          //  console.log(data?.delivery_time_slot);
          //  console.log(timeSlots);

          //  var dt = timeSlots.findIndex(t => t.value == data?.delivery_time_slot);
          //  console.log(dt);
            setEditPrice({
                ...editPrice,
                county: data?.title,
                category: data?.category_title,
                delivery_day: data?.delivery_day,
                price_title: data?.price_title ,
                price: data?.price,
                min_order: data?.min_order,
                is_on_demand: data?.is_on_demand,
                status: data?.status,
                 id: data?.id
            })
            setIsEditModalOpen(true);
        }
    }
    const displayRecords = (result) => {
        const arrayData = result?.map((elem, index) => {
           
            const { id, title,category_title,  delivery_day_title,price_title,price,min_order,is_on_demand,unit, status } = elem;
            let current_id = id;
          //   console.log(id);
            return {
                 index: index + 1,
                county: title,
                category: category_title,
                delivery:  delivery_day_title,
                price_title:price_title,
                price: price+ "/"+ (unit==2?"pound":"bag"),
                min_order: min_order+ (unit==2?" pounds":" bags"),
                is_on_demand:is_on_demand,
                status: <>
                    <div className="toggling form-check form-switch" onClick={() => toggle(id, !status)}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={status ? true : false} onChange={e => {}} />
                    </div>
                </>,
                action: <>
                    <a className='edit-btn' onClick={(e) => editModal(e, elem)}   ><i class="fa fa-pen"></i></a>
                    <button className="dlt-btn" onClick={() => isDestroy(current_id, index)}><i class="fa fa-trash"></i></button>
                </>,
            };
        });
        setData(arrayData);
        setShowResults(true)
    }

    const isDestroy = (id, index) => {
        setId(id)
        setIsConfirm(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsBtn(true);
        
        if (!addPrice?.counties || !addPrice?.category ||  !addPrice?.delivery_day  || !addPrice?.price  || !addPrice?.min_order ) {
            toast.error("All fields are required."+addPrice?.delivery_day);
           
            setIsBtn(false);
            return;
        } else {

            let response = await AddPrice(addPrice.counties, addPrice.category, addPrice.delivery_day,addPrice.price_title, addPrice.price,addPrice.min_order,addPrice.is_on_demand, addPrice.status);
            setIsBtn(false);
            if (response?.status) {
                toast.success(response?.message)
                closeModal();
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const closeModal = () => {
        setIsAddModalOpen(false)
        setIsEditModalOpen(false)
        setIsConfirm(false)
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault()
        setIsBtn(true);
        if (!editPrice?.delivery_day    || !editPrice?.price || !editPrice?.min_order ) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {
            let response = await UpdatePrice(editPrice.id, editPrice.delivery_day,editPrice.price_title, editPrice.price, editPrice.min_order,editPrice.is_on_demand,editPrice.status);
            setIsBtn(false);
            closeModal();
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }


    const deletePrice = async () => {
        if (id) {
            setIsBtn(true)
            let result = await DeletePrice(id);
            setIsBtn(false)
            closeModal();
            getData();
            if (result?.status) {
                toast.success(result?.message);
            } else {
                toast.error(result?.message)
            }
        }
    }




    return (
        <div className="right-nav admin-county py-4 px-2" id="admin-county">
            <h3 >PRICES LIST</h3>
               <button type='button' onClick={openAddModal} className="btn btn-lg btn-success  float-end ">Add Price</button>
           
            {showResults ? <CustomTable columns={columns} data={data} /> : null}

            <Modal animation={false}
                show={isAddModalOpen}
                onHide={() => { setIsAddModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="add-price-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content">
                        <h3>ADD PRICE</h3>
                        <form className="form-horizontal"   onSubmit={handleSubmit}  >
                            
                            <div className="row">
                                <div className='form-group mt-3'>
                                <label>Category<span className="text-danger">*</span><a href="#" class="float-end" onClick={(e) =>{  setCategory({
            ...category,
            image_icon: '',
            title: '',
            unit: '',
            upfront_amount: '',
            status: true
        });
        setIsAddCategoryModalOpen(true) }} >New</a></label>
                                       <Select className="flex-grow-1" name="category"
                                       options={categories}  
                                       value={addPrice?.category}
                                       onChange={(e) => { console.log("data", e);
                                                            setAddPrice({... addPrice, category: e})
                                                        }}
                                       />
                                   
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mt-3'>
                                    <label>Counties/Areas<span className="text-danger">*</span><a href="#" class="float-end" onClick={(e) =>{  setCounty({
            ...county,
            title: '',
            zip_code_list: '',
            city_name: '',
            status: true
        });
        setIsAddCountyModalOpen(true) }} >New</a></label>
                                    <Multiselect
                                            displayValue="title"
                                            onSelect={function noRefCheck(e){
                                                console.log("data",e);
                                                setAddPrice({... addPrice,counties:e})
                                            }}
                                            options={counties}
                                            placeholder="Select multiple counties/areas"
                                            selectedValues={addPrice?.counties}
                                        />
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mt-3'>
                                    <label>Delivery<span className="text-danger">*</span><a href="#" class="float-end" onClick={(e) =>{    setDeliveryDay({
            ...deliveryDay,
            delivery_day: '',
            days_gap:0,
            status: true
        })
        setIsAddDeliveryDayModalOpen(true) }} >New</a></label>
                                     <Select className="flex-grow-1 mb-2" name="delivery_day"
                                       options={deliveryDays}  
                                       value={addPrice?.delivery_day}
                                       onChange={(e) => { console.log("data", e);
                                                           setAddPrice({... addPrice,delivery_day:e})
                                                        }}
                                       />

                                      
                                   
                                </div>
                            </div>
                            
                               <div className="row">
                                <div className='col-12 form-group mt-3'>
                                    <label> Price Title<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={addPrice.price_title} onChange={(e) => setAddPrice({ ...addPrice, price_title: e.target.value })} />
                                </div>
                               
                            </div>
                            <div className="row">
                                <div className='col-6 form-group mt-3'>
                                    <label> Price<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={addPrice.price} onChange={(e) => setAddPrice({ ...addPrice, price: e.target.value })} />
                                </div>
                                <div className='col-6  form-group mt-3'>
                                    <label> Min. Order<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={addPrice.min_order} onChange={(e) => setAddPrice({ ...addPrice, min_order: e.target.value })} />
                                </div>
                            </div>
                          
                           
                          
                            <div className="row">
                                <div className=' col-6 form-group mt-3 '>
                                    <div className="me-2 my-auto fw-bold">On Demand: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setAddPrice({ ...addPrice, is_on_demand: !addPrice.is_on_demand })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="is_on_demand" checked={addPrice.is_on_demand ? true : false}  />
                                    </div>
                                </div>

                                <div className=' col-6 form-group mt-3'>
                                    <div className="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setAddPrice({ ...addPrice, status: !addPrice.status })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={addPrice.status ? true : false}  />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false}
                show={isEditModalOpen}
                onHide={() => { setIsEditModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="edit-price-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content">
                        <div className="modal-heading"><h3>UPDATE PRICE</h3></div>
                        <form className="form-horizontal"    onSubmit={handleEditSubmit} >
                            
                            
                             <div className="row">
                                <div className='form-group mt-3'>
                                    <label>Category: {editPrice.category}</label>
                                     <label>County : {editPrice.county}</label>
                                </div>
                            </div>
                           
                          
                            <div className="row">
                                <div className='form-group mt-3'>
                                    <label>Delivery<span className="text-danger">*</span></label>
                                    
                                    
                                    <Select className="flex-grow-1 mb-2" name="delivery_day"
                                       options={deliveryDays}  
                                       value={deliveryDays.find(o => o.value == editPrice?.delivery_day)}
                                       onChange={(e) => { console.log("data", e);
                                                           setEditPrice({... editPrice,delivery_day:e.value})
                                                        }}
                                       />

                                     
                                </div>
                            </div>
                            
                                <div className="row">
                                <div className='col-12 form-group mt-3'>
                                    <label> Price Title<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={editPrice.price_title} onChange={(e) => setEditPrice({ ...editPrice, price_title: e.target.value })} />
                                </div>
                               
                            </div>
                            <div className="row">
                                <div className=' col-6 form-group mt-3'>
                                    <label> Price<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={editPrice.price} onChange={(e) => setEditPrice({ ...editPrice, price: e.target.value })} />
                                </div>
                                  <div className='col-6  form-group mt-3'>
                                    <label> Min. Order<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={editPrice.min_order} onChange={(e) => setEditPrice({ ...editPrice, min_order: e.target.value })} />
                                </div>
                            </div>
                            
                             
                           
                          
                            <div className="row">
                                 <div className=' col-6 form-group mt-3'>
                                    <label>On Demand: </label>
                                    <div className="toggling form-check form-switch" onClick={(e) => setEditPrice({ ...editPrice, is_on_demand: !editPrice.is_on_demand })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="is_on_demand" checked={editPrice.is_on_demand ? true : false}  />
                                    </div>
                                </div>
                                <div className=' col-6 form-group mt-3'>
                                    <label>Status: </label>
                                    <div className="toggling form-check form-switch" onClick={(e) => setEditPrice({ ...editPrice, status: !editPrice.status })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={editPrice.status ? true : false}  />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Update {isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>   </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false}
                show={isConfirm}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-inner-content">
                    <div className="modal-heading"><h3>Do you really want to delete this price?</h3></div>
                    <div className="modal-footer">
                        <button type='button' onClick={deletePrice} className="btn btn-lg btn-success" disabled={isBtn}>Yes
                            {isBtn ? <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''}
                        </button>
                        <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>No</button>
                    </div>
                </div>
            </Modal>
            
            
            
            
            
            
            
            
            
               <Modal animation={false}
                show={isAddCategoryModalOpen}
                onHide={() => { setIsAddCategoryModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="price-add-category-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content min-700">
                        <div className="modal-heading pb-4"><h3>ADD CATEGORY</h3></div>
                        <form className="form-horizontal"   onSubmit={ async (e) => {
        e.preventDefault();
        setIsBtn(true);
        if (!category?.image_icon || !category?.title || !category?.unit) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {

            let response = await AddCategory(category.image_icon, category.title, category.unit, category.upfront_amount, category.status);
            setIsBtn(false);
            if (response?.status) {
                toast.success(response?.message)
               // closeModal();
                getCategories();
                setIsAddCategoryModalOpen(false);
            } else {
                toast.error(response?.message)
            }
        }
    }
                        
    }  >
            
                                <div className="row">
                                    <div className='form-group mb-3'>
                                        <label> Icon<span className="text-danger">*</span></label>
                                        <input type='file' className="form-control form-control-lg" onChange={(e) => setCategory({...category, image_icon: e.target.files[0]})}   accept="image/*" />
            
            
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='form-group mb-3'>
                                        <label> Title<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" value={category.title} onChange={(e) => setCategory({...category, title: e.target.value})} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='form-group mb-3  '>
                                        <label> Unit<span className="text-danger">*</span></label>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="1"  checked={category.unit === 1  }  onChange={(e) => setCategory({...category, unit: 1})} />
                                            <label className="form-check-label">Per Bag</label>
                                        </div> <div className="form-check form-check-inline">       
                                            <input type="radio" className="form-check-input" value="2"  checked={category.unit === 2  }  onChange={(e) => setCategory({...category, unit: 2})} />
                                            <label  className="form-check-label">Per Pound</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="form-group mb-3">
                                        <label> Min. Order<span className="text-danger">*</span></label>
                                        <input type="number" className="form-control" value={category.upfront_amount} onChange={(e) => setCategory({...category, upfront_amount: e.target.value})} />
            
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='form-group mb-3 d-flex justify-content-start'>
                                        <div className="me-2 my-auto fw-bold">Status: </div>
                                        <div className="toggling form-check form-switch" onClick={(e) => setCategory({...category, status: !category.status})}  >
                                            <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={category.status ? true : false}  />
                                        </div>
                                    </div>
            
                                </div>
                                <div className="modal-footer">
                                    <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : ''}
                                    </button>
                                    <button type='button' className="btn btn-lg btn-success" onClick={(e) => setIsAddCategoryModalOpen(false) }  >Cancel</button>
                                </div>
                            </form>
                    </div>
                </Modal.Body>
            </Modal>

            
            
            
          
          
          
          
          
          
               <Modal animation={false}
                show={isAddCountyModalOpen}
                onHide={() => { setIsAddCountyModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="price-add-county-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content min-700">
                        <div className="modal-heading pb-4"><h3>ADD COUNTY</h3></div>
                        <form className="form-horizontal"   onSubmit={ async (e) => {
       e.preventDefault();
        setIsBtn(true);
        if (!county?.title || !county?.city_name) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {

            let response = await AddCounty(county.title, county.zip_code_list, county.city_name, county.status);
            setIsBtn(false);
            if (response?.status) {
                toast.success(response?.message)
                getCounties();
                setIsAddCountyModalOpen(false);
            } else {
                toast.error(response?.message)
            }
        }
    }                   
    }  >
    <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Area/County<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={county.title} onChange={(e) => setCounty({ ...county, title: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Zip code<span className="text-danger">*</span></label>
                                    <textarea className="form-control" type="text" onChange={(e) => setCounty({ ...county, zip_code_list: e.target.value })}  value={county.zip_code_list} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="form-group mt-2">
                                    <label> State Name<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={county.city_name} onChange={(e) => setCounty({ ...county, city_name: e.target.value })} />

                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3 d-flex justify-content-start'>
                                    <div className="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setCounty({ ...county, status: !county.status })}  >
                                        <input className="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={county.status ? true : false} onChange={e => {}}  />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={(e) => setIsAddCountyModalOpen(false) }>Cancel</button>
                            </div>
                        </form>
                </div>
                </Modal.Body>
            </Modal>

          
          
          
          
          
         
          
               <Modal animation={false}
                show={isAddDeliveryDayModalOpen}
                onHide={() => { setIsAddDeliveryDayModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="price-add-delivery-day-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content min-700">
                        <div className="modal-heading mb-4"><h3>ADD DELIVERY DAY</h3></div>
                        <form className="form-horizontal"   onSubmit={ async (e) => {
       e.preventDefault();
        setIsBtn(true);
        if (!deliveryDay?.delivery_day) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {

            let response = await AddDeliveryDay(deliveryDay.delivery_day,deliveryDay.days_gap,  deliveryDay.status);
            setIsBtn(false);
            if (response?.status) {
                toast.success(response?.message)
                getDeliveryDays()
                setIsAddDeliveryDayModalOpen(false); 
            } else {
                toast.error(response?.message)
            }
        }
    }                   
    }  >
    <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Delivery day<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={deliveryDay.delivery_day} onChange={(e) => setDeliveryDay({ ...deliveryDay, delivery_day: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Days Gap<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={deliveryDay.days_gap} onChange={(e) => setDeliveryDay({ ...deliveryDay, days_gap: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3 d-flex justify-content-start'>
                                    <div class="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setDeliveryDay({ ...deliveryDay, status: !deliveryDay.status })}  >
                                        <input class="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={deliveryDay.status ? true : false}  onChange={e => {}}  />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={(e) => setIsAddDeliveryDayModalOpen(false) }>Cancel</button>
                            </div>
                        </form>
                </div>
                </Modal.Body>
            </Modal>

          
            
            
        </div>
    )
}

export default Prices