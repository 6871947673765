import React from 'react'
import ReactDOM from 'react-dom'
import { HTTPURL } from './../../constants/Matcher';



class RadioBtn extends React.Component{

    constructor(props) {
        super(props);
    }

    handleClick(){
        this.props.handler(this.props.index);
     
    }

    render() {
        return (
            <div className={this.props.isChecked ? "radio-btn-group mx-2 fw-bold checked" : "radio-btn-group mx-2 unchecked"}  onClick={this.handleClick.bind(this)}>
                <label><input  type="radio" name="radio_group"  checked={this.props.isChecked ? "checked":""}  /> {this.props.text}</label>
            </div>
        );
    }
}

export class Radio extends React.Component{

    constructor(props) {
        super(props);
      //  console.log(this.props);
        this.state = {
          selectedValue: this.props?.value,
        };
       
    }

    toggleRadioBtn(category){
        
        this.props.onChange(category);
        this.setState({
          selectedValue: category.id,
        });
       // this.props.value(id);
    }

    render() {

        const allOptions = this.props.options.map((option, ) => {
            return <RadioBtn key={option.id} width={this.props.width} id={option.id} isChecked={(this.state.selectedValue == option.id)} image_icon={option.image_icon} text={option.title} index={option} handler={this.toggleRadioBtn.bind(this)} />
        });

        return (
            <div className="imageButtonGroup d-inline-flex" >{allOptions}</div>
        );
    }
}
export default [Radio,];
