import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable";
import { GetDeliveryDays, AddDeliveryDay, UpdateDeliveryDay, UpdateDeliveryDayStatus, DeleteDeliveryDay } from "../../../apis/Settings";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";

export const columns = [
    {
        name: 'Sr.No',
        selector: row => row.index,
        sortable: true,
    },
    {
        name: 'Delivery Day',
        selector: row => row.delivery_day,
        sortable: true,
    },
    {
        name: 'Days Gap',
        selector: row => row.days_gap,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'ACTION',
        selector: row => row.action,
        sortable: false,
        width: '160px'
    },
];

const DeliveryDays = () => {

    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    const [deliveryDay, setDeliveryDay] = useState({
        delivery_day: '',
        days_gap:0,
        status: true
    })

    const [editDeliveryDay, setEditDeliveryDay] = useState({
        delivery_day: '',
        days_gap:0,
        status: true,
        id: 1
    })

    useEffect(() => {
        getData();
    }, [])
    const openAddModal = () => {

        setDeliveryDay({
            ...deliveryDay,
            delivery_day: '',
            days_gap:0,
            status: true
        })
        setIsAddModalOpen(true)
    }

    const [id, setId] = useState();
    const [isBtn, setIsBtn] = useState(false);

    async function getData() {
        const result = await GetDeliveryDays();
        console.log(result);
        if(result?.data){
          displayRecords(result.data)
        }
    }

    const toggle = async (id, status) => {
        if (id) {
            let response = await UpdateDeliveryDayStatus(id, status)
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const editModal = async (id, data) => {
        if (data?.id) {
            setId(id);
            setEditDeliveryDay({
                ...editDeliveryDay,
                delivery_day: data?.delivery_day,
                days_gap: data?.days_gap,
                id: data?.id,
                status: data?.status
            })
            setIsEditModalOpen(true);
        }
    }
    const displayRecords = (result) => {
        const arrayData = result?.map((elem, index) => {
            const { id, delivery_day, days_gap, status } = elem;
            let current_id = id;
            return {
                delivery_day: delivery_day,
                days_gap: days_gap,
                index: index + 1,
                status: <>
                    <div className="toggling form-check form-switch" onClick={() => toggle(id, !status)}>
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={status ? true : false} onChange={e => {}} />
                    </div>
                </>,
                action: <>
                    <a className='edit-btn'
                        onClick={(e) => editModal(e, elem)}
                    >Edit
                    </a>
                    <button className="dlt-btn" onClick={() => isDestroy(current_id, index)}>Delete</button>
                </>,
            };
        });
        setData(arrayData);
        setShowResults(true)
    }

    const isDestroy = (id, indx) => {
        setId(id)
        setIsConfirm(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsBtn(true);
        if (!deliveryDay?.delivery_day) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {

            let response = await AddDeliveryDay(deliveryDay.delivery_day,deliveryDay.days_gap,  deliveryDay.status);
            setIsBtn(false);
            if (response?.status) {
                toast.success(response?.message)
                closeModal();
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }

    const closeModal = () => {
        setIsAddModalOpen(false)
        setIsEditModalOpen(false)
        setIsConfirm(false)
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault()
        setIsBtn(true);
        if (!editDeliveryDay?.delivery_day ) {
            toast.error("All fields are required.");
            setIsBtn(false);
            return;
        } else {
            let response = await UpdateDeliveryDay(editDeliveryDay.id, editDeliveryDay.delivery_day, editDeliveryDay.days_gap, editDeliveryDay.status);
            setIsBtn(false);
            closeModal();
            if (response?.status) {
                toast.success(response?.message)
                getData();
            } else {
                toast.error(response?.message)
            }
        }
    }


    const deleteDeliveryDay = async () => {
        if (id) {
            setIsBtn(true)
            let result = await DeleteDeliveryDay(id);
            setIsBtn(false)
            closeModal();
            getData();
            if (result?.status) {
                toast.success(result?.message);
            } else {
                toast.error(result?.message)
            }
        }
    }




    return (
        <div className="right-nav admin-county py-4 px-2" id="admin-county">
            <h3 >DELIVERY DAYS LIST</h3>
               <button type='button' onClick={openAddModal} className="btn btn-lg btn-success  float-end ">Add Delivery Day</button>
           
            {showResults ? <CustomTable columns={columns} data={data} /> : null}

            <Modal animation={false}
                show={isAddModalOpen}
                onHide={() => { setIsAddModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="delivery-day-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content">
                        <h3>ADD DELIVERY DAY</h3>
                        <form className="form-horizontal"
                            onSubmit={handleSubmit} >
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Delivery day<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={deliveryDay.delivery_day} onChange={(e) => setDeliveryDay({ ...deliveryDay, delivery_day: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Days Gap<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={deliveryDay.days_gap} onChange={(e) => setDeliveryDay({ ...deliveryDay, days_gap: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className='form-group mb-3 d-flex justify-content-start'>
                                    <div class="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setDeliveryDay({ ...deliveryDay, status: !deliveryDay.status })}  >
                                        <input class="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={deliveryDay.status ? true : false}  onChange={e => {}}  />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Save{isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false}
                show={isEditModalOpen}
                onHide={() => { setIsEditModalOpen(false); }}
                keyboard={false}
                backdrop="static"
                id="delivery-day-modal"
            >
                <Modal.Body>
                    <div className="modal-inner-content">
                        <div className="modal-heading mb-4"><h3>UPDATE DELIVERY DAY</h3></div>
                        <form className="form-horizontal"
                            onSubmit={handleEditSubmit} >
                            <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Delivery day <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={editDeliveryDay.delivery_day} onChange={(e) => setEditDeliveryDay({ ...editDeliveryDay, delivery_day: e.target.value })} />
                                </div>
                            </div>
                             <div className="row">
                                <div className='form-group mb-3'>
                                    <label> Days gap <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" value={editDeliveryDay.days_gap} onChange={(e) => setEditDeliveryDay({ ...editDeliveryDay, days_gap: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                               <div className='form-group mb-3 d-flex justify-content-start'>
                                    <div class="me-2 my-auto fw-bold">Status: </div>
                                    <div className="toggling form-check form-switch" onClick={(e) => setEditDeliveryDay({ ...editDeliveryDay, status: !editDeliveryDay.status })}  >
                                        <input class="form-check-input form-check-input-colored" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={editDeliveryDay.status ? true : false} onChange={e => {}}  />
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type='submit' className="btn btn-lg btn-success" disabled={isBtn}>Update {isBtn ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>   </div> : ''}
                                </button>
                                <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false}
                show={isConfirm}
                onHide={closeModal}
                backdrop="static"
                  animation={true}
                keyboard={false}

            >
                <div className="modal-inner-content">
                    <div className="modal-heading"><h3>Do you really want to delete this delivery day?</h3></div>
                    <div className="modal-footer">
                        <button type='button' onClick={deleteDeliveryDay} className="btn btn-lg btn-success" disabled={isBtn}>Yes
                            {isBtn ? <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''}
                        </button>
                        <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>No</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DeliveryDays